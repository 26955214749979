import { ChevronRight } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../styledcomponents/styledQBExamWidgets";

function HighYield() {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: "center", outline: "none" }}>
      <StyledButton onClick={() => navigate("/student/highyield")}>
        Start The High Yield <ChevronRight />
      </StyledButton>
    </div>
  );
}

export default HighYield;
