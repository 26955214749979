import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Progress } from "antd";
import { green } from "@ant-design/colors";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { AboutParaTypo } from "../../styledcomponents/beforeLoginWidgets";
import useFetch from "../../customHooks/useFetch";
import ModelFile from "../../landing/ModelFile";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Performance - question summary",
    },
  },
  //   aspectRatio: 2 | 1,
  //   maintainAspectRatio: false,
};

export default function PerformanceSummary() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(600));
  const [myscore, setMyscore] = useState(0);
  const [userscore, setUserscore] = useState(0);
  const [totalquestions, setTotalquestions] = useState(0);
  const { loading, resData, modelWin, catchError } = useFetch("/chartsummary");

  useEffect(() => {
    if (resData) {
      setMyscore(resData.myscore);
      setUserscore(resData.userscore);
      setTotalquestions(resData.totalquestions);
    }
  }, [resData]);

  const labels = ["Compare All Users"];
  const data = {
    labels,
    datasets: [
      {
        label: "Your Score",
        data: [myscore],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Average User Score",
        data: [userscore],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <div>
      {loading ? (
        <Backdrop open={loading} invisible>
          <CircularProgress style={{ color: "#f38424" }} />
        </Backdrop>
      ) : (
        <div>
          <AboutParaTypo variant="body2" gutterBottom>
            Your average score is {myscore}% after answering {totalquestions}{" "}
            questions. The average score of registered users is {userscore}%.{" "}
          </AboutParaTypo>
          <Box mt={2} mb={2}>
            <Progress
              percent={myscore}
              steps={isSmall ? 13 : 25}
              strokeColor={green[6]}
            />
          </Box>

          <AboutParaTypo variant="body2" gutterBottom>
            Your score of {myscore}% puts you on the 0th percentile of
            registered users. The histogram below shows the scores of other
            users.
          </AboutParaTypo>
          <Bar options={options} data={data} />
        </div>
      )}
      {modelWin && <ModelFile modelWin={modelWin} catchError={catchError} />}
    </div>
  );
}
