import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  dropzone: {
    textAlign: "center",
    padding: "20px",
    border: "2px black dashed",
    width: "40rem",
    // margin: "auto",
    // width: "100%",
    marginTop: "1rem",
  },
}));
