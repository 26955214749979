import { Close, Done, Flag } from "@mui/icons-material";
import { Divider, Link, Typography } from "@mui/material";
import React from "react";
import {
  ScoreBoardIconDiv,
  ScoreBox
} from "../../styledcomponents/styledQBExamWidgets";

function ScoreBoard({
  scoreData,
  type,
  totalScore,
  getnotAttendQues,
  getMockAttendedQues,
  getTimedTest
}) {
  let scoreData1 = scoreData.map(JSON.stringify);
  let uniqueSet = new Set(scoreData1);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
  let scoreDataValue = uniqueArray;
  const handleNA = (scoreBackId, flag, scoreClick) => {
    if (type === "mock") {
      getMockAttendedQues(scoreBackId, flag, scoreClick);
    } else if (type === "timed-test") {
      getTimedTest(scoreBackId, flag, scoreClick);
    } else {
      getnotAttendQues(scoreBackId, flag, scoreClick);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "end"
      }}
    >
      <ScoreBox>
        <div
          style={{
            padding: "1rem",
            backgroundColor: "#f4f0dc"
          }}
        >
          <Typography variant="body" color={"gray"} marginLeft={1}>
            Score: <b>{totalScore === "NaN" ? 0 : totalScore}%</b>
          </Typography>
        </div>
        <Divider />
        <div
          style={{
            padding: "1rem 2.5rem",
            backgroundColor: "#fcf8e3"
          }}
        >
          {scoreDataValue?.map((scoreVal, id) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
              key={id}
            >
              <ScoreBoardIconDiv
                style={{
                  cursor: "pointer"
                  // textDecoration: "underline",
                  // color: "blue",
                }}
                onClick={(e) =>
                  handleNA(scoreVal.startnumber, scoreVal.flag, "scoreClick")
                }
              >
                <Typography
                  variant="body"
                  color={"gray"}
                  fontWeight={500}
                  onClick={(e) =>
                    handleNA(scoreVal.startnumber, scoreVal.flag, "scoreClick")
                  }
                >
                  {id + 1}
                </Typography>
              </ScoreBoardIconDiv>

              <ScoreBoardIconDiv
                style={{ cursor: "pointer" }}
                onClick={(e) =>
                  handleNA(scoreVal.startnumber, scoreVal.flag, "scoreClick")
                }
              >
                {type === "quiz" ? (
                  <Typography variant="body" color={"gray"} fontWeight={500}>
                    {scoreVal.mark === 1 ? (
                      <Done sx={{ color: "green" }} />
                    ) : scoreVal.mark === 0 ? (
                      <Close sx={{ color: "red" }} />
                    ) : (
                      "-"
                    )}
                  </Typography>
                ) : (
                  // <ScoreBoardIconDiv
                  //   style={{ cursor: "pointer" }}
                  //   onClick={(e) => handleNA(scoreVal.startnumber)}
                  // >
                  <Typography variant="body" color={"gray"} fontWeight={500}>
                    {scoreVal.mark === 1
                      ? "A"
                      : scoreVal.mark === 2
                      ? "B"
                      : scoreVal.mark === 3
                      ? "C"
                      : scoreVal.mark === 4
                      ? "D"
                      : "-"}
                  </Typography>
                  // </ScoreBoardIconDiv>
                )}
              </ScoreBoardIconDiv>
              <ScoreBoardIconDiv>
                {scoreVal.flag ? <Flag sx={{ color: "red" }} /> : null}
              </ScoreBoardIconDiv>
            </div>
          ))}
        </div>
      </ScoreBox>
    </div>
  );
}

export default ScoreBoard;
