import {
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import {
  StyledQuesDiv,
  StyledQuizDiv,
  StyledRadio
} from "../../styledcomponents/styledQuizWidgets";

function QuizQuestion({ quesData, submitAnswer, type, updateScoreData }) {
  const theme = useTheme();
  const getSelectedAnswerId = (quesData) => {
    const selectedAnswer = quesData?.answer?.find((ans) => ans.selectedanswer);
    return selectedAnswer ? selectedAnswer.id : undefined;
  };
  const [answerRadio, setAnswerRadio] = useState(getSelectedAnswerId(quesData));
  const [quesDetails, setQuesDetails] = useState(quesData);

  // useEffect(() => {
  //   setAnswerRadio(
  //     quesData?.answer?.filter((obj) => obj.selectedanswer === true)[0].id
  //   );
  // }, []);

  const handleChange = (event) => {
    let quesVal = { ...quesDetails };
    quesVal.answer.forEach((item, id) => {
      item.selectedanswer = false;
      if (parseInt(item.id) === parseInt(event.target.value)) {
        item.selectedanswer = true;
        if (type !== "quiz") {
          updateScoreData(id + 1);
        }
      }
    });
    quesVal.answeredstatus = true;
    setAnswerRadio(event.target.value);
    setQuesDetails(quesVal);
    if (type !== "quiz") {
      // updateScoreData();
      submitAnswer(quesDetails.questionid, event.target.value);
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div key={quesDetails?.questionid}>
      <StyledQuizDiv>
        {quesDetails && (
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={answerRadio}
              style={{ width: "100%" }}
              onChange={handleChange}
            >
              {quesDetails?.answer?.map((ans, id) => (
                <StyledQuesDiv key={id}>
                  <div
                    style={{
                      width: "100%"
                    }}
                  >
                    <FormControlLabel
                      value={ans?.id}
                      control={<StyledRadio size="small" />}
                      label={
                        <Typography
                          variant="body2"
                          color={
                            theme.palette.mode === "dark" ? "#fff" : "#5A5A5A"
                          }
                        >
                          <div
                            style={{ margin: "1rem" }}
                            dangerouslySetInnerHTML={{ __html: ans.answer }}
                          />
                        </Typography>
                      }
                    />
                  </div>
                  {quesDetails?.answer?.length === id + 1 ? null : <Divider />}
                </StyledQuesDiv>
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </StyledQuizDiv>
      {type === "quiz" ? (
        <StyledGreenButton
          style={{ outline: "none" }}
          disabled={quesDetails?.answeredstatus === false}
          onClick={() => submitAnswer(quesDetails?.questionid, answerRadio)}
        >
          Submit answer
        </StyledGreenButton>
      ) : null}
    </div>
  );
}

export default QuizQuestion;
