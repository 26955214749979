import { Grid, List, ListItem, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import homeImgBlue from "../../assets/home-banner.png";
import {
  offerContent,
  welcomeContent,
  whyPgneetContent,
  whyPgneetContent2
} from "../Common/beforeLoginPageContents";
import {
  AboutParaTypo,
  BlueContainerSection,
  GrayHomeSection,
  HomeBannerHead,
  HomeBannerRigthdiv,
  HomeBannerTypo,
  HomeRegButton,
  SectionDiv3,
  SectionDivMockTest,
  SectionDivQb,
  SectionGrid,
  SectionHead,
  TransparentHead,
  TransparentHead1,
  TransparentHomeDiv,
  TransparentPara,
  TransparentPara1,
  TransparentSection,
  WhiteHomeSection,
  AnnouncePara,
  AnnouncePara1
} from "../styledcomponents/beforeLoginWidgets";
import { StyledGreenButton } from "../styledcomponents/styledDBwidgets";
import {
  dashboardPricingAPi,
  dashboardinstructionAPi
} from "../../http/allApis";
import Announce from "../../assets/Announce.jpg";

function HomePage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(1200));
  const myRef = useRef();
  const [qbOffer, setQBoffer] = useState();
  const [instValue, setInstValue] = useState([]);
  // const [objValue, setObjValue] = useState();
  const [boolean, setBoolean] = useState(false);

  useEffect(() => {
    const y = myRef.current.offsetTop;
    if (window.location.pathname === "/") {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, y - 70);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    dashboardPricingAPi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setBoolean(true);
          let data = res?.data?.data.filter(handleOffer);
          const firstThreeItems = data.slice(0, 3);
          setQBoffer(firstThreeItems);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    dashboardinstructionAPi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          let data = res?.data?.data;
          setInstValue(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [boolean]);

  const handleOffer = (item) => {
    return item.examId === 1;
  };
  return (
    <div>
      <BlueContainerSection>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <img
              src={homeImgBlue}
              style={{
                height: isSmall ? "auto" : "16rem",
                width: isSmall ? "100%" : "auto",
                marginLeft: isSmall ? "none" : "-.5rem"
                // marginTop: isSmall ? "none" : "-.5rem",
              }}
              alt=""
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <HomeBannerRigthdiv>
              <HomeBannerHead variant="h3">PGNEET</HomeBannerHead>
              <HomeBannerTypo
                sx={{
                  textShadow: "2px 4px 5px #444",
                  marginTop: isSmall ? "none" : "-3%"
                }}
                variant="h5"
              >
                Study. Revise. Test.
              </HomeBannerTypo>
              <HomeRegButton
                onClick={() => navigate("/login", { state: "signup" })}
              >
                Register Now
              </HomeRegButton>
            </HomeBannerRigthdiv>
          </Grid>
        </Grid>
      </BlueContainerSection>
      {/* <div>
        <img scr={Announce} alt="image" width={100} /> */}
      {/* {instValue.map((item) =>
        item.instStatus ? (
          <AnnouncePara1>
            <TransparentHead variant="h4" fontWeight={"bold"} align="center">
              Announcement
            </TransparentHead>
            <AnnouncePara style={{ margin: "1rem 0" }}>
              {item.instHeading}
            </AnnouncePara>
          </AnnouncePara1>
        ) : null
      )} */}
      {/* </div> */}
      <GrayHomeSection>
        <TransparentHead variant="h4" fontWeight={"bold"} align="center">
          Welcome
        </TransparentHead>
        <div style={{ margin: "1rem 0" }}>
          <TransparentPara>{welcomeContent.content1}</TransparentPara>
          <br />
          <TransparentPara>{welcomeContent.content2}</TransparentPara>
          <br />
          <TransparentPara>{welcomeContent.content3}</TransparentPara>
          <br />
          <TransparentPara>{welcomeContent.content4}</TransparentPara>
        </div>
      </GrayHomeSection>
      <WhiteHomeSection ref={myRef}>
        <TransparentHead
          variant="h4"
          fontWeight={"bold"}
          align="center"
          marginBottom={".8rem"}
        >
          What we offer
        </TransparentHead>
        <div style={{ width: "100%" }}>
          <Grid container>
            <SectionGrid item xs={12} sm={4} md={4} lg={4}>
              <SectionDivQb>
                <div style={{ marginBottom: "1rem" }}>
                  <SectionHead variant="h5" align="center" fontWeight={"bold"}>
                    Question Bank
                  </SectionHead>
                  <TransparentPara>
                    {offerContent.content1.text}
                  </TransparentPara>
                </div>
                <SectionHead
                  variant="h5"
                  fontWeight={"bold"}
                  marginTop={"1rem"}
                >
                  Pricing
                </SectionHead>
                {qbOffer?.map((item, index) => (
                  <div key={item.id}>
                    {index === 0 && (
                      <>
                        <TransparentPara fontWeight={"bold"}>
                          {item.offerAmount === 0 ? (
                            `QBank 1-month subscription ₹ ${item.amount}`
                          ) : (
                            <>
                              <s
                                style={{
                                  marginBottom: ".3rem"
                                }}
                              >
                                <span>
                                  QBank 1-month subscription ₹{item.amount}
                                </span>
                              </s>
                              <br />
                              <span
                                style={{
                                  color: "#de1a24",
                                  fontSize: "13px",
                                  fontWeight: "bold"
                                }}
                              >
                                Offer :
                              </span>{" "}
                              QBank 1-month subscription ₹{item.offerAmount}
                            </>
                          )}
                        </TransparentPara>
                        <TransparentPara style={{ marginBottom: "1rem" }}>
                          {offerContent.content1.QBPricingFirst}
                        </TransparentPara>
                      </>
                    )}

                    {index === 1 && (
                      <>
                        <TransparentPara fontWeight={"bold"}>
                          {/* {`QBank 3-month subscription ₹ ${item.amount}`} */}
                          {item.offerAmount === 0 ? (
                            `QBank 3-month subscription ₹ ${item.amount}`
                          ) : (
                            <>
                              <s
                                style={{
                                  marginBottom: ".3rem"
                                }}
                              >
                                <span>
                                  QBank 3-month subscription ₹{item.amount}
                                </span>
                              </s>
                              <br />
                              <span
                                style={{ color: "#de1a24", fontSize: "13px" }}
                              >
                                Offer :
                              </span>{" "}
                              QBank 3-month subscription ₹{item.offerAmount}
                            </>
                          )}
                        </TransparentPara>
                        <TransparentPara style={{ marginBottom: "1rem" }}>
                          {offerContent.content1.QBPricingSec}
                        </TransparentPara>
                      </>
                    )}
                    {index === 2 && (
                      <>
                        <TransparentPara fontWeight={"bold"}>
                          {" "}
                          {item.offerAmount === 0 ? (
                            `QBank 6-month subscription ₹ ${item.amount}`
                          ) : (
                            <>
                              <s
                                style={{
                                  marginBottom: ".3rem"
                                }}
                              >
                                <span>
                                  QBank 6-month subscription ₹{item.amount}
                                </span>
                              </s>
                              <br />
                              <span
                                style={{ color: "#de1a24", fontSize: "13px" }}
                              >
                                Offer :
                              </span>{" "}
                              QBank 6-month subscription ₹{item.offerAmount}
                            </>
                          )}
                        </TransparentPara>
                        <TransparentPara style={{ marginBottom: "1rem" }}>
                          {offerContent.content1.QBPricingThird}
                        </TransparentPara>
                      </>
                    )}
                  </div>
                ))}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "60px"
                    // marginTop: "2rem",
                  }}
                >
                  <StyledGreenButton
                    onClick={() => navigate("questionbank")}
                    style={{
                      marginTop: "1rem",
                      width: "200px",
                      height: "fit-content",
                      fontSize: "16px",
                      fontWeight: "bold"
                    }}
                  >
                    Subscribe to QBank
                  </StyledGreenButton>
                </div>
              </SectionDivQb>
            </SectionGrid>
            <SectionGrid item xs={12} sm={4} md={4} lg={4}>
              <SectionDivMockTest>
                <div>
                  <SectionHead variant="h5" align="center" fontWeight={"bold"}>
                    Mock Test
                  </SectionHead>
                  <TransparentPara>
                    {offerContent.content2.line1}
                  </TransparentPara>
                  <TransparentPara>
                    {offerContent.content2.line2}
                  </TransparentPara>
                  <TransparentPara>
                    {offerContent.content2.line3}
                  </TransparentPara>
                  <TransparentPara>
                    {offerContent.content2.line4}
                  </TransparentPara>
                </div>
                {/*<TransparentPara marginTop={"3.5rem"}>
                  {offerContent.content3.text1}
                </TransparentPara>*/}
                <TransparentPara marginTop={"3.5rem"} fontWeight={"bold"}>
                  {offerContent.content2.MQBPricing1}
                </TransparentPara>
                <TransparentPara marginTop={"1.5rem"}>
                  {offerContent.content2.MQBPricingFirst}
                </TransparentPara>
                <div
                  style={{
                    marginTop: "32%",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "end",
                    alignItems: "center",
                    fontSize: "16px",
                    height: "60px"
                  }}
                >
                  <StyledGreenButton
                    onClick={() => navigate("mocktest")}
                    style={{
                      marginTop: "1rem",
                      alignItems: "bottom",
                      width: "200px",
                      fontSize: "16px",
                      fontWeight: "bold"
                    }}
                  >
                    Subscribe to Mock Test
                  </StyledGreenButton>
                </div>
              </SectionDivMockTest>
            </SectionGrid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <SectionDiv3>
                <div>
                  <SectionHead
                    Typography
                    variant="h5"
                    align="center"
                    fontWeight={"bold"}
                  >
                    High Yield Notes
                  </SectionHead>
                  <TransparentPara>
                    {offerContent.content3.text}
                  </TransparentPara>
                </div>
                <br />
                <TransparentPara marginTop={"3.5rem"}>
                  {offerContent.content3.text1}
                </TransparentPara>
              </SectionDiv3>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              marginTop: "2rem",
              justifyContent: "center"
            }}
          >
            <TransparentPara>
              If you're already registered and would like to{" "}
              <span
                onClick={() => navigate("/login")}
                style={{
                  color: "#31a188",
                  cursor: "pointer",
                  textDecoration: "none"
                }}
              >
                {" "}
                extend subscription
              </span>{" "}
              or{" "}
              <span
                onClick={() => navigate("/login")}
                style={{
                  color: "#31a188",
                  cursor: "pointer",
                  textDecoration: "none"
                }}
              >
                {" "}
                add a resource
              </span>{" "}
              to your account, please go to{" "}
              <span
                onClick={() => navigate("/login")}
                style={{
                  color: "#31a188",
                  cursor: "pointer",
                  textDecoration: "none"
                }}
              >
                {" "}
                My Account
              </span>{" "}
              page after logging in.
            </TransparentPara>
          </div>
        </div>
      </WhiteHomeSection>
      <TransparentSection>
        <TransparentHomeDiv>
          <TransparentHead1 variant="h4" fontWeight={"bold"} align="center">
            Why PGNEET©?
          </TransparentHead1>
          <div style={{ marginTop: "1rem" }}>
            <TransparentPara1>
              {whyPgneetContent.FQR}
              <TransparentPara1 style={{ marginTop: "1rem" }}>
                {whyPgneetContent.ORI}
              </TransparentPara1>
              <List style={{ marginTop: "1rem" }}>
                {whyPgneetContent2.map((item) => (
                  <ListItem>
                    <ul style={{ color: "white" }}>
                      <li>{item}</li>
                    </ul>
                  </ListItem>
                ))}
              </List>
            </TransparentPara1>
          </div>
        </TransparentHomeDiv>
      </TransparentSection>
      {/* {instValue.map((item) =>
        item.imageStatus ? (
          <Modal
            // style={{  }}
            width={400}
            height={500}
            // title={
            //   <Typography variant="h6" gutterBottom>
            //     Add Exam{" "}
            //   </Typography>
            // }
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            // onCancel={handleClose}
            footer={null}
          >
            <div
              style={{
                margin: "1rem 0",
                backgroundColor: "#fff",
                padding: "2rem 1rem"
              }}
            >
              <img
                src={objValue?.instImage}
                alt="image"
                width={300}
                height={200}
              />
            </div>
          </Modal>
        ) : null
      )} */}
    </div>
  );
}

export default HomePage;
