import {
  alpha,
  Button,
  Grid,
  styled,
  TextField,
  Typography
} from "@mui/material";
import transparentBg from "../../assets/transparentBg.png";

export const BlueContainerSection = styled("section")(({ theme }) => ({
  background: "linear-gradient(180deg,#396a8e,#4aaecc)",
  padding: "1rem 9%",
  [theme.breakpoints.down(1200)]: {
    padding: "1.5rem 4%"
  }
}));

export const BlueContainerSection1 = styled("section")(({ theme }) => ({
  background: "linear-gradient(180deg,#396a8e,#4aaecc)",
  padding: "1rem 9%",
  [theme.breakpoints.down(1200)]: {
    padding: "1.5rem 4%"
  }
}));

export const GrayHomeSection = styled("section")(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : "#f8f8f8",
  padding: "3rem 9%",
  color: "rgb(12 26 39)",
  [theme.breakpoints.down(1200)]: {
    padding: "2rem 4%"
  }
}));

export const WhiteHomeSection = styled("section")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: "3rem 9%",
  color: "rgb(12 26 39)",
  [theme.breakpoints.down(1200)]: {
    padding: "2rem 4%"
  }
}));

export const HomeBannerRigthdiv = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "end",
  color: "#fff",
  flexDirection: "column",
  gap: "1rem"
}));

export const TransparentSection = styled("section")(({ theme }) => ({
  backgroundAttachment: "fixed",
  backgroundImage: `url(${transparentBg})`,
  backgroundRepeat: "no-repeat"
}));

export const TransparentHomeDiv = styled("div")(({ theme }) => ({
  backgroundColor: "rgb(12 26 39 / 90%)",
  padding: "3rem 9%",
  color: "#fff",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "2rem 4%"
  },
  [theme.breakpoints.down(600)]: {
    padding: "2rem 4%"
  }
}));

export const SectionGrid = styled(Grid)(({ theme }) => ({
  borderRight: "2px dashed gray",
  [theme.breakpoints.down(600)]: {
    borderBottom: "2px dashed gray"
  }
}));

export const SectionDivQb = styled("div")(({ theme }) => ({
  padding: "1rem 2rem 1rem 0",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.between(600, 900)]: {
    padding: "0 1rem 0 0",
    borderRight: "2px dashed gray",
    marginTop: "1rem"
  },
  [theme.breakpoints.between(900, 1200)]: {
    padding: "0 1rem 0 0",
    marginTop: "1rem"
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    height: "auto",
    borderRight: "none"
  }
}));

export const SectionDivMockTest = styled("div")(({ theme }) => ({
  padding: "1rem 2rem",
  height: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.between(600, 900)]: {
    padding: "0 1rem 0 0",
    borderRight: "2px dashed gray",
    marginTop: "1rem"
  },
  [theme.breakpoints.between(900, 1200)]: {
    padding: "0 1rem 0 0",
    marginTop: "1rem"
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    height: "auto",
    borderRight: "none"
  }
}));

export const SectionDiv3 = styled("div")(({ theme }) => ({
  padding: "1rem 0 1rem 2rem",
  height: "100%",
  [theme.breakpoints.between(600, 900)]: {
    padding: "0 0 0 1rem",
    marginTop: "1rem"
  },
  [theme.breakpoints.between(900, 1200)]: {
    padding: "0 0 0 1rem",
    marginTop: "1rem"
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    height: "auto"
  }
}));

export const SectionHead = styled(Typography)(({ theme }) => ({
  marginBottom: "1rem",
  color: "#31a188",
  [theme.breakpoints.down(900)]: {
    fontSize: "16px"
  }
}));

export const TransparentHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  [theme.breakpoints.down(900)]: {
    fontSize: "20px"
  }
}));

export const TransparentPara = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  [theme.breakpoints.down(900)]: {
    fontSize: "12px"
  }
}));

// export const AnnouncePara1 = styled(Typography)(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark"
//       ? theme.palette.background.default
//       : "#f8f8f8",
//   padding: "3rem 9%",
//   color: "rgb(12 26 39)",
//   [theme.breakpoints.down(1200)]: {
//     padding: "2rem 4%"
//   }
// }));

// export const AnnouncePara = styled(Typography)(({ theme }) => ({
//   color: theme.palette.text.primary,
//   [theme.breakpoints.down(900)]: {
//     fontSize: "12px"
//   }
// }));

export const TransparentHead1 = styled(Typography)(({ theme }) => ({
  color: "#fff",
  [theme.breakpoints.down(900)]: {
    fontSize: "20px"
  }
}));

export const TransparentPara1 = styled(Typography)(({ theme }) => ({
  color: "#fff",
  [theme.breakpoints.down(900)]: {
    fontSize: "12px"
  }
}));

export const HomeRegButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "50px",
  color: "#fff",
  width: "9rem",
  outline: "none",
  backgroundColor: "#006089",
  ":hover": {
    color: "#fff",
    backgroundColor: "#006089"
  },
  [theme.breakpoints.down(600)]: {
    width: "6rem",
    fontSize: "11px"
  }
}));

export const HomeBannerHead = styled(Typography)(({ theme }) => ({
  textShadow: "2px 4px 5px #444",
  color: "#fff",
  [theme.breakpoints.between(600, 900)]: {
    fontSize: "30px"
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "25px"
  }
}));

export const HomeBannerTypo = styled(Typography)(({ theme }) => ({
  textShadow: "2px 4px 5px #444",
  color: "#fff",
  [theme.breakpoints.between(600, 900)]: {
    fontSize: "20px"
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "15px"
  }
}));

// about page

export const AboutBannerHead = styled(Typography)(({ theme }) => ({
  margin: "1rem 0",
  // fontSize: "3rem",
  [theme.breakpoints.between(600, 900)]: {
    fontSize: "2rem"
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "1.5rem",
    margin: ".5rem 0"
  }
}));

export const AboutBannerHead1 = styled(Typography)(({ theme }) => ({
  margin: "1rem 0",
  // fontSize: "2rem",
  [theme.breakpoints.between(600, 900)]: {
    fontSize: "2rem"
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "1.5rem",
    margin: ".5rem 0"
  }
}));

export const AboutParaTypo = styled(Typography)(({ theme }) => ({
  width: "100%",
  textalign: "center",
  borderbottom: "1px solid gray",
  // lineheight: "0.1em",
  margin: "5px 0 10px",

  // color: theme.palette.text.primary,
  [theme.breakpoints.between(900, 1200)]: {
    fontSize: "14px"
  },
  [theme.breakpoints.down(900)]: {
    fontSize: "10px"
  }
}));
export const BlogWelParaTypo = styled(Typography)(({ theme }) => ({
  width: "100%",
  textalign: "center",
  borderbottom: "1px solid gray",
  // lineheight: "0.1em",
  margin: "5px 0 10px",

  // color: theme.palette.text.primary,
  [theme.breakpoints.between(900, 1200)]: {
    fontSize: "14px"
  },
  [theme.breakpoints.down(900)]: {
    fontSize: "10px"
  }
}));
export const AboutParaTypoDiv = styled(Typography)(({ theme }) => ({
  color: "#fff",
  textAlign: "center",
  borderRadius: "50px",
  padding: ".2rem 1rem",
  [theme.breakpoints.between(900, 1200)]: {
    fontSize: "12px",
    marginLeft: "-1rem",
    borderRadius: "10px",
    padding: ".1rem .5rem",
    display: "flex",
    justifyContent: "center"
  },
  [theme.breakpoints.down(900)]: {
    fontSize: "10px",
    borderRadius: "10px",
    padding: ".1rem .5rem",
    display: "flex",
    justifyContent: "center"
  }
}));
export const AboutParaTypo1 = styled(Typography)(({ theme }) => ({
  width: "100%",
  textalign: "center",
  borderbottom: "1px solid gray",
  [theme.breakpoints.between(900, 1200)]: {
    fontSize: "14px"
  },
  [theme.breakpoints.down(900)]: {
    fontSize: "12px"
  }
}));

export const AboutHead = styled(Typography)(({ theme }) => ({
  color: "#31a188",
  [theme.breakpoints.between(900, 1200)]: {
    fontSize: "17px"
  },
  [theme.breakpoints.down(900)]: {
    fontSize: "15px"
  }
}));

export const ContentDiv = styled("div")(({ theme }) => ({
  padding: "2rem 9%",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1.5rem 4%"
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem 4%"
  }
}));
export const BlogWelDiv = styled("div")(({ theme }) => ({
  // padding: "2rem 9%",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1.5rem 4%"
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem 4%"
  }
}));
export const BlogDiv = styled("div")(({ theme }) => ({
  padding: "1rem 6%",
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.background.default : "#fff",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1rem",
    fontSize: "10px"
  },
  [theme.breakpoints.down(600)]: {
    display: "none"
    // fontSize: "7px"
  }
}));
export const BlogArticalDiv = styled("div")(({ theme }) => ({
  padding: "1rem 0",
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.background.default : "#FFF",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1rem 6%",
    fontSize: "15px",
    marginLeft: "1rem"
  },
  [theme.breakpoints.down(1200)]: {
    padding: "2rem 4%"
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "15px",
    padding: "1rem 0"
  }
}));
export const BlogResArticalDiv = styled("div")(({ theme }) => ({
  padding: "1rem 0",
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.background.default : "#FFF",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1rem 6%",
    fontSize: "10px"
    // marginLeft: "1rem"
  },
  [theme.breakpoints.down(600)]: {
    // fontSize: "15px"
  }
}));
export const BlogRMBackGround = styled("div")(({ theme }) => ({
  position: "relative",
  height: "200px",
  overflow: "hidden"
}));
export const BlogRMHeading = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  marginLeft: "8rem",
  color: "white",
  fontSize: "26px",
  fontWeight: "bold",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
  [theme.breakpoints.down(600)]: {
    marginLeft: "1rem"
  },
  [theme.breakpoints.between(600, 1200)]: {
    // fontSize: "15px"
    marginLeft: "2rem"
  }
}));
export const BlogSpan = styled("div")(({ theme }) => ({
  fontSize: "20px",
  color: "#f38424",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  [theme.breakpoints.between(600, 1200)]: {
    fontSize: "15px"
  },
  [theme.breakpoints.down(600)]: {
    display: "none"
  }
}));

export const BlogResDes = styled("div")(({ theme }) => ({
  padding: "1rem 6%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : "#FAFAFA",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1rem",
    fontSize: "10px"
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "15px"
  }
}));
export const BlogResDiv = styled("div")(({ theme }) => ({
  padding: "1rem 6%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : "#FAFAFA",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  display: "none",
  [theme.breakpoints.down(600)]: {
    display: "block",
    fontSize: "7px"
  }
}));
export const ArticalFileDiv = styled("div")(({ theme }) => ({
  padding: "1rem 6%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : "#FAFAFA",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  display: "none",
  [theme.breakpoints.down(600)]: {
    display: "block",
    fontSize: "7px"
  }
}));

export const RespDiv = styled("div")(({ theme }) => ({
  padding: "1rem 6%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : "#FAFAFA",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  display: "none",
  [theme.breakpoints.down(600)]: {
    display: "block",
    fontSize: "7px"
  }
}));
export const ResponDesign = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b"
  }
}));
export const Darkdiv = styled("div")(({ theme }) => ({
  padding: "1rem 6%",
  fontSize: "14px",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.background.default
      : "#FAFAFA",
  color: theme.palette.mode === "dark" ? "white" : "black ",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1.5rem 4%"
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem 4%"
  }
}));
export const ButtonDes = styled(Button)(({ theme }) => ({
  fontSize: "15px",
  textTransform: "none",
  outline: "none",
  margin: ".7rem",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.mode === "dark" ? "#31a188" : "#31a188",
    color: theme.palette.mode === "dark" ? "white" : "white",
    outline: "none"
  },
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1rem 2%"
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem 4%"
  }
}));
export const BlogSearch = styled("div")(({ theme }) => ({
  margin: "1rem",
  padding: "1rem ",
  backgroundColor: "#DFF3E2 ",
  // backgroundColor: theme.palette.mode === "dark" ? "#DFF3E2" : "#DFF3E2",
  color: theme.palette.mode === "dark" ? "black" : "white",
  outline: "none",
  [theme.breakpoints.between(600, 1200)]: {
    padding: "1rem 2%",
    fontSize: "13px"
  },
  [theme.breakpoints.down(600)]: {
    display: "block"
  }
}));
export const Searchdiv = styled("div")(({ theme }) => ({
  outline: "none",
  padding: ".6rem",
  marginBottom: "1rem",
  fontSize: "19px",
  color: theme.palette.mode === "dark" ? "black" : "black",
  [theme.breakpoints.between(600, 1200)]: {
    // padding: "1rem 2%"
    fontSize: "13px"
  }
}));

//conatct page

export const FilledTextField = styled((props) => (
  <TextField
    InputLabelProps={{ shrink: true }}
    InputProps={{ disableUnderline: true }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow"
    ]),
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-focused": {
      backgroundColor: theme.palette.mode === "light" ? "#E8F0FE" : "#1f2129d9",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  }
}));

// Login Page
export const LoginHeader = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.between(600, 900)]: {
    fontSize: "1.5rem"
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "1.2rem"
  }
}));

export const SocialButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.mode === "dark" ? "#fff" : "#515151",
  borderColor: "#dadce0",
  backgroundColor: "#fff",
  minWidth: "2.5rem",
  padding: ".4rem",
  outline: "none",
  width: "100%",
  "&:hover": {
    backgroundColor: "white",
    Text: "white"
  },
  [theme.breakpoints.down(600)]: {
    marginRight: "0",
    width: "100%"
  }
}));
