import {
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useState } from "react";
import {
  StyledBeforeFooterDiv,
  StyledFooterIconButton,
  StyledFooterText
} from "../styledcomponents/styledDBwidgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTelegram,
  faTwitter,
  faTwitterSquare
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import Twi from "../../assets/x-icon-png.png";
import fb from "../../assets/fb.png";
import tel from "../../assets/tel.png";
import { socialSignUpApi } from "../../http/allApis";
import { SocialIcon } from "react-social-icons";
function FooterBeforeLogin({ buttonFor, callBackFunction, handleAlert }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const [modelWin, setModelWin] = useState(false);

  const [resp, setResp] = useState();
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "2841983716098248",
      cookie: true,
      xfbml: true,
      version: "v12.0"
    });

    window.FB.getLoginStatus(function (response) {
      console.log(response);
      if (response.status === "connected") {
        console.log("User is logged in");
      } else {
        console.log("User is not logged in");
      }
    });
  };

  function loginWithFacebook() {
    window.FB.login(
      function (response) {
        setResp(response);
        if (response.authResponse) {
          let payload1 = {
            facebookId: response.authResponse.userID,
            webToken: response.authResponse.accessToken,
            signupType: "Facebook"
          };
          socialSignUpApi(payload1, "signup")
            .then((res) => {
              if (res.data.status === "Success") {
                sessionStorage.setItem("token", res.data.data.accessToken);
                sessionStorage.setItem(
                  "Tokentype",
                  JSON.stringify(res?.data?.data)
                );
                callBackFunction(res.data.data);
              } else if (res.data.status === "Failure") {
                handleAlert(res.data.message, "warning");
              } else {
                if (
                  res.data.error.description ===
                  "You Are Already Login.You Need to Continue This Session."
                ) {
                  setModelWin(true);
                } else {
                  handleAlert(res.data.error.description, "warning");
                }
              }
            })
            .catch((response) => {
              console.log(response);
            });
        } else {
          // User canceled the login process or didn't authorize the app
          console.log("User canceled login or did not authorize the app");
        }
      },
      { scope: "email" }
    );
  }
  // function isTelegramAppInstalled() {
  //   var anchor = document.createElement("a");
  //   anchor.href = "https://t.me/pgneet_net";
  //   if (typeof anchor.click === "function") {
  //     try {
  //       anchor.click();
  //       return false;
  //     } catch (error) {
  //       return true;
  //     }
  //   } else {
  //     return true;
  //   }
  // }

  // const handleTelegramClick = () => {
  //   if (isTelegramAppInstalled()) {
  //     window.location.href = "tg://resolve?domain=pgneet_net";
  //   } else {
  //     window.open("https://t.me/pgneet_net", "_blank");
  //   }
  // };

  return (
    <StyledBeforeFooterDiv>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div style={{ textAlign: isSmall ? "center" : "start" }}>
            <Typography variant={"caption"}>
              &copy;2024 PGNEET. All rights reserved.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SocialIcon
              network="facebook"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://www.facebook.com/PGNeetnet-107422365370696"
            />
            <SocialIcon
              network="instagram"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://www.instagram.com/pgneet_net/"
            />
            <SocialIcon
              network="x"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://twitter.com/pgneet_net"
            />
            <SocialIcon
              network="snapchat"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
            />
            <SocialIcon
              network="telegram"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://t.me/pgneet_net"
            />
            <SocialIcon
              network="whatsapp"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div
            style={{
              display: "flex",
              justifyContent: isSmall ? "center" : "end",
              marginTop: ".2rem"
            }}
          >
            <StyledFooterText
              onClick={() => navigate("/terms")}
              variant={"caption"}
              style={{ marginRight: "1rem" }}
            >
              Terms and Conditions
            </StyledFooterText>
            <StyledFooterText
              onClick={() => navigate("/privacy-policy")}
              variant={"caption"}
              style={{ marginRight: "1rem" }}
            >
              Privacy Policy
            </StyledFooterText>
            <StyledFooterText
              onClick={() => navigate("/copy-rights")}
              variant={"caption"}
            >
              Copyright Credits
            </StyledFooterText>
          </div>
        </Grid>
      </Grid>
    </StyledBeforeFooterDiv>
  );
}

export default FooterBeforeLogin;
