import { Box, Button, styled } from "@mui/material";

export const StyledQBBlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#6c757d",
  color: "#fff",
  textTransform: "none",
  fontWeight: 300,
  borderRadius: "0",
  borderTopRightRadius: "4px",
  outline: "none",
  borderBottomRightRadius: "4px",
  width: "11rem",
  // margin: ".5rem 0 1rem 0",
  "&:hover": {
    backgroundColor: "#6c757d",
    color: "#fff"
  },
  [theme.breakpoints.down(600)]: {
    width: "7rem"
  }
}));

export const TimedTestDiv = styled("div")({
  marginBottom: "1rem"
});

export const ScoreBox = styled(Box)(({ theme }) => ({
  border: "1.5px solid #EBECF0",
  borderRadius: "6px",
  marginTop: ".5rem",
  width: "15rem",
  textAlign: "center",
  [theme.breakpoints.down(600)]: {
    width: "100%"
  }
}));

export const QBScoreEndButton = styled(Button)(({ theme }) => ({
  width: "15rem",
  backgroundColor: "#31a188",
  color: "#fff",
  textTransform: "none",
  fontWeight: 300,
  lineHeight: "1.2rem",
  marginTop: "1rem",
  outline: "none",
  "&:hover": {
    backgroundColor: "#f38424",
    color: "#fff"
  },
  ":disabled": {
    backgroundColor: "#f1f1f1",
    color: "gray"
  },
  [theme.breakpoints.down(600)]: {
    width: "100%",
    marginTop: "0"
  }
}));

export const ScoreBoardIconDiv = styled("div")({
  width: "33.3%"
});

export const StyledButton = styled(Button)({
  backgroundColor: "#f38424",
  outline: "none",
  textTransform: "none",
  color: "#fff",
  margin: ".5rem 0 1rem 0",
  "&:hover": {
    backgroundColor: "#f38424",
    color: "#fff"
  },
  ":disabled": {
    backgroundColor: "#f1f1f1",
    color: "gray"
  }
});

export const StyledQBSearchDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1.5rem",
  border: "1px solid #adadad",
  borderRadius: "4px",
  marginBottom: "1rem"
});

export const HowToUseDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "1rem",
  border: "1px solid #0c7083",
  borderRadius: "4px",
  padding: ".3rem",
  color: "#0c7083"
});

export const PassBotDiv = styled("div")(({ theme }) => ({
  // backgroundColor: "#d1ecf1",
  // border: "1px solid #d1ecf1",
  color: theme.palette.mode === "dark" ? "white" : "black",
  backgroundColor: "#b8f1d485",
  border: "1px solid #b8f1d485",
  borderRadius: "4px",
  padding: "1rem"
}));

export const PassBotBlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#0c7083",
  color: "#fff",
  textTransform: "none",
  fontWeight: 300,
  marginTop: "1rem",
  "&:hover": {
    backgroundColor: "#0c7083",
    color: "#fff"
  }
}));
