import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import useStyles from "./DropZoneStyles";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  MenuItem,
  Snackbar,
  TextField
} from "@mui/material";
import { useEffect } from "react";
import {
  SettingCategoryApi,
  SettingCategoryApiId,
  SettingSubCategoryApi,
  SettingSubCategoryApiId,
  SettingSubjectApi,
  csvUploadApi
} from "../../../http/allAdminApi";
import Axios from "axios";
import constantValues from "../../../components/Common/ConstantValues";
import ModelFile from "../../landing/ModelFile";

function Dropzone({ type, accept, open }) {
  const classes = useStyles();
  const location = window?.location?.pathname;
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [openSB, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [catOptions, setCatOptions] = useState([]);
  const [subject, setSubject] = useState("");
  const [catVal, setCatVal] = useState([]);
  const [category, setCategory] = useState("");
  const [subCatVal, setSubCatVal] = useState([]);
  const [subVal, setSubVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDrop: (files) => setAcceptedFiles(files),
    multiple: false
  });
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  useEffect(() => {
    if (
      location != "/admin/mock/importcsv" &&
      subject != undefined &&
      subject != null &&
      subject != ""
    ) {
      SettingCategoryApiId(subject).then((res) => {
        setCatVal(res.data.data);
      });
    }
  }, [subject]);

  // useEffect(() => {
  //   SettingSubjectApi().then((res) => {
  //     setCatOptions(res.data.data);
  //   });
  // }, []);

  useEffect(() => {
    if (category != undefined && category != null && category != "") {
      SettingSubCategoryApiId(subject).then((res) => {
        setSubCatVal(res.data.data);
      });
    }
  }, [category]);

  const handleApiClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      acceptedFiles[0] !== undefined &&
      acceptedFiles[0]?.type !== "text/csv"
    ) {
      alert("Upload CSV file only");
      setAcceptedFiles([]);
    }
  }, [acceptedFiles]);

  const handleCSV = () => {
    // setLoading(true);
    if (acceptedFiles[0]) {
      // let payloadAll = {
      //   subjectId: subject,
      //   categoryId: category,
      //   subCategoryId: subVal,
      // };
      // let payloadMock = {
      //   subjectId: subject,
      //   categoryId: null,
      //   subCategoryId: null,
      // };
      const uploadData = new FormData();
      uploadData.append("file", acceptedFiles[0], acceptedFiles[0]?.name);
      uploadData.append("flag", type);
      console.log(uploadData, "Mock");
      csvUploadApi(uploadData)
        .then((res) => {
          if (res.data.status === "Success") {
            setSuccessMsg("Questions imported successfully");
            setAlertstatus("success");
            setOpen(true);
            setLoading(false);
            // setCategory("");
            // setSubVal("");
            // setSubject("");
            setAcceptedFiles([]);
          } else {
            setSuccessMsg(res?.data?.error?.description);
            setAlertstatus("warning");
            setOpen(true);
            return Promise.reject(res);
          }
        })
        .catch((response) => {
          if (response?.response?.data?.status === 500) {
            setModelWin(true);
            setCatchError(response?.response?.data?.message);
          } else {
            setSuccessMsg(response.data.error.description);
            setAlertstatus("warning");
            setOpen(true);
          }
        });
    } else {
      alert("Please select the file.");
    }
  };

  return (
    <div>
      {/* <div>
        <TextField
          select
          label="Subject"
          size="small"
          value={subject}
          id="name"
          style={{
            margin: ".5rem 0 1.5rem 0",
          }}
          fullWidth
          onChange={(e) => setSubject(e.target.value)}
        >
          <MenuItem value={""} />
          {catOptions?.map((item, id) => (
            <MenuItem key={id} value={item.id}>
              {item.subject}
            </MenuItem>
          ))}
        </TextField>
        {location === "/admin/mock/importcsv" ? null : (
          <div>
            <TextField
              select
              label="Category"
              size="small"
              value={category}
              id="name"
              style={{ margin: ".5rem 0 1.5rem 0" }}
              fullWidth
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value={""} />
              {catVal?.map((item, id) => (
                <MenuItem key={id} value={item.id}>
                  {item.category}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Sub Category"
              type="text"
              size="small"
              value={subVal}
              id="name"
              style={{ margin: ".5rem 0 1.5rem 0" }}
              fullWidth
              onChange={(e) => setSubVal(e.target.value)}
            >
              <MenuItem value={""} />
              {subCatVal?.map((item, id) => (
                <MenuItem key={id} value={item.id}>
                  {item.subCategory}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </div> */}
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <div
        style={{ display: "flex", justifyContent: "center", color: "black" }}
      >
        <div {...getRootProps({ className: classes.dropzone })}>
          <input
            className="input-zone"
            type={"file"}
            {...getInputProps()}
            // accept={accept}
          />
          <div className="text-center">
            {isDragActive ? (
              <p className="dropzone-content">Drop here</p>
            ) : (
              <p className="dropzone-content">
                Drag and drop files , or Browse
              </p>
            )}
            <Button
              style={{
                color: "gray",
                backgroundColor: "#f3f3f3",
                outline: "none"
              }}
              onClick={open}
            >
              Click to select files
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        <aside style={{ width: "60%" }}>
          <ul>{files}</ul>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              style={{
                marginTop: "1rem",
                textTransform: "none",
                backgroundColor: "#2c3643",
                color: "#fff",
                outline: "none"
              }}
              onClick={handleCSV}
            >
              Import Data
            </Button>
          </div>
        </aside>
      </div>
      <Snackbar
        open={openSB}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}
export default Dropzone;
