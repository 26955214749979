import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
  useTheme
} from "@mui/material";
import "./blog.css";
import moment from "moment";

export const BlogDetails = ({ blogList, handleReadMore }) => {
  const [displayCount, setDisplayCount] = useState(6);
  const theme = useTheme();

  return (
    <div>
      <Grid container spacing={3}>
        {blogList.slice(0, displayCount).map((item, index) => (
          <Grid item xs={4} key={index}>
            <Card
              sx={{
                // maxWidth: 300,
                height: "100%",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#1f2129d9" : "#eef2f6",
                ":hover": {
                  boxShadow: "5px 10px 20px 1px rgb(0,0,0,0.253) !important"
                }
              }}
              style={{ cursor: "pointer" }}
              onClick={() => handleReadMore(item)}
            >
              <CardMedia
                component="img"
                title="GeeksforGeeks"
                height="150"
                image={item.titleImage}
              />
              <CardContent sx={{height: "240px"}}>
                <Typography
                  sx={{ fontSize: 11 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {item?.author},{" "}
                  {moment
                    .utc(item?.createdDate?.toString())
                    .local()
                    .startOf("seconds")
                    .format("MMMM Do YYYY")}
                </Typography>
                <Typography sx={{lineHeight: 1.15, letterSpacing: "0.02857em",}} gutterBottom variant="h6" component="div">
                  {item?.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.description?.slice(0, 150)
                    }}
                  />
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.8125rem",
                    lineHeight: 1.75,
                    letterSpacing: "0.02857em",
                    textTransform: "uppercase",
                    color: "rgb(243, 132, 36)",
                    ":hover": {
                      color: "#31a188",
                      cursor: "pointer"
                    }
                  }}
                  variant="button"
                  display="block"
                  gutterBottom
                  onClick={() => handleReadMore(item)}
                >
                  Read More
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {blogList.length > displayCount && (
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                marginTop: "1rem",
                backgroundColor: "#31a188",
                color: "#fff",
                textTransform: "none",
                outline: "none"
              }}
              onClick={() => setDisplayCount(displayCount + 3)}
            >
              Load More
            </Button>
          </div>
        </Grid>
      )}
    </div>
  );
};
