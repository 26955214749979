import {
  AccordionDetails,
  Typography,
  Snackbar,
  useTheme
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import constantValues from "../Common/ConstantValues";
import MockTest from "../student/widgets/MockTest";
import QuestionBank from "../student/widgets/QuestionBank";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledGreenButton,
  StyledRedButton
} from "../styledcomponents/styledDBwidgets";
import useRazorpay from "react-razorpay";
import MuiAlert from "@mui/material/Alert";
import { ArrowDropDown } from "@mui/icons-material";
import {
  getSubscriptionMockApi,
  getSubscriptionQbApi,
  orderIdApi,
  paymentCheckApi
} from "../../http/allApis";

import ModelFile from "../landing/ModelFile";
/// snackbar alert starts

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AccOption({
  id,
  expandId,
  title,
  text,
  callBack,
  setMockDataProp,
  setQbDataProp,
  type,
  setDummy,
  dummy,
  planValidity
}) {
  const person = JSON.parse(sessionStorage.getItem("person"));
  const theme = useTheme();
  const [expanded, setExpanded] = useState(`panel${id}`);
  const [selectedmockdrop, setSelectedmockdrop] = useState([]);
  const [selectedqbdrop, setSelectedqbdrop] = React.useState([]);
  const [qbdata, setQbdata] = useState([]);
  const [mockdata, setMockdata] = useState([]);
  const [amountqb, setAmountqb] = useState(0);
  const [selectedqb, setSelectedqb] = useState("");
  const [amountmock, setAmountmock] = useState(0);
  const [selectedmock, setSelectedmock] = useState("");
  const [totalamount, setTotalamount] = useState(0);
  const [orderid, setOrderid] = React.useState("");
  const [receipt, setReceipt] = React.useState("");
  const [paymentid, setPaymentid] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");
  const Razorpay = useRazorpay();
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    callBack(panel);
    setExpanded(isExpanded ? panel : false);
  };

  const getMockData = () => {
    getSubscriptionMockApi()
      .then((res) => {
        let mydata = res.data.result.map((item) => ({
          value: item.id + "#" + item.amount,
          label: item.name + " ₹" + item.amount
        }));
        setMockdata(mydata);
        setMockDataProp(mydata);
      })
      .catch((res) => {
        setModelWin(true);
        setCatchError(res?.response?.data?.message);
      });
  };
  // const getQbData = () => {
  //   getSubscriptionQbApi(false).then((res) => {
  //     console.log(res.data.result);
  //     let mydata = res.data.result.map((item) => ({
  //       value: item.id + "#" + item.amount,
  //       label: item.name + " ₹" + item.amount
  //     }));
  //     console.log(mydata, "Mydata");
  //     setQbDataProp(mydata);
  //     setQbdata(mydata);
  //   });
  // };
  const getQbData = () => {
    getSubscriptionQbApi(false).then((res) => {
      let mydata = res.data.result.map((item) => {
        const label =
          item.offerAmount !== undefined &&
          item.offerAmount !== null &&
          item.offerAmount !== 0
            ? `${item.name} ₹${item.offerAmount}`
            : `${item.name} ₹${item.amount}`;
        const value =
          item.offerAmount !== undefined &&
          item.offerAmount !== null &&
          item.offerAmount !== 0
            ? `${item.id}#${item.offerAmount}`
            : `${item.id}#${item.amount}`;
        return {
          value: value,
          label: label
        };
      });
      setQbDataProp(mydata);
      setQbdata(mydata);
    });
  };

  useEffect(() => {
    getMockData();
    getQbData();
  }, []);

  const handleChangeQuestion = (value) => {
    console.log(value, "Value");
    setSelectedqbdrop(value);
    let valuearray = value.toString().split("#");
    setAmountqb(parseInt(valuearray[1]));
    setSelectedqb(valuearray[0]);
    setTotalamount(parseInt(valuearray[1]) + amountmock);
  };

  const handleChangeMock = (value) => {
    setSelectedmockdrop(value);
    let checkresult = value.toString().includes(",");
    if (checkresult === true) {
      let valuearray = value.toString().split(",");
      let length = valuearray.length;
      let myselection = "";
      let myamount = 0;
      for (let i = 0; i < length; i++) {
        let finalarray = valuearray[i].split("#");
        myamount = myamount + parseInt(finalarray[1]);
        myselection = myselection + finalarray[0] + ",";
      }
      setSelectedmock(myselection.slice(0, -1));
      setAmountmock(myamount);
      setTotalamount(myamount + amountqb);
    } else {
      let checkresult1 = value.toString().includes("#");
      if (checkresult1 === true) {
        let valuearray1 = value.toString().split("#");
        setAmountmock(parseInt(valuearray1[1]));
        setSelectedmock(valuearray1[0]);
        setTotalamount(parseInt(valuearray1[1]) + amountqb);
      } else {
        setAmountmock(0);
        setSelectedmock("");
        setTotalamount(0 + amountqb);
      }
    }
  };

  const paymentcheck = (
    signature,
    orderid1,
    paymentid1,
    selectedqb,
    selectedmock,
    receipt
  ) => {
    let payload = {
      extend: false,
      amount: totalamount,
      fullName: person.fullName,
      email: person.email,
      mobile: person.mobileNumber,
      orderid: orderid1 || "",
      selectedqb: selectedqb,
      selectedmock: selectedmock,
      paymentid: paymentid1 || "",
      signature: signature || "",
      receipt: receipt
    };
    paymentCheckApi(payload)
      .then(function (response) {
        if (response.data.result === true) {
          setDummy(!dummy);
          setMessage("Resource added Successfully!");
          setAlertstatus("success");
          getMockData();
          getQbData();
          setOpen(true);
          setTotalamount(0);
          setAmountqb(0);
          setAmountmock(0);
          setMockDataProp([]);
          setQbDataProp([]);
          setSelectedmockdrop([]);
          setSelectedqbdrop([]);
          setSelectedmock("");
          setSelectedqb("");
        } else {
          setMessage("Error!");
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleReset = () => {
    setAmountmock(0);
    setSelectedmock("");
    setTotalamount(0);
    setSelectedmockdrop([]);
    setSelectedqbdrop([]);
    setSelectedqb("");
    setAmountqb(0);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handlePayment = () => {
    if (totalamount > 0) {
      let mockVal = selectedmock.split(",");
      let payloadMack = mockVal.map((item) => ({
        subId: item,
        discount: "0"
      }));
      let payload = {
        amount: totalamount * 100,
        selectedqbPay: {
          subId: selectedqb,
          discount: "0"
        },
        selectedmockPay: payloadMack
      };

      orderIdApi(payload)
        .then(function (response) {
          if (response.data.result) {
            const options = {
              key: "rzp_live_tur63s7UHuhIQr",
              // key: "rzp_test_VuryPxMzbyAwLO",
              amount: totalamount * 100,
              currency: "INR",
              order_id: response?.data?.result?.orderid,
              handler: (res) => {
                paymentcheck(
                  res.razorpay_signature,
                  response?.data?.result?.orderid,
                  res.razorpay_payment_id,
                  selectedqb,
                  selectedmock,
                  response?.data?.result?.receipt
                );
                setPaymentid(res.razorpay_payment_id);
              },
              prefill: {
                name: person.fullName,
                email: person.email,
                contact: person.mobileNumber
              },
              theme: {
                color: "#31a188",
                // Customize the Razorpay payment form with additional styles
                borderRadius: "4"
              }
            };

            const rzpay = new Razorpay(options);
            rzpay.open();
          } else {
            setMessage(response.data.error.description);
            setAlertstatus("error");
            setOpen(true);
          }
        })
        .catch((res) => {
          if (res?.response?.data?.status === 500) {
            setModelWin(true);
            setCatchError(res?.response?.data?.message);
          } else {
            console.log(res?.response?.data?.error);
          }
        });
    } else {
      alert("Please select resource");
    }
  };

  return (
    <div>
      <StyledAccordion
        expanded={expanded === expandId}
        onChange={handleChange(`panel${id}`)}
      >
        <StyledAccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          expandIcon={<ArrowDropDown style={{ color: "#31a188" }} />}
        >
          <Typography variant="p" color="#31a188" fontSize={14}>
            {title}
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          {type === "qBank" ? (
            <div>
              <QuestionBank
                qbdata={qbdata}
                handleChangeQuestion={handleChangeQuestion.bind()}
                selectedqbdrop={selectedqbdrop}
                planValidity={planValidity}
              />
              <MockTest
                selectedmockdrop={selectedmockdrop}
                handleChangeMock={handleChangeMock.bind()}
                mockdata={mockdata}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1rem",
                  flexDirection: "column"
                }}
              >
                <Typography variant="body2" color={"gray"} marginLeft={1}>
                  Total amount &#8377;{totalamount}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    marginTop: "1rem",
                    flexDirection: "row"
                  }}
                >
                  {totalamount <= 0 ? null : (
                    <StyledRedButton
                      style={{ marginTop: ".5rem", outline: "none" }}
                      onClick={handleReset}
                    >
                      Reset
                    </StyledRedButton>
                  )}

                  <StyledGreenButton
                    disabled={totalamount <= 0}
                    style={{
                      marginTop: ".5rem",
                      marginLeft: "1rem",
                      outline: "none"
                    }}
                    onClick={handlePayment}
                  >
                    Buy Now
                  </StyledGreenButton>
                </div>
              </div>
            </div>
          ) : (
            <Typography
              variant="p"
              color={theme.palette.text.secondary}
              fontSize={"13px"}
            >
              {text}
            </Typography>
          )}
        </AccordionDetails>
      </StyledAccordion>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AccOption;
