import { Typography } from "@mui/material";
import { Modal } from "antd";
import React from "react";
import { StyledButton } from "../../styledcomponents/styledQBExamWidgets";

function TimedTestModal({
  isModalOpen,
  setIsModalOpen,
  noOfQuestions,
  timeDuration,
  isSmall,
  startTimedTest,
}) {
  return (
    <Modal
      title={
        <Typography variant="h6" gutterBottom>
          Timed test
        </Typography>
      }
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <Typography variant={isSmall ? "caption" : "body2"} paragraph>
        You are about to attempt a timed test of {noOfQuestions} questions over{" "}
        {timeDuration} minutes.
      </Typography>
      <Typography variant={isSmall ? "caption" : "body2"} paragraph>
        Don't worry if you haven't time to finish the test in one sitting. You
        can save the test and come back to it at any point.
      </Typography>
      <Typography variant={isSmall ? "caption" : "body2"} paragraph>
        Please do not use the back or refresh button of the browser during the
        test.
      </Typography>
      <StyledButton
        size={isSmall ? "small" : "medium"}
        onClick={startTimedTest}
        style={{ outline: "none" }}
      >
        Click here to start the test
      </StyledButton>
    </Modal>
  );
}

export default TimedTestModal;
