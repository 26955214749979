import React from "react";
import {
  AboutBannerHead,
  AboutParaTypo,
  BlueContainerSection,
  ContentDiv,
  SectionHead,
  AboutParaTypo1
} from "../styledcomponents/beforeLoginWidgets";
import { List, ListItem, ListItemIcon, useTheme } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";

function PrivacyPolicy() {
  const theme = useTheme();
  window.scroll(0, 0);
  return (
    <div>
      <BlueContainerSection>
        <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
          Privacy Policy
        </AboutBannerHead>
      </BlueContainerSection>
      <ContentDiv>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Introduction
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              This privacy policy outlines how PGNEET© utilizes and safeguards
              the information you provide when using the website. We are
              committed to safeguarding your privacy and will never sell your
              email address or any other personal details to third parties.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Information Collection
            </SectionHead>
          </div>
          {/* <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Your privacy is of the utmost importance to us and we will never
              sell your e-mail address or any other personal details to third
              parties.
            </AboutParaTypo>
          </div> */}
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              When you make a purchase from the website we collect and store the
              following information:
            </AboutParaTypo>
          </div>
          <div>
            <List>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">Your e-mail address</AboutParaTypo1>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">Your name</AboutParaTypo1>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">Your address</AboutParaTypo1>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">
                  Your phone number (if provided)
                </AboutParaTypo1>
              </ListItem>
            </List>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo1 variant="p">
              Once you begin using the website, we also gather the following
              information:
            </AboutParaTypo1>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <List>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">Your answer history</AboutParaTypo1>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">
                  Comments made about questions
                </AboutParaTypo1>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">Notes you create</AboutParaTypo1>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">
                  Messages or feedback sent to the admin team
                </AboutParaTypo1>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "25px" }}>
                  <FiberManualRecord
                    style={{
                      color: theme.palette.text.primary,
                      fontSize: "11px"
                    }}
                  />
                </ListItemIcon>
                <AboutParaTypo1 variant="p">
                  Details of your login activity, including time/date and IP
                  address
                </AboutParaTypo1>
              </ListItem>
            </List>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="p">
            All this data is securely stored on our servers. We do not have
            access to, collect, or store credit/debit card details, as the
            payment system is provided separately by CC Avenue/Razorpay.
          </AboutParaTypo>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <SectionHead variant="h5" fontWeight={"bold"}>
            User Discussion Forum
          </SectionHead>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="p">
            Users have the option to participate in a discussion forum under
            each question. The visible screen name defaults to the first part of
            your email address but can be changed at any time, affecting the
            screen name for all past and future comments associated with your
            account.
          </AboutParaTypo>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <SectionHead variant="h5" fontWeight={"bold"}>
            Communication
          </SectionHead>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="p">
            We limit email communication but may send emails to welcome new
            subscribers, address specific site queries (including password
            reminders), and provide updates on your progress in passing the exam
            and website development.
          </AboutParaTypo>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <SectionHead variant="h5" fontWeight={"bold"}>
            Disclosure
          </SectionHead>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="p">
            If we believe your use of the site is unlawful or harmful to other
            users, we reserve the right to disclose information about you to the
            extent necessary to remedy the situation and prevent such conduct
            from continuing.
          </AboutParaTypo>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <SectionHead variant="h5" fontWeight={"bold"}>
            IP address
          </SectionHead>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="p">
            Your IP address may be stored and used for identification, gathering
            broad demographic information for statistical purposes, and
            diagnosing server problems. It may also assist in fraud detection,
            and we may share this information with law enforcement when
            necessary.
          </AboutParaTypo>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <SectionHead variant="h5" fontWeight={"bold"}>
            Erasure of Information
          </SectionHead>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="p">
            You have the right to request the erasure of stored information.
            Please email us at{" "}
            <a
              href="mailto:admin@pgneet.net"
              style={{ color: "blue", textDecoration: "none" }}
            >
              support@pgneet.net
            </a>{" "}
            to exercise this right.
          </AboutParaTypo>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <SectionHead variant="h5" fontWeight={"bold"}>
            Google Service
          </SectionHead>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="p">
            We use a Google service that places a cookie on the user's computer
            to provide marketing data.
          </AboutParaTypo>
        </div>
        <div style={{ marginTop: "1rem" }}>
          This privacy policy is subject to updates, so please check it
          periodically.
        </div>
      </ContentDiv>
    </div>
  );
}

export default PrivacyPolicy;
