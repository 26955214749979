import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "../customHooks/useFetch";
import { accOptions } from "../staticData/staticDBData";
import { AboutParaTypo } from "../styledcomponents/beforeLoginWidgets";
import AccOption from "./AccOption";
import PlanValidity from "./PlanValidity";
import {
  FlexDiv,
  PlanValidityDiv,
  StyledGreenButton,
  StyleBlueBox
} from "../styledcomponents/styledDBwidgets";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function DashboardComp() {
  const theme = useTheme();
  const [expandId, setExpandId] = useState("panel1");
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const person = JSON.parse(sessionStorage.getItem("person"));
  const [dummy, setDummy] = useState(false);
  const { loading, resData } = useFetch("/getplanvalidity", dummy);
  const [planValidity, setPlanValidity] = useState([]);
  const [qbdata, setQbdata] = useState([]);
  const [mockdata, setMockdata] = useState([]);
  const [planObject, setPlanObject] = useState(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    if (resData) {
      setPlanValidity(resData.result);
      resData?.result?.map((item) => setPlanObject(item));
    }
  }, [resData]);
  useEffect(() => {
    if (planObject !== undefined) {
      if (planObject.exam1 === "Question Bank") {
        var jsonString = JSON.stringify(planObject);
        sessionStorage.setItem("planData", jsonString);
      }
    }
  }, [planObject]);
  const handleNavigation = () => {
    navigate("/student/highyield");
  };
  return (
    <div>
      <Typography variant="h5" fontWeight={500} color="#31a188">
        My account - {person?.fullName?.toUpperCase()}
      </Typography>
      <Grid container spacing={isSmall ? 0 : 3}>
        <Grid item xs={12} md={6.5}>
          <Typography
            variant="h6"
            fontWeight={500}
            color="gray"
            marginTop={"1.5rem"}
          >
            Please select which resource you would like to use:
          </Typography>
          {planValidity && planValidity?.length !== 0 ? (
            planValidity?.map((validityItem, id) => (
              <PlanValidity
                key={id}
                validityDtl={validityItem}
                dummy={dummy}
                setDummy={(val) => setDummy(val)}
              />
            ))
          ) : (
            <div>
              <div
                style={{
                  height: "100%",
                  margin: "1rem 0",
                  backgroundColor: "#b8f1d485",
                  borderRadius: "4px",
                  padding: "2rem",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {loading ? (
                  <CircularProgress style={{ color: "#f38424" }} />
                ) : (
                  <AboutParaTypo variant="body2">
                    You don't have any resource. Please add resource to use.
                  </AboutParaTypo>
                )}
              </div>
            </div>
          )}
          {planValidity?.length === 0 ? null : (
            <StyleBlueBox style={{ marginTop: "1rem" }}>
              <Grid container style={{ height: "100%" }}>
                <Grid item xs={12} md={8}>
                  <FlexDiv>
                    <Typography
                      fontWeight={500}
                      variant={isSmall ? "p" : "h6"}
                      color={theme.palette.mode === "dark" ? "#fff" : "#5A5A5A"}
                    >
                      {"High Yield"}
                    </Typography>
                    <Typography
                      variant={isSmall ? "caption" : "p"}
                      fontSize={isSmall ? 13 : 14}
                      // color={
                      //   val
                      //     ? theme.palette.mode === "dark"
                      //       ? "#fff"
                      //       : "#5A5A5A"
                      //     : "error"
                      // }
                    ></Typography>
                  </FlexDiv>
                </Grid>
                <Grid item xs={12} md={4}>
                  <PlanValidityDiv>
                    <StyledGreenButton
                      size={isSmall ? "small" : "medium"}
                      style={{ outline: "none" }}
                      onClick={handleNavigation}
                    >
                      Use this resource <ChevronRight />
                    </StyledGreenButton>
                  </PlanValidityDiv>
                </Grid>
              </Grid>
            </StyleBlueBox>
          )}
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Typography
            variant="h6"
            fontWeight={500}
            color="gray"
            marginTop={"1.5rem"}
          >
            Account options
          </Typography>
          <div style={{ marginTop: "1rem" }}>
            {accOptions?.map((option, id) => (
              <AccOption
                title={option.title}
                planValidity={planValidity}
                callBack={(val) => setExpandId(val)}
                expandId={expandId}
                setQbDataProp={(val) => setQbdata(val)}
                setMockDataProp={(val) => setMockdata(val)}
                text={option.text}
                id={id + 1}
                type={option.type}
                dummy={dummy}
                setDummy={(val) => setDummy(val)}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardComp;
