import {
  Box,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// import { allStates } from "../../staticData/staticDBData";
import useStyles from "../../styles/FAQPageStyles";
import ProfileDetails from "./ProfileDetails";
import ConfirmPassword from "./ConfirmPassword";

import MuiAlert from "@mui/material/Alert";
import { profileCheck } from "../../landing/profileCheck";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import { exitUserApi, planvalidity } from "../../../http/allApis";
import { StyledButton } from "../../styledcomponents/styledQBExamWidgets";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function Profile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pendingPlans, setPendingPlans] = useState([]);
  const [modelWin, setModelWin] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");
  const [feedBack, setFeedBack] = useState("");
  let valCheck = 0;
  if (profileCheck() === false) {
    valCheck = 1;
  }
  const [value, setValue] = useState(valCheck);

  const handleChange1 = (event, newValue) => {
    if (profileCheck() === false) {
      alert("Update your profile details to proceed!");
    } else {
      setValue(newValue);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const snackbarcall = (message1, status1) => {
    setMessage(message1);
    setAlertstatus(status1);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const handleFeedBack = () => {
  //   let payload = {
  //     usrFeedback: feedBack
  //   };
  //   exitUserApi(payload)
  //     .then((res) => {
  //       if (res?.data?.status === "Success") {
  //         navigate("/");
  //         sessionStorage.clear();
  //       } else {
  //         setMessage(res?.data?.error?.description);
  //         setAlertstatus("warning");
  //         setOpen(true);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // const planvalidityApi = () => {
  //   planvalidity().then((res) => {
  //     if (res.data.status === "Success") {
  //       const currentDate = new Date();
  //       const filteredData = res.data.result.filter((item) => {
  //         const dateOnly = item.expiry.date.split(" ")[0];
  //         const expiryDate = new Date(dateOnly);
  //         return expiryDate >= currentDate;
  //       });

  //       setPendingPlans(filteredData);
  //       if (filteredData.length > 0) {
  //         setModelWin(true);
  //       } else {
  //         handleFeedBack();
  //       }
  //     }
  //   });
  // };

  const personValue = JSON.parse(sessionStorage.getItem("Tokentype"));
  const isInvalidValue =
    personValue === undefined ||
    personValue === null ||
    personValue === "undefined" ||
    personValue === "null";

  const personVal = isInvalidValue ? console.log("Arun") : personValue;

  return (
    <div>
      <Box sx={{ width: "100%", mt: "1rem" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange1}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "transparent",
                outline: "none"
              }
            }}
            sx={{
              overflow: "hidden",
              "& .MuiTab-root": {
                color: "#31a188",
                borderBottom: "2px solid #EBECF0",
                textTransform: "none",
                outline: "none"
              },
              "& .Mui-selected": {
                borderTop: "2px solid #EBECF0",
                borderRight: "2px solid #EBECF0",
                borderLeft: "2px solid #EBECF0",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                borderBottom: "none",
                color: theme.palette.text.primary,
                outline: "none"
              }
            }}
            textColor="#31a188"
          >
            <Tab disableRipple label="Profile" {...a11yProps(0)} />
            <Tab disableRipple label="Update Profile" {...a11yProps(1)} />

            <Tab
              disableRipple
              disabled={
                personVal?.tokenType === "Google" ||
                personVal?.tokenType === "Facebook"
                  ? true
                  : false
              }
              label="Change password"
              {...a11yProps(2)}
            />
            {/* <Tab disableRipple label="User exit" {...a11yProps(3)} /> */}
            {Array.from(new Array(20)).map((obj, id) => (
              <Tab
                disabled
                sx={{
                  textTransform: "none",
                  borderBottom: "2px solid #EBECF0"
                }}
                label="         "
                {...a11yProps(id + 5)}
              />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Paper
            style={{
              padding: "1rem"
            }}
          >
            <Box
              className={classes.faqPageMainBox}
              style={{
                margin: "2rem 0",
                border: "none"
              }}
            >
              <ProfileDetails edit={true} />
            </Box>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Paper
            style={{
              padding: "1rem"
            }}
          >
            <Box
              className={classes.faqPageMainBox}
              style={{
                border: "none"
              }}
            >
              <ProfileDetails snackbarcall={snackbarcall} edit={false} />
            </Box>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Paper
            style={{
              padding: "1rem"
            }}
          >
            <Box
              className={classes.faqPageMainBox}
              style={{
                border: "none"
              }}
            >
              <ConfirmPassword snackbarcall={snackbarcall} />
            </Box>
          </Paper>
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          <Paper
            style={{
              padding: "1rem"
            }}
          >
            <Box
              className={classes.faqPageMainBox}
              style={{
                border: "none",
                marginBottom: "1rem"
              }}
            >
              <span>
                {" "}
                If you exit this platform, Please confirm your opinion{" "}
              </span>
            </Box>
            <TextField
              fullWidth
              placeholder="Enter your Opinion"
              value={feedBack}
              onChange={(e) => setFeedBack(e.target.value)}
            />
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "end"
              }}
            >
              <StyledGreenButton onClick={planvalidityApi}>
                Confirm
              </StyledGreenButton>
            </div>
          </Paper>
        </TabPanel> */}
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {/* <Modal
        open={modelWin}
        onOk={() => setModelWin(false)}
        onCancel={() => setModelWin(false)}
        footer={null}
      >
        <div>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "bold"
            }}
          >
            {" "}
            Subscription validity details
          </span>
          {pendingPlans.map((item) => (
            <Typography
              style={{
                marginTop: "1rem"
              }}
            >
              <span style={{ fontWeight: "bold", color: "#31a188" }}>
                Subscripted Name :
              </span>{" "}
              {item?.name} --
              <span style={{ fontWeight: "bold", color: "red" }}>
                Expired Date:
              </span>{" "}
              {item?.expiry?.date.split(" ")[0]}
            </Typography>
          ))}
        </div>

        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "2rem" }}
        >
          <StyledButton onClick={handleFeedBack} style={{ outline: "none" }}>
            Ok
          </StyledButton>
        </div>
      </Modal> */}
    </div>
  );
}

export default Profile;
