import React from "react";
import { Outlet } from "react-router-dom";

function AdminMainCatPage() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default AdminMainCatPage;
