import React, { useState, useEffect } from "react";
import constantValues from "../components/Common/ConstantValues";
import Axios from "axios";

export const VeritfyComments = () => {
  const [state, setState] = useState("");

  const location = window?.location?.search;
  let authToken = location;
  let email;
  if (location != "") {
    let splitOne = authToken.split("?");
    let secondSplit = splitOne[1].split("pg-auth=");
    email = secondSplit[1].split("&pg-identity.email=");
  }

  useEffect(() => {
    if (location != "") {
      let payload = {
        "pg-auth": email[0],
        "pg-identity.email": email[1]
      };
      Axios.post(
        constantValues.PGNEET_PROD_URL + "/auth/approved-comments",
        null,
        {
          params: payload
        }
      )
        .then((res) => {
          if (res?.data?.status === "Success") {
            setState(res.data.message);
          }
        })
        .catch((err) => {
          setState("An error occurred. Please try again later.");
        });
    }
  }, []);
  return <div style={{ margin: "1rem 1rem 1rem 1rem" }}>{state}</div>;
};
