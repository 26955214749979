export const profileCheck = (val) => {
  let profDtls = sessionStorage.getItem("person");
  const profileDetails =
    profDtls != "undefined"
      ? JSON.parse(profDtls)
      : {
          fullName: "",
          mobileNumber: "",
          address1: "",
          address2: "",
          city: "",
          postalCode: "",
          state: "",
        };
  const myProfileDetails = val ? val : profileDetails;
  const checkData = {
    fullName: myProfileDetails?.fullName || "",
    mobileNumber: myProfileDetails?.mobileNumber || "",
    address1: myProfileDetails?.address1 || "",
    address2: myProfileDetails?.address2 || "",
    city: myProfileDetails?.city || "",
    postalCode: myProfileDetails?.postalCode || "",
    state: myProfileDetails?.state || "",
  };
  if (
    checkData?.fullName === "" ||
    checkData?.mobile === "" ||
    checkData?.email === "" ||
    checkData?.address1 === "" ||
    checkData?.address2 === "" ||
    checkData?.city === "" ||
    checkData?.state === ""
  ) {
    return false;
  } else {
    return true;
  }
};
