import axios from "axios";
import { useEffect, useState } from "react";
import constantValues from "../Common/ConstantValues";

function useFetch(url, val) {
  const value = sessionStorage.getItem("Tokentype");
  const tokenType = JSON.parse(value);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);

  useEffect(() => {
    if (url === "/getplanvalidity") {
      const options = {
        Authorization:
          tokenType?.tokenType + " " + sessionStorage.getItem("token")
      };
      setLoading(true);
      axios
        .get(constantValues.PGNEET_PROD_URL + url, {
          headers: options
        })
        .then((res) => {
          setResData(res.data);
        })
        .catch((res) => {
          setModelWin(true);
          setCatchError(res?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (url === "/chartsummary") {
      const options = {
        headers: {
          Authorization:
            tokenType?.tokenType + " " + sessionStorage.getItem("token")
        }
      };
      const apiUrl = constantValues.PGNEET_PROD_URL + url;
      var jsonString = sessionStorage.getItem("planData");
      var jsonData = JSON.parse(jsonString);
      const params = { planId: jsonData?.id };
      setLoading(true);
      axios
        .get(apiUrl, {
          headers: options.headers,
          params: params
        })
        .then((res) => {
          setResData(res.data);
        })
        .catch((error) => {
          setModelWin(true);
          setCatchError(error?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [val]);
  return { loading, resData, modelWin, catchError };
}

export default useFetch;
