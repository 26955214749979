import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledButton } from "../../styledcomponents/styledQBExamWidgets";
import { StyledReviewDiv } from "../../styledcomponents/styledQuizWidgets";
import ReviewQuestions from "../widgets/ReviewQuestions";
import PropTypes from "prop-types";
import { List, Checkbox } from "antd";
import styled, { css } from "styled-components";
import {
  qbResultApi,
  qbSearchCategoryResultApi,
  qbresultCategoryApi,
  qbresultDataReviewApi,
  resultDataApi,
  searchCategoryResultApi
} from "../../../http/allApis";
import ModelFile from "../../landing/ModelFile";
const CheckboxGroup = Checkbox.Group;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
const CustomCheckbox = styled(Checkbox)`
  ${(props) =>
    props.backgroundColor &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
      }
    `}
`;

function SessionReview() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(600));
  const [value, setValue] = useState(0);
  const [reviewQns, setReviewQnData] = useState([]);
  const [category, setCategory] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [maxnumber, setMaxnumber] = React.useState(0);
  const [totalscore, setTotalscore] = React.useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const [reviewdata, setReviewdata] = useState([]);
  const { state } = useLocation();
  const referid = state?.referid;
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const reviewQstnsData = state?.scoreData?.filter((item) => item?.mark < 2);
  let reviewData = reviewQstnsData?.map(JSON.stringify);
  let uniqueSet = new Set(reviewData);
  let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
  const payloadReviewData = uniqueArray?.map((item, index) => {
    return {
      startnumber: state?.reviewFor === "quiz" ? index + 1 : item?.startnumber,
      questionid: item?.questionid,
      flag: item?.flag,
      mark: item?.mark
    };
  });

  var jsonString = sessionStorage.getItem("planData");
  var jsonData = JSON.parse(jsonString);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const percentageCalc = (min, max) => {
    let finalVal = (min / max) * 100;
    return finalVal.toFixed(2).toString();
  };
  useEffect(() => {
    let data = reviewdata?.filter((obj) => obj.status === true);
    let scorePercent = percentageCalc(data?.length, reviewdata?.length);
    setMaxnumber(reviewdata?.length);
    setTotalscore(scorePercent);
  }, [reviewdata]);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < category.length);
    setCheckAll(list.length === category.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked
        ? category.map((item) => {
            return item.id;
          })
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const qbResult = () => {
    let payload = {
      reviewdata: payloadReviewData,
      planId: state?.planId
    };
    qbResultApi(payload)
      .then((res) => {
        setReviewdata(res.data.result);
        setReviewQnData(res.data.result);
      })
      .catch((res) => {
        if (res?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(res?.response?.data?.message);
        } else {
          console.log(res?.response?.data?.error);
        }
      });
  };

  const qbSearchCatResult = () => {
    let payload = {
      reviewdata: payloadReviewData,
      category: checkedList,
      planId: state?.planId
    };
    qbSearchCategoryResultApi(payload)
      .then((res) => {
        setReviewdata(res.data.result);
        setReviewQnData(res.data.result);
      })
      .catch((e) => console.log(e));
  };

  const ttResult = (val) => {
    let payload = {
      referid: referid
    };
    resultDataApi(payload, val)
      .then((res) => {
        setReviewdata(res.data.result);
        setReviewQnData(res.data.result);
      })
      .catch((e) => console.log(e));
  };

  const ttSearchCatResult = (val) => {
    if (checkedList.length !== 0) {
      let payload = {
        referid: referid,
        category: checkedList
      };
      searchCategoryResultApi(payload, val)
        .then((res) => {
          const numAscending = res?.data?.result?.sort(
            (a, b) => a.startnumber - b.startnumber
          );
          if (numAscending) {
            setReviewdata(numAscending);
            setReviewQnData(numAscending);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (state?.reviewFor === "quiz") {
      qbResult();
      qbSearchCatResult();
    } else if (state?.reviewFor === "timed-test") {
      ttResult("timed-test");
      ttSearchCatResult("timed-test");
    } else if (state?.reviewFor === "mock") {
      ttResult("mock");
      ttSearchCatResult("mock");
    } else {
      getResult();
      // qbSearchCatResult();
    }
    getResultCategory();
  }, [state?.reviewFor]);

  useEffect(() => {
    if (state?.reviewFor === "timed-test") {
      ttSearchCatResult("timed-test");
    } else if (state?.reviewFor === "mock") {
      ttSearchCatResult("mock");
    } else if (state?.reviewFor === "quiz") {
      qbSearchCatResult();
    } else {
      getSearchCategoryResult(checkedList);
    }
  }, [checkedList]);

  const getSearchCategoryResult = (category1) => {
    let myresult = reviewdata?.filter((item) =>
      category1.includes(item.category)
    );
    setReviewQnData(myresult);
  };

  useEffect(() => {
    var myitems = category.map((item) => item.id);
    setCheckedList(myitems);
  }, [category]);

  const getResult = () => {
    let payload = {
      planId: jsonData?.id
    };
    qbresultDataReviewApi(payload)
      .then((response) => {
        setReviewdata(response.data.result);
        setReviewQnData(response.data.result);
        setMaxnumber(response.data.maxnumber);
        setTotalscore(response.data.totalscore);
      })
      .catch((response) => {
        setModelWin(true);
        setCatchError(response?.response?.data?.message);
      });
  };

  const getResultCategory = () => {
    qbresultCategoryApi()
      .then(function (response) {
        let mydata = response.data.result.map((item) => ({
          id: item.id,
          category: item.mainCategory
        }));
        setCategory(mydata);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (value === 0) {
      setReviewQnData(reviewdata);
    } else if (value === 1) {
      let data = reviewdata?.filter((obj) => obj.status === true);
      setReviewQnData(data);
    } else if (value === 2) {
      let data = reviewdata?.filter((obj) => obj.status === false);
      setReviewQnData(data);
    } else if (value === 3) {
      let data = reviewdata?.filter((obj) => obj.flag === true);
      setReviewQnData(data);
    }
  }, [value]);

  const handleNewSession = () => {
    navigate("/student/qbexam", {
      state: { planId: state?.planId }
    });
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={3.5}>
          <Typography
            variant={isSmall ? "p" : "h5"}
            fontWeight={500}
            color={"#31a188"}
          >
            Session review
          </Typography>
          <StyledReviewDiv>
            <Typography variant="p" fontSize={isSmall ? 12 : 15}>
              You attempted {maxnumber} questions, scoring
              <span>
                <b> {totalscore === "NaN" ? 0 : totalscore}%</b>
              </span>
            </Typography>
          </StyledReviewDiv>
          <div style={{ margin: "1rem 0" }}>
            <Box
              style={{
                border: "1.5px solid #EBECF0",
                borderRadius: "6px",
                marginTop: ".5rem"
              }}
            >
              <div
                style={{
                  padding: ".5rem",
                  backgroundColor:
                    theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7"
                }}
              >
                <Typography variant="body2" fontWeight={600} marginLeft={1}>
                  {"Subjects:"}
                </Typography>
              </div>
              <Divider />
              <div
                style={{
                  padding: ".3rem"
                }}
              >
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    borderBottom: "none",
                    paddingTop: "3px",
                    paddingInline: "6px",
                    paddingBottom: "3px"
                  }}
                >
                  <CustomCheckbox
                    // style={{ backgroundColor: "#31a188" }}
                    backgroundColor="#31a188"
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  >
                    <Typography
                      variant="caption"
                      style={{
                        color: theme.palette.text.primary,
                        fontWeight: 600
                      }}
                    >
                      All
                    </Typography>
                  </CustomCheckbox>
                </div>
                <CheckboxGroup
                  style={{ width: "100%" }}
                  value={checkedList}
                  onChange={onChange}
                >
                  <List
                    bordered
                    style={{ width: "100%", borderRadius: "0" }}
                    dataSource={category}
                    renderItem={(item) => (
                      <List.Item
                        style={{
                          paddingTop: "3px",
                          paddingInline: "6px",
                          paddingBottom: "3px"
                        }}
                      >
                        <CustomCheckbox
                          backgroundColor="#31a188"
                          value={item.id}
                        >
                          <Typography
                            variant="caption"
                            style={{ color: theme.palette.text.primary }}
                          >
                            {item.category}{" "}
                          </Typography>
                        </CustomCheckbox>
                      </List.Item>
                    )}
                  />
                </CheckboxGroup>
              </div>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8.5}>
          <div
            style={{
              margin: ".5rem 0"
            }}
          >
            {state?.reviewFor != "mock" ? (
              <div
                style={{
                  margin: "1rem 0",
                  display: "flex",
                  justifyContent: "end"
                }}
              >
                <StyledButton
                  style={{ outline: "none" }}
                  onClick={handleNewSession}
                  disabled={
                    reviewdata === null ||
                    reviewdata === undefined ||
                    reviewdata.length === 0
                  }
                >
                  Start new session
                </StyledButton>
              </div>
            ) : null}
            <Box>
              <Tabs
                variant={isSmall ? "scrollable" : "standard"}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "transparent",
                    borderColor: "#EBECF0"
                  }
                }}
                sx={{
                  overflow: "hidden",
                  "& .MuiTab-root": {
                    color: "#31a188",
                    borderBottom: "2px solid #EBECF0",
                    textTransform: "none",
                    borderColor: "#EBECF0"
                  },
                  "& .Mui-selected": {
                    borderTop: "2px solid #EBECF0",
                    borderRight: "2px solid #EBECF0",
                    borderLeft: "2px solid #EBECF0",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    borderBottom: "none",
                    borderColor: "#EBECF0",
                    outline: "none",
                    color: theme.palette.text.primary
                  }
                }}
                textColor="#31a188"
              >
                <Tab disableRipple label="All" {...a11yProps(0)} />
                <Tab disableRipple label="Correct" {...a11yProps(1)} />
                <Tab disableRipple label="Incorrect" {...a11yProps(2)} />
                <Tab disableRipple label="Flagged" {...a11yProps(3)} />
                {isSmall
                  ? null
                  : Array.from(new Array(6)).map((obj, id) => (
                      <Tab
                        disabled
                        sx={{
                          textTransform: "none",
                          borderBottom: "2px solid #EBECF0"
                        }}
                        label="         "
                        {...a11yProps(id + 5)}
                      />
                    ))}
              </Tabs>
            </Box>
            <div style={{ marginTop: "1rem" }}>
              <ReviewQuestions
                reviewQnData={reviewQns}
                referid={referid}
                reviewFor={state?.reviewFor}
                planId={state?.planId}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default SessionReview;
