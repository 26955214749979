import { DoubleArrow } from "@mui/icons-material";
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { mockTestContent } from "../../staticData/staticDBData";
import {
  AboutParaTypo,
  TransparentHead
} from "../../styledcomponents/beforeLoginWidgets";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import PrevTimeTest from "../widgets/PrevTimeTest";
import TimedTestModal from "../widgets/TimedTestModal";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedQuestionsTTApi } from "../../../http/allApis";

function MockTestPage() {
  const noOfQuestions = 200;
  const timeDuration = 210;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const [arrayVal, setArrayVal] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleRow = (val) => {
    setArrayVal(val);
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const startTimedTest = () => {
    let payload = {
      lot: timeDuration,
      noq: noOfQuestions,
      subid: state?.mockDetails?.subid
    };
    selectedQuestionsTTApi(payload, "mock")
      .then((response) => {
        if (
          response?.data?.error?.description ===
          "You are Already Started the Exam."
        ) {
          setSuccessMsg(response?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
        } else {
          let mydata = response.data.result.map((item) => item.questionid);
          navigate("/student/mock-test", {
            state: {
              questionIds: mydata,
              referid: response.data.referid,
              scoredata: response.data.scoredata,
              timeDuration: timeDuration * 60,
              mockDetails: state?.mockDetails,
              componentIdentity: "mockquesstarted"
            }
          });
        }
      })
      .catch((response) => {
        console.log(response);
      });
  };

  return (
    <div>
      <Box
        style={{
          border: "1.5px solid  #EBECF0",
          borderRadius: "6px",
          marginTop: ".5rem"
        }}
      >
        <div
          style={{
            padding: "1rem",
            borderBottom: "1.5px solid  #EBECF0"
          }}
        >
          <TransparentHead variant="h5" fontWeight={"bold"}>
            {state?.mockDetails?.name}
          </TransparentHead>
        </div>
        <div
          style={{
            padding: "1rem"
          }}
        >
          <div>
            <List>
              {mockTestContent.map((item) => (
                <ListItem sx={{ padding: "0 0 3px 0" }}>
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    <DoubleArrow fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <AboutParaTypo variant="p">{item}</AboutParaTypo>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
          {arrayVal.length === 0 ? (
            <Box mt={2}>
              <StyledGreenButton onClick={() => setIsModalOpen(true)}>
                Start Mock Exam
              </StyledGreenButton>
            </Box>
          ) : null}
          <Box mt={4}>
            <PrevTimeTest
              type={"mock"}
              subid={state?.mockDetails?.subid}
              handleRow={handleRow}
            />
          </Box>
        </div>
      </Box>
      <TimedTestModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        noOfQuestions={noOfQuestions}
        timeDuration={timeDuration}
        isSmall={isSmall}
        startTimedTest={startTimedTest}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default MockTestPage;
