import { Paper, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SignUp from "../SignUp";
import { ContentDiv } from "../styledcomponents/beforeLoginWidgets";
import Login from "./Login";

function LoginSignUp({ reset }) {
  const { state } = useLocation();
  const theme = useTheme();
  const [togglePage, setTogglePage] = useState(state || "login");

  const handleToggle = (val) => {
    setTogglePage(val);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default
      }}
    >
      <ContentDiv>
        <div
          style={{
            margin: "2rem 0",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "2rem 1rem",
              backgroundColor: theme.palette.background.paper,
              width: "25rem"
            }}
          >
            {togglePage === "login" ? (
              <Login reset={reset} handleToggle={handleToggle.bind()} />
            ) : (
              <SignUp handleToggle={handleToggle.bind()} />
            )}
          </Paper>
        </div>
      </ContentDiv>
    </div>
  );
}

export default LoginSignUp;
