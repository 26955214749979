import { ThemeProvider, createTheme } from "@mui/material";
import { createContext, useCallback, useMemo, useState } from "react";

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const ToggleThemeProvider = ({ children }) => {
  const [mode, setMode] = useState("light");

  const toggleColorMode = useCallback(() => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: "#0C1A27",
            main: "#31a188",
            dark: "#fff",
            // contrastText: styles.d
          },
          // secondary: {
          //   light: styles.aa,
          //   main: styles.bb,
          //   dark: styles.cc,
          //   contrastText: styles.dd
          // },
          mode,
          ...(mode === "light"
            ? {
                // palette values for light mode
                // primary: amber,
                // divider: amber[200],
                background: {
                  default: "#fff",
                  paper: "#f8f8f8",
                },
                text: {
                  primary: "#000",
                  secondary: "gray",
                  appGreen: "#31a188",
                },
              }
            : {
                // palette values for dark mode
                // primary: deepOrange,
                // divider: deepOrange[700],
                background: {
                  default: "#212529",
                  paper: "#212529",
                },
                text: {
                  primary: "#fff",
                  secondary: "#fff",
                  // primary: "#000",
                  // secondary: "gray",
                  // appGreen: "#31a188",
                },
              }),
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={{ mode, toggleColorMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};
