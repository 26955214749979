import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function SubDays({ expiryDate }) {
  const { state } = useLocation();
  const [expiryDateVal, setExpiryDate] = useState(
    state || sessionStorage.getItem("ExpiryDate")
  );

  useEffect(() => {
    if (expiryDateVal) {
      sessionStorage.setItem("ExpiryDate", expiryDateVal);
    }
  }, [expiryDateVal]);

  // useEffect(() => {
  //   let expiry = moment(expiryDateVal).diff(moment(), "days");
  // }, [expiryDateVal, state]);
  return (
    <div
      style={{
        backgroundColor: "#0fd3704f",
        padding: ".7rem",
        borderRadius: "6px",
        textAlign: "center"
      }}
    >
      <Typography variant="p" fontSize={15}>
        Your subscription expires in {}
        {moment(expiryDateVal).diff(moment(), "days")} days
      </Typography>
    </div>
  );
}

export default SubDays;
