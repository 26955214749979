import {
  ArrowDropDown,
  Brightness2,
  Close,
  WbSunny
} from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import pgneet_logo from "../../assets/pgneet_logo_text.png";
import {
  StyledNBButton,
  StyledNBDiv,
  StyledNBResDiv,
  StyledNBToolBar
} from "../styledcomponents/styledNavBar";
import { ColorModeContext } from "../Common/ToggleThemeProvider";
import { profileCheck } from "../landing/profileCheck";
import { confirmDelete, deleteUseApi, userApiLogout } from "../../http/allApis";
import "./Nav.css";
import { StyledButton } from "../styledcomponents/styledQBExamWidgets";
import { Divider, Modal } from "antd";
import alert1 from "../../assets/alert1.svg";
import useFetch from "../customHooks/useFetch";
import { useEffect } from "react";
function NavBar() {
  const theme = useTheme();
  const isSmMd = useMediaQuery(theme.breakpoints.down(1200));
  const isLg = useMediaQuery(theme.breakpoints.up(1700));
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const navigate = useNavigate();
  const qbOpen = Boolean(anchorEl);
  const accOpen = Boolean(anchorE2);
  const { mode, toggleColorMode } = useContext(ColorModeContext);
  const [modelWin, setModelWin] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");
  const person = JSON.parse(sessionStorage.getItem("person"));
  const [approve, setApprove] = useState(false);
  const [dummy, setDummy] = useState(false);

  const { resData } = useFetch("/getplanvalidity", dummy);
  const [planValidity, setPlanValidity] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const hanldeLogout = () => {
    const credential = JSON.parse(sessionStorage.getItem("Tokentype"));
    userApiLogout(credential)
      .then((res) => {
        if (res.data.status === "Success") {
          navigate("/");
          sessionStorage.clear();
        } else {
          alert(() => {
            "Something went wrong please try again try";
          });
        }
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          {
            navigate("/");
            sessionStorage.clear();
          }
        }
      });
  };
  useEffect(() => {
    if (resData) {
      setPlanValidity(resData.result);
    }
  }, [resData]);
  const handleAction = (val) => {
    if (profileCheck() === false && val !== "Logout") {
      alert("Update your profile details to proceed!");
    } else {
      if (val === "Review") {
        navigate("/student/review");
      } else if (val === "Dashboard") {
        navigate("/user/dashboard");
      } else if (val === "Performance") {
        navigate("/student/performance");
      } else if (val === "ResetQb") {
        navigate("/student/resetqb");
      } else if (val === "Contact") {
        navigate("/student/contact");
      } else if (val === "Profile") {
        navigate("/user/profile");
      } else if (val === "myinvoices") {
        navigate("/student/myinvoices");
      } else if (val === "Logout") {
        setApprove(true);
        // if (approve) {
        //   setModalOpen(true);
        // }
      }
    }
    setAnchorEl(null);
    setAnchorE2(null);
  };
  const handleDelete = () => {
    setModelWin(true);
    setAnchorE2(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeletePgneet = (payload) => {
    deleteUseApi(payload)
      .then((res) => {
        if (res.data.data === true) {
          confirmDelete().then(() => {
            if (res?.data?.status === "Success") {
              navigate("/");
              sessionStorage.clear();
            } else {
              setMessage(res?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
            }
          });
        } else {
          setMessage("Check passwork", "warning");
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleNotPgneet = () => {
    confirmDelete().then((res) => {
      if (res?.data?.status === "Success") {
        navigate("/");
        sessionStorage.clear();
      } else {
        setMessage("Check passwork", "warning");
        setAlertstatus("warning");
        setOpen(true);
      }
    });
  };
  const handleUserDte = () => {
    let payload = {
      password: passwordValue
    };
    if (person?.signupType === "pgneet") {
      handleDeletePgneet(payload);
    } else {
      handleNotPgneet();
    }
  };

  return (
    <div>
      <AppBar position="sticky">
        <StyledNBToolBar
          style={{
            padding: isSmMd ? ".5rem 4%" : ".5rem 9%",
            minHeight: "auto"
          }}
        >
          <img
            src={pgneet_logo}
            width={"auto"}
            style={{ cursor: "pointer", height: "3rem" }}
            alt="pg_neet_logo"
            onClick={(e) => handleAction("Dashboard")}
          />

          <StyledNBResDiv>
            {window.location.pathname === "/user/menu" ? (
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  width: "20px",
                  height: "20px",
                  padding: 0,
                  outline: "none"
                }}
              >
                <Close />
              </IconButton>
            ) : (
              <div>
                <IconButton
                  style={{ marginLeft: ".5rem", outline: "none" }}
                  onClick={toggleColorMode}
                >
                  {mode === "dark" ? (
                    <WbSunny
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: isLg ? "1rem" : "13px"
                      }}
                    />
                  ) : (
                    <Brightness2
                      style={{
                        color: theme.palette.text.main,
                        rotate: "150deg",
                        fontSize: isLg ? "2rem" : "1rem"
                      }}
                    />
                  )}
                </IconButton>
                <IconButton
                  onClick={() => navigate("/user/menu")}
                  sx={{
                    width: "20px",
                    height: "20px",
                    padding: 0,
                    outline: "none",
                    marginLeft: "1rem"
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            )}
          </StyledNBResDiv>
          <StyledNBDiv>
            <StyledNBButton onClick={(e) => handleAction("Dashboard")}>
              Dashboard
            </StyledNBButton>
            <StyledNBButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              id="demo-positioned-button"
              aria-controls={qbOpen ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={qbOpen ? "true" : undefined}
            >
              Question bank <ArrowDropDown style={{ color: "#31a188" }} />
            </StyledNBButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              open={qbOpen}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              PaperProps={{
                style: {
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
                }
              }}
            >
              {/* {planValidity.length === 0 ? null : ( */}
              <MenuItem
                onClick={() => handleAction("Review")}
                disabled={planValidity?.length === 0}
              >
                Review
              </MenuItem>
              {/* )} */}

              <MenuItem onClick={() => handleAction("Performance")}>
                Performance
              </MenuItem>
              <MenuItem onClick={() => handleAction("ResetQb")}>
                Reset the question history
              </MenuItem>
            </Menu>
            <StyledNBButton
              onClick={(e) => setAnchorE2(e.currentTarget)}
              id="demo-positioned-button"
              aria-controls={qbOpen ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={qbOpen ? "true" : undefined}
            >
              My account <ArrowDropDown style={{ color: "#31a188" }} />
            </StyledNBButton>
            <Menu
              id="basic-menu"
              open={accOpen}
              onClose={() => setAnchorE2(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
              anchorEl={anchorE2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
            >
              <MenuItem onClick={() => handleAction("Profile")}>
                Profile
              </MenuItem>
              <MenuItem onClick={() => handleAction("myinvoices")}>
                Invoices
              </MenuItem>
              <MenuItem onClick={handleDelete}>Delete Account</MenuItem>
            </Menu>
            <StyledNBButton onClick={() => handleAction("Contact")}>
              Contact us
            </StyledNBButton>
            <StyledNBButton
              style={{ marginRight: "0" }}
              onClick={() => handleAction("Logout")}
            >
              Logout
            </StyledNBButton>
            <IconButton
              style={{ marginLeft: ".5rem", outline: "none" }}
              onClick={toggleColorMode}
            >
              {mode === "dark" ? (
                <WbSunny
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: isLg ? "2rem" : "1rem"
                  }}
                />
              ) : (
                <Brightness2
                  style={{
                    color: theme.palette.text.main,
                    rotate: "150deg",
                    fontSize: isLg ? "2rem" : "1rem"
                  }}
                />
              )}
            </IconButton>
          </StyledNBDiv>
        </StyledNBToolBar>
      </AppBar>
      {/* <div class="watermark">TEST MODE</div>{" "} */}
      <Modal
        width={600}
        open={modelWin}
        onOk={() => setModelWin(false)}
        onCancel={() => setModelWin(false)}
        footer={null}
      >
        {/* <div> */}
        <div>
          <img src={alert1} alt="Alert" width="50px" height="50px" />
          <span
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              marginTop: "1rem",
              marginLeft: "1rem"
            }}
          >
            Delete your account
          </span>
        </div>

        <Divider style={{ marginTop: "1rem", backgroundColor: "gray" }} />
        {/* </div> */}
        <div style={{ marginBottom: "1rem", fontSize: "18px" }}>
          <span style={{ fontWeight: "bold" }}> Are you sure?</span>
          &nbsp; Your profile and related account information will be deleted
          from our site
        </div>
        {person?.signupType === "pgneet" ? (
          <TextField
            size={"small"}
            placeholder="Password"
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            style={{ marginTop: "1rem" }}
          />
        ) : null}
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "1.5rem"
          }}
        >
          <StyledButton
            onClick={handleUserDte}
            style={{ outline: "none", backgroundColor: "#dc3545" }}
          >
            Confirm
          </StyledButton>
          <div
            style={{ marginTop: "1rem", marginLeft: "1rem", cursor: "pointer" }}
            onClick={() => setModelWin(false)}
          >
            <span
              style={{
                color: "#31a188",
                cursor: "pointer",
                textDecoration: "none",
                marginRight: "5rem"
              }}
            >
              {" "}
              No I`ve changed my mind
            </span>
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            Logout Alert{" "}
          </Typography>
        }
        width={370}
        open={approve}
        onOk={() => setApprove(false)}
        onCancel={() => setApprove(false)}
        footer={null}
      >
        <Typography style={{ marginBottom: "1rem" }}>
          Are you sure you wish to logout?
        </Typography>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledButton
            onClick={() => setApprove(false)}
            style={{ outline: "none" }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => hanldeLogout()}
            style={{
              outline: "none",
              marginLeft: "1rem",
              backgroundColor: "#31a188"
            }}
          >
            Logout
          </StyledButton>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default NavBar;
