import { ThumbDown, ThumbUp } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Divider,
  Typography,
  useTheme
} from "@mui/material";
import React, { useState } from "react";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import { StyledButton } from "../../styledcomponents/styledQBExamWidgets";
import {
  StyledBadge,
  StyledQuizDiv,
  StyledReferenceDiv
} from "../../styledcomponents/styledQuizWidgets";
import ResultPageEditor from "./ResultPageEditor";
import {
  addMyNotesApi,
  addThumbApi,
  getMyNotesApi,
  getThumbApi
} from "../../../http/allApis";
import { useEffect } from "react";
import ImproveEditor from "./ImproveEditor";

function QuizResult({
  quesData,
  noOfQn,
  startnumber,
  handleNext,
  snackbarcall,
  reviewFor
}) {
  const [myNotes, setMyNotes] = useState("");
  const [likeDislike, setLikeDisLike] = useState({
    likecount: 0,
    dislikecount: 0
  });
  const theme = useTheme();
  const [showImprove, setShowImprove] = useState(false);

  const submitNote = () => {
    let payload = {
      mynote: myNotes,
      questionid: quesData?.questionid
    };
    addMyNotesApi(payload)
      .then(function (response) {
        if (response.data.result === true) {
          snackbarcall("Saved Successfully!", "success");
        } else {
          snackbarcall("Error!", "warning");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addThumb = (val) => {
    let payload = {
      status: val,
      questionid: quesData?.questionid
    };
    addThumbApi(payload)
      .then((res) => {
        setLikeDisLike(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (quesData?.questionid) {
      getThumb();
      getNotes();
    }
  }, []);

  const getThumb = () => {
    let payload = {
      questionid: quesData?.questionid
    };
    getThumbApi(payload)
      .then((res) => {
        setLikeDisLike(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNotes = () => {
    let payload = {
      questionid: quesData?.questionid
    };
    getMyNotesApi(payload)
      .then((res) => {
        setMyNotes(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <StyledQuizDiv>
        {quesData?.answer?.map((ans, id) => (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "5px",
                borderLeft: `5px solid ${
                  ans?.rightanswer
                    ? "#008000"
                    : ans?.selectedanswer && ans?.rightanswer === false
                    ? "#ff0000"
                    : "#fff"
                }`,
                padding: ".5rem",
                background: ans?.rightanswer
                  ? `linear-gradient(90deg,#69C343 0%, #69C343 ${ans?.percentage}%, ${theme.palette.background.default} ${ans?.percentage}%, ${theme.palette.background.default} 100%)`
                  : ans?.selectedanswer && ans?.rightanswer === false
                  ? `linear-gradient(90deg,#ffaaaa 0%, #ffaaaa ${ans?.percentage}%, ${theme.palette.background.default} ${ans?.percentage}%, ${theme.palette.background.default} 100%)`
                  : `linear-gradient(90deg,#899499 0%, #899499 ${ans?.percentage}%, ${theme.palette.background.default} ${ans?.percentage}%, ${theme.palette.background.default} 100%)`,
                width: "100%"
              }}
            >
              <Typography variant="body2">
                <div
                  style={{
                    color: theme.palette.mode === "dark" ? "#fff" : "black"
                  }}
                  dangerouslySetInnerHTML={{ __html: ans?.answer }}
                />{" "}
              </Typography>
              <StyledBadge
                style={{
                  backgroundColor: ans?.rightanswer
                    ? "green"
                    : ans?.selectedanswer && ans?.rightanswer === false
                    ? "red"
                    : "gray"
                }}
              >
                {ans?.percentage}%
              </StyledBadge>
            </div>
            {quesData?.answer?.length === id + 1 ? null : <Divider />}
          </div>
        ))}
      </StyledQuizDiv>
      {reviewFor === "mock" ? null : (
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button style={{ color: "gray" }} onClick={() => addThumb(true)}>
            <ThumbUp fontSize="small" color="success" />
          </Button>
          <Button style={{ color: "#2e7d32", cursor: "default" }}>
            {likeDislike?.likecount}
          </Button>
          <Button onClick={() => addThumb(false)}>
            <ThumbDown fontSize="small" color="error" />
          </Button>
          <Button style={{ color: "#d32f2f", cursor: "default" }}>
            {likeDislike?.dislikecount}
          </Button>
          <Button
            style={{ textTransform: "none" }}
            onClick={() => setShowImprove(!showImprove)}
          >
            <b>Improve</b>
          </Button>
        </ButtonGroup>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "end"
        }}
      >
        <StyledButton
          style={{ outline: "none" }}
          disabled={startnumber === noOfQn}
          onClick={handleNext}
        >
          Next question
        </StyledButton>
      </div>
      {showImprove ? (
        <ImproveEditor
          questionid={quesData?.questionid}
          setShowimprove={(val) => setShowImprove(val)}
        />
      ) : null}
      {reviewFor === "mock" ? null : (
        <div>
          <Typography
            variant="body2"
            fontWeight={500}
            style={{ marginBottom: ".5rem" }}
          >
            Explanation :
          </Typography>
          <StyledReferenceDiv
            dangerouslySetInnerHTML={{ __html: quesData?.reference }}
          />
          <div style={{ margin: "1rem 0", color: "black" }}>
            <ResultPageEditor
              myNotes={myNotes}
              setMyNotes={(val) => setMyNotes(val)}
            />
          </div>
          <StyledGreenButton onClick={submitNote}>
            Save my notes
          </StyledGreenButton>
        </div>
      )}
    </div>
  );
}

export default QuizResult;
