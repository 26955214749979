import React, { useEffect } from "react";
import {
  AboutParaTypo,
  TransparentHead
} from "../../styledcomponents/beforeLoginWidgets";
import { useState } from "react";
import {
  TopicsApi,
  HyviewRefApi,
  HyViewAddApi,
  HyViewEditApi,
  HyViewDeleteApi,
  TopicsCategoryApi
} from "../../../http/allAdminApi";
import AdminTextEditor from "../widgets/AdminTextEditor";
import {
  Button,
  Menu,
  MenuItem,
  TextField,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  InputBase
} from "@mui/material";
import { Form, Switch } from "antd";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import { ArrowDropDown, Add } from "@mui/icons-material";
import { default as swal } from "sweetalert2";
import { useLocation } from "react-router-dom";
import AdminDataGrid from "../widgets/AdminDataGrid";

function AdminHYViewTopics() {
  const [checkBox, setCheckBox] = useState(true);
  const { state } = useLocation();
  const [rowsData, setRowsData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [subCatVal, setSubCatVal] = useState("");
  const [referenceText, setReferenceText] = useState("");
  const [topics, setTopics] = useState("");
  const [showPage, setShowPage] = useState("Table");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = rowsData?.filter((item) => {
      return (
        item.mainCategory.toLowerCase().includes(value.toLowerCase()) ||
        item.question.toLowerCase().includes(value.toLowerCase()) ||
        item.subCategory.toLowerCase().includes(value.toLowerCase()) ||
        item.category.toLowerCase().includes(value.toLowerCase())
      );
    });
    setResData(filteredResults);
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const deleteApi = (id) => {
    let payload = {
      flag: "Delete",
      id: id
    };
    HyViewDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleDelete = () => {
    setAnchorEl(null);
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          deleteApi(paramData?.id);
        }
      });
  };

  const handleViewMenu = (val) => {
    setAnchorEl(null);
    if (val === "Add") {
      setShowPage(val);
      TopicsCategoryApi().then((res) => {
        if (res?.data?.status === "Success") {
          setSubCatOptions(res?.data?.data);
          console.log(res?.data?.data, "res?.data?.data");
        }
      });
    } else if (val === "Edit") {
      setShowPage(val);
      HyviewRefApi(paramData?.id).then((res) => {
        if (res?.data?.status === "Success") {
          let ViewTopicData = res?.data?.data;
          setSubCatVal(ViewTopicData?.highCategoryId);
          setTopics(ViewTopicData?.topic);
          setReferenceText(ViewTopicData?.explanation);
          setCheckBox(ViewTopicData?.status);
        }
      });
    }
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setOpen(true);
      handleViewTopic();
      setAnchorEl(null);
      setShowPage("Table");
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleViewAdd = () => {
    let payload = {
      highCategoryId: subCatVal,
      topic: topics,
      status: checkBox,
      explanation: referenceText
    };
    HyViewAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleViewEdit = () => {
    let paylaod = {
      id: paramData?.id,
      highCategoryId: subCatVal,
      topic: topics,
      status: checkBox,
      explanation: referenceText
    };
    HyViewEditApi(paylaod)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleSave = () => {
    if (showPage === "Add") {
      handleViewAdd();
    } else if (showPage === "Edit") {
      handleViewEdit();
    }
  };

  const handleClose = () => {
    setShowPage("Table");
  };

  // useEffect(() => {
  //   if (showPage === "Add" || showPage === "Edit") {
  //     TopicsCategoryApi().then((res) => {
  //       let data = res?.data?.data || [];
  //       data.sort((a, b) => a.category.localeCompare(b.category));
  //       data.sort((a, b) => a.topic.localeCompare(b.topic));
  //       if (showPage === "Add" && data?.length > 0) {
  //         setSubCatVal(data[0].id);
  //         setSubCatVal(data[1].id);
  //       }
  //       setSubCatOptions(data);
  //     });
  //   }
  // }, [showPage]);

  const handleViewTopic = () => {
    setLoading(true);
    TopicsApi(state?.topicId)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res.data.data);
          setRowsData(res.data.data);
          setLoading(false);
        }
      })
      .catch((e) => setLoading(false));
  };

  useEffect(() => {
    handleViewTopic();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 60, hide: true },
    {
      field: "topic",
      headerName: "Topics",
      flex: 5,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem"
            }}
          >
            <AboutParaTypo variant="p">{params?.row?.topic}</AboutParaTypo>
          </div>
        );
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div
            style={{
              color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              padding: "3px",
              textAlign: "center",
              borderRadius: "50px",
              padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </div>
        );
      }
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      hide: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem"
            }}
          >
            <AboutParaTypo variant="p">{params?.row?.category}</AboutParaTypo>
          </div>
        );
      }
    },
    {
      field: "age",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none"
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        High Yield View Topics
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "1rem"
        }}
      >
        {showPage === "Add" || showPage === "Edit" ? (
          <div>
            <TransparentHead
              variant="h6"
              style={{ color: "#2c3643", fontWeight: "bold" }}
            >
              Category
            </TransparentHead>
            <div style={{ marginTop: "1rem" }}>
              <TextField
                select
                size="small"
                value={subCatVal}
                placeholder="Sub Category"
                id="name"
                fullWidth
                SelectProps={{
                  MenuProps: {
                    style: { maxHeight: 300 }
                  }
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    border: "1px solid #31a188",
                    padding: "0.3rem 0 0.3rem 0.2rem",
                    color: "#000"
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000"
                  },
                  "& .MuiInputLabel-formControl": {
                    top: "-8px"
                  }
                }}
                onChange={(e) => setSubCatVal(e.target.value)}
              >
                <MenuItem value={""} />
                {subCatOptions?.map((item, id) => (
                  <MenuItem key={id} value={item.id}>
                    {item.category}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <TransparentHead
              variant="h6"
              style={{
                color: "#2c3643",
                fontWeight: "bold",
                marginTop: "1rem"
              }}
            >
              Topic
            </TransparentHead>
            <div style={{ marginTop: "1rem" }}>
              <TextField
                size="small"
                value={topics}
                placeholder="Topics"
                id="name"
                fullWidth
                SelectProps={{
                  MenuProps: {
                    style: { maxHeight: 300 }
                  }
                }}
                onChange={(e) => setTopics(e.target.value)}
                sx={{
                  "& .MuiInputBase-input": {
                    border: "1px solid #31a188",
                    padding: "0.3rem 0 0.3rem 0.2rem",
                    color: "#000"
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000"
                  },
                  "& .MuiInputLabel-formControl": {
                    top: "-8px"
                  }
                }}
              ></TextField>
            </div>

            <TransparentHead
              variant="h6"
              style={{
                color: "#2c3643",
                fontWeight: "bold",
                marginTop: "1rem"
              }}
            >
              Content
            </TransparentHead>
            <div
              style={{
                lineHeight: "1em",
                color: "#000",
                margin: ".4rem 0 .5rem 0",
                padding: ".3rem 0 .3rem .2rem",
                marginTop: "1rem"
              }}
            >
              <AdminTextEditor
                editorFor={"QBReference"}
                content={referenceText}
                setContent={(val) => setReferenceText(val)}
              />
            </div>
            <div>
              <Form.Item label="Status">
                <Switch
                  checked={checkBox}
                  onChange={() => setCheckBox(!checkBox)}
                />
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  marginRight: "1rem",
                  outline: "none"
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#2c3643",
                  color: "#fff",
                  outline: "none"
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <StyledGreenButton
                onClick={() => handleViewMenu("Add")}
                style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
              >
                <Add fontSize="small" /> Add New
              </StyledGreenButton>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "1rem",
                color: "black"
              }}
            >
              <InputBase
                placeholder="Search..."
                value={searchVal}
                size="small"
                style={{
                  width: "35rem",
                  lineHeight: "1em",
                  color: "#000",
                  border: "1px solid #000",
                  margin: ".4rem 0 .5rem 0",
                  padding: ".3rem 0 .3rem .2rem"
                }}
                onChange={handleInputChange}
              />
            </div>
            <AdminDataGrid loading={loading} columns={columns} rows={resData} />
          </div>
        )}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <MenuItem onClick={() => handleViewMenu("Edit")}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

export default AdminHYViewTopics;
