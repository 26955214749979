import React from "react";
import { AboutParaTypo } from "../../styledcomponents/beforeLoginWidgets";
import { useTheme } from "@mui/material";

function PerformanceType({ perfPage, setPerfPage }) {
  const theme = useTheme();
  return (
    <div>
      <div
        style={{
          border: "1px solid #31a188",
          textAlign: "center",
          borderRadius: "4px",
        }}
      >
        <div
          onClick={() => setPerfPage("summary")}
          style={{
            cursor: "pointer",
            padding: ".5rem",
            borderBottom: "1px solid #31a188",
            backgroundColor:
              perfPage === "summary"
                ? "#31a188"
                : theme.palette.background.default,
            color: perfPage === "summary" ? "#fff" : "gray",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
          }}
        >
          <AboutParaTypo>Question Summary</AboutParaTypo>
        </div>
        <div
          onClick={() => setPerfPage("category")}
          style={{
            cursor: "pointer",
            padding: ".5rem",
            borderBottom: "1px solid #31a188",
            backgroundColor:
              perfPage === "category"
                ? "#31a188"
                : theme.palette.background.default,
            color: perfPage === "category" ? "#fff" : "gray",
          }}
        >
          <AboutParaTypo>Performance by Category</AboutParaTypo>
        </div>
        <div
          onClick={() => setPerfPage("overtime")}
          style={{
            cursor: "pointer",
            padding: ".5rem",
            backgroundColor:
              perfPage === "overtime"
                ? "#31a188"
                : theme.palette.background.default,
            color: perfPage === "overtime" ? "#fff" : "gray",
            borderRadius: perfPage === "overtime" ? "0" : "4px",
          }}
        >
          <AboutParaTypo>Performance over time</AboutParaTypo>
        </div>
      </div>
    </div>
  );
}

export default PerformanceType;
