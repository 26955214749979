import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Select } from "antd";
import React from "react";

function MockTest({ handleChangeMock, selectedmockdrop, mockdata }) {
  const theme = useTheme();
  return (
    <Box
      style={{
        border: "1.5px solid #EBECF0",
        borderRadius: "6px",
        marginTop: ".5rem",
      }}
    >
      <div
        style={{
          padding: ".5rem",
          backgroundColor:
            theme.palette.mode === "dark" ? "#2d2e34f7" : "#f1f1f1",
        }}
      >
        <Typography variant="body2" fontWeight={600} marginLeft={1}>
          Mock Test
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          padding: ".3rem",
        }}
      >
        <Select
          mode="multiple"
          size="large"
          placeholder="Please select"
          onChange={handleChangeMock}
          style={{
            width: "100%",
          }}
          value={selectedmockdrop}
          options={mockdata}
        />
      </div>
    </Box>
  );
}

export default MockTest;
