import { Box, Divider, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

function TimeDurationTextField({ callBack, noOfQuestions }) {
  const theme = useTheme();
  const [timeDuration, settimeDuration] = useState(10);
  const handleChange = (e) => {
    callBack(e.target.value);
    settimeDuration(e.target.value);
  };

  useEffect(() => {
    let duration = 0;
    if (noOfQuestions === 10) {
      duration = 10;
    } else if (noOfQuestions === 200) {
      duration = 180;
    } else if (noOfQuestions === 150) {
      duration = 120;
    } else if (noOfQuestions === 100) {
      duration = 90;
    } else if (noOfQuestions === 50) {
      duration = 45;
    } else if (noOfQuestions === 30) {
      duration = 30;
    }
    callBack(duration);
    settimeDuration(duration);
  }, [noOfQuestions]);

  return (
    <Box
      style={{
        border: "1.5px solid #EBECF0",
        borderRadius: "6px",
        marginTop: ".5rem"
      }}
    >
      <div
        style={{
          padding: ".5rem",
          backgroundColor:
            theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7"
        }}
      >
        <Typography variant="body2" fontWeight={600} marginLeft={1}>
          {"Length of test :"}
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          padding: ".3rem"
        }}
      >
        <TextField
          // type={"number"}
          size="small"
          fullWidth
          disabled
          InputProps={{ inputProps: { min: 0, max: 120 } }}
          variant="outlined"
          placeholder="eg:60"
          value={timeDuration + " " + "minutes"}
          // onKeyDown={(e) =>
          //   ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          // }
          onChange={handleChange}
        />
      </div>
    </Box>
  );
}

export default TimeDurationTextField;
