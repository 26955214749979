import React from "react";
import { TransparentHead } from "../styledcomponents/beforeLoginWidgets";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { AdminDasboardApi } from "../../http/allAdminApi";
import ModelFile from "../landing/ModelFile";
import noRecordsFound from "../../assets/norecordFound.png";
import DbIncomCard from "./widgets/DbIncomCard";
import { useLocation } from "react-router-dom";

function AdminLanding() {
  const [dashboardData, setDashboardData] = useState();
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [parsedUserData, setParsedUserData] = useState(null);
  // const { state } = useLocation();
  // console.log(state, "state");
  useEffect(() => {
    const storedUserData = sessionStorage.getItem("credential");
    const userData = JSON.parse(storedUserData);
    // if (userData === null || userData === undefined) {
    //   window.location.reload();
    // } else {
    setParsedUserData(userData);
    // }
    if (userData?.role === "Super Admin") {
      AdminDasboardApi()
        .then((res) => {
          if (res.data.status === "Success") {
            setDashboardData(res.data.data);
          }
        })
        .catch((response) => {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        });
    }
  }, []);

  return (
    <div>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        DASHBOARD
      </TransparentHead>
      <div
        style={{
          minHeight: 500,
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem"
        }}
      >
        {parsedUserData?.role === "Super Admin" ? (
          <Grid container spacing={3}>
            <>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <DbIncomCard
                  title={"Today's Income"}
                  bgColor={"#0dcaf0"}
                  type={"income"}
                  amount={dashboardData?.todayIncome || 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <DbIncomCard
                  title={"Total Income"}
                  bgColor={"#ffc107"}
                  type={"income"}
                  amount={dashboardData?.totalIncome || 0}
                />
              </Grid>
            </>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={parsedUserData?.dashboard ? 3 : 6}
              // lg={3}
            >
              <DbIncomCard
                bgColor={"#0dcaf0"}
                title={"Today's Subscribers"}
                type={"sub"}
                amount={dashboardData?.todaySubscribers || 0}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={parsedUserData?.dashboard ? 3 : 6}
              // lg={3}
            >
              <DbIncomCard
                title={"Total Subscribers"}
                bgColor={"#ffc107"}
                type={"sub"}
                amount={dashboardData?.totalSubscriber || 0}
              />
            </Grid>
          </Grid>
        ) : (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center"
            }}
          >
            <img
              src={noRecordsFound}
              alt="error"
              width={"160px"}
              height={"120px"}
            />
            <Typography variant="h4">Welcome to the dashboard</Typography>
            <Typography variant="body2">
              Oops!! There is no data to show you
            </Typography>
          </div>
        )}
      </div>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminLanding;
