import React from "react";
import {
  AboutParaTypo,
  TransparentHead
} from "../../styledcomponents/beforeLoginWidgets";
import {
  Backdrop,
  CircularProgress,
  InputBase,
  Button,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  Grid
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";

import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import { Add, ArrowDropDown } from "@mui/icons-material";
import AdminDataGrid from "../widgets/AdminDataGrid";
import {
  SettingStateApi,
  handleBlogApi,
  handleBlogHeadingApi,
  handleBlogpublish
} from "../../../http/allAdminApi";
import ModelFile from "../../landing/ModelFile";
import { Modal } from "antd";
import AdminTextEditor from "../widgets/AdminTextEditor";
import useStyles from "../../../components/admin/widgets/DropZoneStyles";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import moment from "moment";

function AdminBlogs() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalFor, setModalFor] = useState("Add");
  const [article, setArticle] = useState("");
  const [blogHeading, setBlogHeading] = useState("");
  const [articleDecs, setArticleDecs] = useState("");
  const [paramData, setParamData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [author, setAuthor] = useState(" ");
  const [files, setFiles] = useState([]);
  const [blogHeadingRes, setBlogHeadingRes] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [imageDesign, setImageDesign] = useState(false);
  const [imageValue, setImageValue] = useState("");
  const [idValue, setIdValue] = useState("");
  const [editImageBtn, setEditImageBtn] = useState(false);
  const [dateWindow, setDateWindow] = useState(false);
  const [dateValue, setDateValue] = useState({
    StartDate: moment().format("YYYY-MM-DD"),
    EndDate: moment().format("YYYY-MM-DD")
  });
  const [prIdValue, setprIdValue] = useState("");
  const handleImageDesign = () => {
    setEditImageBtn(true);
    setImageDesign(false);
  };
  const onDrop = useCallback((files) => {
    setFiles(
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "images/*"
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          width={"30%"}
          height={"30%"}
          src={file.preview}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          alt=""
        />
      </div>
    </div>
  ));

  const handleInputChange = (event) => {
    // const value = event.target.value;
    // const filteredResults = rowsData?.filter((item) => {
    //   let finalValue = value.replace(/\s/g, "").toLowerCase();
    //   let finalmainCategory = item.mainCategory
    //     .replace(/\s/g, "")
    //     .toLowerCase();
    //   let finalquestion = item.question.replace(/\s/g, "").toLowerCase();
    //   let finalsubCategory = item.subCategory.replace(/\s/g, "").toLowerCase();
    //   let finalcategory = item.subCategory.replace(/\s/g, "").toLowerCase();
    //   return (
    //     item.id.toString().includes(value) ||
    //     finalmainCategory.toLowerCase().includes(finalValue.toLowerCase()) ||
    //     finalquestion.toLowerCase().includes(finalValue.toLowerCase()) ||
    //     finalsubCategory.toLowerCase().includes(finalValue.toLowerCase()) ||
    //     finalcategory.toLowerCase().includes(finalValue.toLowerCase())
    //   );
    // });
    // setSearchVal(value);
    // setResData(filteredResults);
  };

  const handleSucces = () => {
    setOpenModal(false);
    setAnchorEl(null);
    setFiles([]);
    setArticle("");
    setArticleDecs("");
    setBlogHeading("");
    handleBlogTable();
    setImageDesign(false);
  };
  const handleBlogTable = () => {
    const uploadData = new FormData();
    uploadData.append("flag", "all");
    handleBlogApi(uploadData)
      .then((res) => {
        if (res?.data?.data) {
          setResData(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    handleBlogTable();
  }, []);

  const handleBlogHeading = () => {
    let payload = {
      flag: "all"
    };
    handleBlogHeadingApi(payload)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setBlogHeadingRes(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };

  const handleApiClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    const delBlogPayload = new FormData();
    delBlogPayload.append("flag", "delete");
    delBlogPayload.append("id", paramData?.id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      // cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        handleBlogApi(delBlogPayload)
          .then((res) => {
            if (res.data.status === "Success") {
              setSuccessMsg(res?.data?.message);
              setAlertstatus("success");
              setOpen(true);
              setLoading(false);
              handleSucces();
            } else {
              setSuccessMsg(res?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
              return Promise.reject(res);
            }
          })
          .catch((response) => {
            if (response?.response?.data?.status === 500) {
              setModelWin(true);
              setCatchError(response?.response?.data?.message);
            } else {
              setSuccessMsg(response?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
            }
          });
      }
    });
  };
  const handleBlogMenu = (condition, data1) => {
    setAnchorEl(null);
    if (condition === "Edit") {
      setModalFor("Edit");
      setOpenModal(true);
      setImageDesign(true);
      let paylaod = {
        flag: "get",
        id: data1.id
      };
      handleBlogApi(paylaod).then((res) => {
        if (res?.data?.status === "Success") {
          let data = res?.data?.data;
          setArticleDecs(data.description);
          setArticle(data.title);
          setBlogHeading(data.blogHeadingId);
          setIdValue(data?.id);
          setImageValue(data.titleImage);
          setAuthor(data.author);
          handleBlogHeading();
        }
      });
    } else if (condition === "add") {
      setModalFor("Add");
      setOpenModal(true);
      handleBlogHeading();
    }
  };

  const handleDateChange = (e) => {
    var stateCopy = { ...dateValue };
    stateCopy.StartDate = e.target.value;
    setDateValue(stateCopy);
  };
  const handleEndDateChange = (e) => {
    var stateCopy = { ...dateValue };
    stateCopy.EndDate = e.target.value;

    setDateValue(stateCopy);
  };

  const handleSave = () => {
    if (modalFor === "Edit") {
      if (editImageBtn) {
        const imageFile = files[0];
        const editBlogPayload = new FormData();
        editBlogPayload.append("titleImage", imageFile);
        editBlogPayload.append("blogHeadingId", blogHeading);
        editBlogPayload.append("title", article);
        editBlogPayload.append("description", articleDecs);
        editBlogPayload.append("flag", modalFor);
        editBlogPayload.append("id", idValue);
        editBlogPayload.append("author", author);

        handleBlogApi(editBlogPayload)
          .then((res) => {
            if (res.data.status === "Success") {
              setSuccessMsg(res?.data?.message);
              setAlertstatus("success");
              setOpen(true);
              setLoading(false);
              handleSucces();
            } else {
              setSuccessMsg(res?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
              return Promise.reject(res);
            }
          })
          .catch((response) => {
            if (response?.response?.data?.status === 500) {
              setModelWin(true);
              setCatchError(response?.response?.data?.message);
            } else {
              setSuccessMsg(response?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
            }
          });
      } else {
        const editBlogPayload = new FormData();
        editBlogPayload.append("titleImage", imageValue);
        editBlogPayload.append("blogHeadingId", blogHeading);
        editBlogPayload.append("title", article);
        editBlogPayload.append("description", articleDecs);
        editBlogPayload.append("flag", modalFor);
        editBlogPayload.append("id", idValue);
        editBlogPayload.append("author", author);

        handleBlogApi(editBlogPayload)
          .then((res) => {
            if (res.data.status === "Success") {
              setSuccessMsg(res?.data?.message);
              setAlertstatus("success");
              setOpen(true);
              setLoading(false);
              handleSucces();
            } else {
              setSuccessMsg(res?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
              return Promise.reject(res);
            }
          })
          .catch((response) => {
            if (response?.response?.data?.status === 500) {
              setModelWin(true);
              setCatchError(response?.response?.data?.message);
            } else {
              setSuccessMsg(response?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
            }
          });
      }
    } else {
      const addBlogPayload = new FormData();
      const imageFile = files[0];
      addBlogPayload.append("titleImage", imageFile);
      addBlogPayload.append("blogHeadingId", blogHeading);
      addBlogPayload.append("title", article);
      addBlogPayload.append("description", articleDecs);
      addBlogPayload.append("flag", modalFor);
      addBlogPayload.append("author", author);
      handleBlogApi(addBlogPayload)
        .then((res) => {
          if (res.data.status === "Success") {
            setSuccessMsg(res?.data?.message);
            setAlertstatus("success");
            setOpen(true);
            setLoading(false);
            handleSucces();
          } else {
            setSuccessMsg(res?.data?.error?.description);
            setAlertstatus("warning");
            setOpen(true);
            return Promise.reject(res);
          }
        })
        .catch((response) => {
          if (response?.response?.data?.status === 500) {
            setModelWin(true);
            setCatchError(response?.response?.data?.message);
          } else {
            setSuccessMsg(response.data.error.description);
            setAlertstatus("warning");
            setOpen(true);
          }
        });
      // }
    }
  };
  const handlePublish = (prId) => {
    setDateWindow(true);
    setprIdValue(prId.id);
  };
  const handlePublishApi = () => {
    handleBlogpublish(prIdValue, dateValue.StartDate, dateValue.EndDate).then(
      (res) => {
        if (res.data.status === "Success") {
          setSuccessMsg(res?.data?.message);
          setAlertstatus("success");
          setOpen(true);
          setLoading(false);
          setDateWindow(false);
          handleSucces();
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
          return Promise.reject(res);
        }
      }
    );
  };
  const columns = [
    {
      field: "Btn",
      headerName: "Approve",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              marginRight: "2rem"
            }}
          >
            <Button
              size="small"
              style={{
                backgroundColor: params?.row?.publish
                  ? "green"
                  : "rgb(243, 132, 36)",
                color: params?.row?.publish ? "white" : "#fff",
                textTransform: "none",
                outline: "none",
                fontSize: "12px"
              }}
              disabled={params?.row?.publish}
              onClick={(e) => {
                handlePublish(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              {params?.row?.publish ? "published" : "publish"}
            </Button>
          </div>
        );
      }
    },
    {
      field: "id",
      headerName: "ID",
      flex: 0.4,
      hide: true
    },
    {
      field: "blogHeading",
      headerName: "Blog Heading Name",
      flex: 2,
      hide: true
    },
    {
      field: "1",
      headerName: "Artical Image",
      flex: 2,
      renderCell: (params) => {
        return (
          <div
            style={{
              // whiteSpace: "pre-wrap",
              width: "100%",
              overflow: "auto",
              padding: "1rem 0 1rem .2rem"
            }}
          >
            <img
              src={params?.row?.titleImage}
              alt="image"
              width={"100px"}
              height={"100px"}
            />
          </div>
        );
      }
    },
    {
      field: "title",
      headerName: "Artical Title",
      flex: 2
    },
    /*{
      field: "description",
      headerName: "Artical Desc",
      flex: 5,
      renderCell: (params) => {
        return (
          <div
            style={{
              // whiteSpace: "pre-wrap",
              width: "100%",
              overflow: "auto",
              padding: "1rem 0 1rem .2rem"
            }}
          >
            <AboutParaTypo variant="p">
              <div
                style={{
                  width: "100%",
                  fontSize: "15px"
                  // marginBottom: ".2rem",
                }}
                dangerouslySetInnerHTML={{
                  __html: params?.row?.description.slice(0, 350)
                }}
              />
            </AboutParaTypo>
          </div>
        );
      }
    },*/
    {
      field: "author",
      headerName: "Author Name",
      flex: 2,
      hide: true
    },
    {
      field: "age",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none"
                // margin: ".5rem"
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      }
    }
  ];
  const handleClose = () => {
    setOpenModal(false);
    setFiles([]);
    setArticle("");
    setArticleDecs("");
    setBlogHeading("");
  };
  const currentDate = new Date();
  const minDate = currentDate.toISOString().split("T")[0];
  const isValidDateRange = () => {
    return new Date(dateValue.EndDate) > new Date(dateValue.StartDate);
  };
  return (
    <div>
      {" "}
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      {/* <div>
        <AdminBlogUpload />
      </div> */}
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Blogs
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem"
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleBlogMenu("add")}
            style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add New
          </StyledGreenButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black"
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem"
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
      <Modal
        width={1350}
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "Add" ? "Add Article" : "Edit Article"}
          </Typography>
        }
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <TextField
          select
          placeholder="Blog Heading"
          size="small"
          value={blogHeading}
          id="Blog Heading"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000"
            },
            "& .MuiInputLabel-root": {
              color: "#000"
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px"
            }
          }}
          fullWidth
          onChange={(e) => setBlogHeading(e.target.value)}
        >
          <MenuItem value={""} />
          {blogHeadingRes?.map((item, id) => (
            <MenuItem key={id} value={item.id}>
              {item.blogHeading}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          placeholder="Article Name"
          size="small"
          value={article}
          id="Article Name"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000"
            },
            "& .MuiInputLabel-root": {
              color: "#000"
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px"
            }
          }}
          fullWidth
          onChange={(e) => setArticle(e.target.value)}
        />
        <TextField
          placeholder="Author Name"
          size="small"
          value={author}
          id="Article Name"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000"
            },
            "& .MuiInputLabel-root": {
              color: "#000"
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px"
            }
          }}
          fullWidth
          onChange={(e) => setAuthor(e.target.value)}
        />

        {imageDesign ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src={imageValue} alt="image" width={300} height={200} />
            <div style={{ marginTop: "1rem" }}>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "red",
                  color: "white"
                }}
                onClick={handleImageDesign}
              >
                Edit
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              {...getRootProps()}
              style={{
                border: "2px gray dashed"
              }}
            >
              <input type="file" id="image" {...getInputProps()} />
              {isDragActive ? (
                <p style={{ color: "gray" }}>Drop your image files here</p>
              ) : (
                <p style={{ color: "gray", margin: "1rem" }}>
                  Drag and drop some Image here, or click to select Image
                </p>
              )}
            </div>
          </div>
        )}
        <div style={{ marginTop: "1rem" }}>{thumbs}</div>

        <div
          style={{
            lineHeight: "1em",
            color: "#000",
            margin: ".4rem 0 .5rem 0",
            padding: ".3rem 0 .3rem .2rem",
            marginTop: "1rem"
          }}
        >
          <AdminTextEditor
            editorFor={"ArticleDecs"}
            content={articleDecs}
            setContent={(val) => setArticleDecs(val)}
          />
        </div>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Button
            style={{
              textTransform: "none"
              //   backgroundColor:
              //     article == "" || articleDecs == "" ? "white" : "#2c3643",
              //   color: article == "" || articleDecs == "" ? "black" : "#fff"
            }}
            // disabled={articleDecs == "" && article == ""}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Modal
        width={500}
        open={dateWindow}
        onOk={() => {
          setDateWindow(false);
        }}
        onCancel={() => {
          setDateWindow(false);
        }}
        footer={null}
      >
        <div>publish From Date & To Date</div>
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextField
              id="outlined-basic"
              label="From_Date"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true, focused: "focused" }}
              value={dateValue.StartDate}
              onChange={(e) => handleDateChange(e)}
              inputProps={{ min: minDate }}
              required={true}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6}>
            <TextField
              autoOk
              label="To_Date"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true, focused: "focused" }}
              value={dateValue.EndDate}
              onChange={(e) => handleEndDateChange(e)}
              minDate={dateValue.StartDate}
              required="true"
              style={{ width: "100%" }}
              error={!isValidDateRange()}
              helperText={
                !isValidDateRange()
                  ? "End date must be equal to or after start date"
                  : ""
              }
            />
          </Grid>
        </Grid>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "2rem" }}
        >
          <Button
            size="small"
            style={{
              backgroundColor: !isValidDateRange()
                ? "gray"
                : "rgb(243, 132, 36)",
              color: !isValidDateRange() ? "black" : "#fff",
              textTransform: "none",
              outline: "none"
            }}
            disabled={!isValidDateRange()}
            onClick={handlePublishApi}
          >
            Click to Blog published
          </Button>
        </div>
      </Modal>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <MenuItem onClick={() => handleBlogMenu("Edit", paramData)}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleDelete("Delete", paramData)}>
          Delete
        </MenuItem>
      </Menu>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AdminBlogs;
