import Axios from "axios";
import constantValues from "../components/Common/ConstantValues";

const optionsEmp = {};

// Profile Details

//Profile

export const updateProfileApi = (payload, tokenTypeV) => {
  const options = {
    Authorization: tokenTypeV?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/updateprofile";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const exitUserApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/remove-usr";
  return Axios.post(URL, payload, {
    headers: options
  });
};

//Dashboard

export const getSubscriptionMockApi = (val, id) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  let payload = {
    extend: val ? true : false,
    id: id
  };
  const URL = constantValues.PGNEET_PROD_URL + "/getsubscription/mock";
  return Axios.get(URL, {
    headers: options,
    params: payload
  });
};

export const planvalidity = () => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/getplanvalidity";
  return Axios.get(URL, {
    headers: options
  });
};

export const getSubscriptionQbApi = (val, qbId) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };

  let payload = {
    extend: val,
    id: qbId
  };
  const URL = constantValues.PGNEET_PROD_URL + "/getsubscription/qb";
  return Axios.get(URL, {
    headers: options,
    params: payload
  });
};

//Highyield page

export const highyieldAllCategory = () => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/highyield/allcat";
  return Axios.post(URL, null, {
    headers: options
  });
};

export const highyieldAllTopic = () => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/highyield/alltopic";
  return Axios.post(URL, null, {
    headers: options
  });
};

//Question bank

// export const categoryCountApi = (payload) => {
//   const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
//   // let payload = {
//   //   dropvalue: 1
//   // };
//   const options = {
//     Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
//   };
//   const URL = constantValues.PGNEET_PROD_URL + "/categorycount";
//   return Axios.post(URL, {
//     headers: options,
//     params: payload
//   });
// };
export const categoryCountApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    headers: {
      Authorization:
        personVal?.tokenType + " " + sessionStorage.getItem("token")
    },
    params: payload
  };
  const URL = constantValues.PGNEET_PROD_URL + "/categorycount";
  return Axios.post(URL, null, options);
};

export const categorySubCountApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/categorysubcount";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const qbselectedquestionsApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qbselectedquestions";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const qbresultCategoryApi = () => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  let payload = {
    dropvalue: 1
  };
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qbresultcategory";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const qbresultDataReviewApi = (payload) => {
  console.log(payload, "payload");
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    headers: {
      Authorization:
        personVal?.tokenType + " " + sessionStorage.getItem("token")
    },
    params: payload
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qbresultdatareview";
  return Axios.post(URL, null, options);
};

export const addMyNotesApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/mynotes";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const addImproveApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/myimprove";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const getMyNotesApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/getnote";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const addThumbApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/addthumb";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const getThumbApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/getthumb";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const qbResultApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qbresultdata";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const qbSearchCategoryResultApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qbsearchcategoryresultdata";
  return Axios.post(URL, payload, {
    headers: options
  });
};

//Timed Test
export const getQuestionApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/getquestion"
      : type === "overall"
      ? constantValues.PGNEET_PROD_URL + "/getquestionreview"
      : constantValues.PGNEET_PROD_URL + "/getquestion";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const examApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/exam"
      : constantValues.PGNEET_PROD_URL + "/exam";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const infoDataTimedTestApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  let pyLoad = null;
  if (type === "mock") {
    pyLoad = payload;
  } else if (type === "timed-test") {
    pyLoad = payload;
  }
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/infodata"
      : constantValues.PGNEET_PROD_URL + "/qbtt/infodata";
  return Axios.post(URL, pyLoad, {
    headers: options
  });
};

export const selectedQuestionsTTApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/selectedquestions"
      : constantValues.PGNEET_PROD_URL + "/qbtt/selectedquestions";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const mockSelectedQuestionsTTApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/selectedquestionsbyid"
      : constantValues.PGNEET_PROD_URL + "/qbtt/selectedquestionsbyid";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const updateScoreDataApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/updatescoredata"
      : constantValues.PGNEET_PROD_URL + "/qbtt/updatescoredata";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const saveAndExitApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/saveandexit"
      : constantValues.PGNEET_PROD_URL + "/qbtt/saveandexit";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const addFlagApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/addflag"
      : constantValues.PGNEET_PROD_URL + "/addflag";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const testCompletedApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/testcompleted"
      : constantValues.PGNEET_PROD_URL + "/qbtt/testcompleted";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const resultDataApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/resultdata"
      : constantValues.PGNEET_PROD_URL + "/qbtt/resultdata";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const searchCategoryResultApi = (payload, type) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL =
    type === "mock"
      ? constantValues.PGNEET_PROD_URL + "/mett/searchcategoryresultdata"
      : constantValues.PGNEET_PROD_URL + "/qbtt/searchcategoryresultdata";
  return Axios.post(URL, payload, {
    headers: options
  });
};

//Performance

export const chartSummary = () => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/chartsummary";
  return Axios.get(URL, {
    headers: options
  });
};

// Login

export const updatePassApi = (val) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/update-password";
  return Axios.post(URL, val, {
    headers: options
  });
};

export const changePassApi = (val) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/change-password";
  return Axios.post(URL, val, {
    headers: optionsEmp
  });
};

export const loginApi = (payload) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/login";
  return Axios.post(URL, payload);
};

export const userApiLogout = (payload) => {
  const URL = constantValues.PGNEET_PROD_URL + "/user-logout";
  let optVal = {
    Authorization: payload?.tokenType + " " + payload?.accessToken
  };
  return Axios.post(URL, null, {
    headers: optVal
  });
};

export const ConfirmUserApi = (payload) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/confirm-userlogout";
  return Axios.post(URL, payload);
};

export const signUpApi = (payload) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/signup";
  return Axios.post(URL, payload);
};

export const pgNeetUser = (tokenValue) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  let token = sessionStorage.getItem("token");
  let tokenTypeVal =
    tokenValue?.tokenType !== undefined && tokenValue?.tokenType !== null
      ? tokenValue?.tokenType
      : personVal?.tokenType;

  const URL = constantValues.PGNEET_PROD_URL + "/getprofile";
  let optVal = {
    Authorization: tokenTypeVal + " " + token
  };
  return Axios.get(URL, {
    headers: optVal
  });
};

export const pgNeetGetFaq = () => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/common-faq";
  return Axios.get(URL);
};

export const fbGraphApi = (userId, accessToken) => {
  const URL = `https://graph.facebook.com/${userId}?fields=email,phone&access_token=${accessToken}`;
  return Axios.get(URL);
};

export const pgNeetUserAuth = (idVal) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/getprofile";
  return Axios.get(URL, {
    params: {
      id: idVal
    }
  });
};

export const forgotPwdApi = (mail) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/forgot-password";
  return Axios.post(URL, mail, {
    headers: optionsEmp
  });
};

// Reset Questions

export const resetQuestionApi = (mail) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qbreset";
  return Axios.post(URL, mail, {
    headers: options
  });
};

export const contactAuthApi = (payload) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/contact-auth";
  return Axios.post(URL, payload);
};

export const contactApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/contact";
  return Axios.post(URL, payload, {
    headers: options
  });
};

// Invoices

export const getInvoicesApi = (mail) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));

  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/allinvoices";
  return Axios.get(URL, {
    headers: options
  });
};

// Payment Check

export const orderIdApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/razor/orderid";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const paymentCheckApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/razor/paymentcheck";
  return Axios.post(URL, payload, {
    headers: options
  });
};
export const deleteUseApi = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/Verify-delete";
  return Axios.post(URL, payload, {
    headers: options
  });
};
export const confirmDelete = (payload) => {
  const personVal = JSON.parse(sessionStorage.getItem("Tokentype"));
  const options = {
    Authorization: personVal?.tokenType + " " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/user-delete";
  return Axios.post(URL, payload, {
    headers: options
  });
};

// Social login

// export const socialLoginApi = (payload) => {
//   const URL = constantValues.PGNEET_PROD_URL + "/auth/social-login";
//   return Axios.post(URL, payload);
// };

export const socialSignUpApi = (payload, apiFor) => {
  const URL =
    constantValues.PGNEET_PROD_URL +
    `/auth/${apiFor === "signup" ? "social-signup" : "social-login"}`;
  return Axios.post(URL, payload);
};

export const dashboardPricingAPi = () => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/user-offer";
  return Axios.get(URL);
};
export const dashboardinstructionAPi = () => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/user-instruction";
  return Axios.get(URL);
};

export const addBlogEmail = (emailVal) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-subscribe";
  return Axios.post(URL, null, {
    params: {
      email: emailVal
    }
  });
};

export const addBlogComment = (commentVal, id) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-comments";
  return Axios.post(URL, null, {
    params: {
      name: commentVal.name,
      email: commentVal.email,
      comments: commentVal.comment,
      blogId: id,
      website: commentVal.website
    }
  });
};
