import { ChevronRight } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FlexDiv,
  PlanValidityDiv,
  StyleBlueBox,
  StyledGreenButton,
  StyledRedButton
} from "../styledcomponents/styledDBwidgets";
import { Modal } from "antd";
import { AboutParaTypo } from "../styledcomponents/beforeLoginWidgets";
import {
  getSubscriptionMockApi,
  getSubscriptionQbApi,
  orderIdApi,
  paymentCheckApi
} from "../../http/allApis";
import useRazorpay from "react-razorpay";
import ModelFile from "../landing/ModelFile";
import alert1 from "../../assets/alert1.svg";
import { StyledButton } from "../styledcomponents/styledQBExamWidgets";

function PlanValidity({ validityDtl, setDummy, dummy }) {
  const person = JSON.parse(sessionStorage.getItem("person"));
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const [totalamount, setTotalamount] = useState(0);
  const [planId, setPlanId] = useState(0);
  const [qbdata, setQbdata] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");
  const [selectedqb, setSelectedqb] = useState("");
  const [selectedmock, setSelectedMock] = useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  let id = validityDtl?.id;
  let expiryDate = validityDtl?.expiry?.date;
  sessionStorage.setItem("ExpiryDate", validityDtl);
  let examSource = validityDtl?.exam1;
  let name = validityDtl?.name;
  let val = moment(expiryDate) > moment() ? true : false;
  const Razorpay = useRazorpay();

  const handleNavigation = (planDet) => {
    // const isValid = planValidity.map((validity) => {
    //   const expiryDate = new Date(validity?.expiry?.date);
    //   const currentDate = new Date();
    if (planDet.exam1 === "Question Bank") {
      var jsonString = JSON.stringify(planDet);
      sessionStorage.setItem("planData", jsonString);
    }

    if (examSource === "Question Bank") {
      navigate("/student/qbexam", {
        state: { expiryDate: expiryDate, PlanValidity: validityDtl }
      });
    } else {
      navigate("/student/mocktest", {
        state: {
          mockDetails: validityDtl
        }
      });
    }
  };

  const getExtendData = (data) => {
    if (examSource === "Question Bank") {
      getSubscriptionQbApi(true, data.id).then((res) => {
        // let mydata = res.data.result.map((item) => ({
        //   value: item.id + "#" + item.amount,
        //   label: item.name + " ₹" + item.amount
        // }));
        let mydata = res.data.result.map((item) => {
          const label =
            item.offerAmount !== undefined &&
            item.offerAmount !== null &&
            item.offerAmount !== 0
              ? `${item.name} ₹${item.offerAmount}`
              : `${item.name} ₹${item.amount}`;
          const value =
            item.offerAmount !== undefined &&
            item.offerAmount !== null &&
            item.offerAmount !== 0
              ? `${item.id}#${item.offerAmount}`
              : `${item.id}#${item.amount}`;
          return {
            value: value,
            label: label
          };
        });
        setQbdata(mydata);
        setModalOpen(true);
      });
    } else {
      getSubscriptionMockApi(true, data.id)
        .then((res) => {
          let mydata = res.data.result.map((item) => ({
            value: item.id + "#" + item.amount,
            label: item.name + " ₹" + item.amount
          }));
          setQbdata(mydata);
          setModalOpen(true);
        })
        .then((res) => {})
        .catch((response) => {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        });
      setModalOpen(true);
    }
  };

  useEffect(() => {
    handleAddButton();
  }, [planId, qbdata]);

  const handleAddButton = () => {
    let amount = "0";
    if (examSource === "Question Bank") {
      amount = qbdata[planId]?.value?.split("#")[1];
      setSelectedqb(qbdata[planId]?.value?.split("#")[0]);
    } else {
      amount = qbdata[planId]?.value?.split("#")[1];
      setSelectedMock(qbdata[planId]?.value?.split("#")[0]);
    }
    setTotalamount(parseInt(amount));
  };
  const paymentcheck = (signature, orderid1, paymentid1, receipt) => {
    let payload = {
      extend: true,
      amount: totalamount,
      fullName: person.fullName,
      email: person.email,
      mobile: person.mobileNumber,
      orderid: orderid1,
      selectedqb: selectedqb,
      selectedmock: selectedmock,
      paymentid: paymentid1,
      signature: signature,
      receipt: receipt
    };
    paymentCheckApi(payload)
      .then(function (response) {
        if (response.data.result === true) {
          setDummy(!dummy);
          setMessage("Resource added Successfully!");
          setAlertstatus("success");
          setOpen(true);
          setTotalamount(0);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setMessage("Error!");
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePayment = () => {
    if (totalamount > 0) {
      let mockVal = selectedmock.split(",");
      let payloadMack = mockVal.map((item) => ({
        subId: item,
        discount: "0"
      }));
      let payload = {
        amount: totalamount * 100,
        selectedqbPay: {
          subId: selectedqb,
          discount: "0"
        },
        selectedmockPay: payloadMack
      };
      // let payload = {
      //   amount: totalamount * 100,
      //   selectedqb: selectedqb,
      //   selectedmock: selectedmock,
      // };
      orderIdApi(payload)
        .then(function (response) {
          const options = {
            key: "rzp_live_tur63s7UHuhIQr",
            // key: "rzp_test_VuryPxMzbyAwLO",
            amount: totalamount * 100,
            currency: "INR",
            order_id: response.data.result.orderid,
            handler: (res) => {
              paymentcheck(
                res.razorpay_signature,
                res.razorpay_order_id,
                res.razorpay_payment_id,
                response.data.result.receipt
              );
            },
            prefill: {
              name: person.fullName,
              email: person.email,
              contact: person.mobileNumber
            },
            theme: {
              color: "#31a188",
              borderRadius: "4"
            },
            payment_capture: "1"
            // timeout: 1000,
          };
          const rzpay = new Razorpay(options);
          rzpay.open();
          setModalOpen(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Please select resource");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <StyleBlueBox key={id} id={id}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} md={8}>
            <FlexDiv>
              <Typography
                fontWeight={500}
                variant={isSmall ? "p" : "h6"}
                color={theme.palette.mode === "dark" ? "#fff" : "#5A5A5A"}
              >
                {examSource === "Question Bank" ? examSource : name}
              </Typography>
              <Typography
                variant={isSmall ? "caption" : "p"}
                fontSize={isSmall ? 13 : 14}
                color={
                  val
                    ? theme.palette.mode === "dark"
                      ? "#fff"
                      : "#5A5A5A"
                    : "error"
                }
              >
                {val
                  ? `Expires: ${moment(expiryDate).format("Do MMM YY")}`
                  : `Expired on: ${moment(expiryDate).format("Do MMM YY")}`}
              </Typography>
            </FlexDiv>
          </Grid>
          <Grid item xs={12} md={4}>
            <PlanValidityDiv>
              {val ? (
                <StyledGreenButton
                  size={isSmall ? "small" : "medium"}
                  style={{ outline: "none" }}
                  onClick={() => handleNavigation(validityDtl)}
                >
                  Use this resource <ChevronRight />
                </StyledGreenButton>
              ) : (
                <StyledRedButton
                  onClick={() => getExtendData(validityDtl)}
                  size={isSmall ? "small" : "medium"}
                >
                  Extend
                </StyledRedButton>
              )}
            </PlanValidityDiv>
          </Grid>
        </Grid>

        <Modal
          width={500}
          style={{ top: 100 }}
          open={modalOpen}
          footer={null}
          onCancel={() => setModalOpen(false)}
        >
          <div>
            <span
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                marginTop: "2rem"
              }}
            >
              {examSource === "Question Bank"
                ? "Extend Subscription"
                : "An extra 3 days will be added to the subscription period"}
            </span>
          </div>

          <Divider
            style={{
              marginTop: ".5rem",
              backgroundColor: "gray",
              marginBottom: "2rem"
            }}
          />
          <div style={{ marginBottom: "1rem", fontSize: "18px" }}>
            <span>
              {" "}
              {examSource === "Question Bank"
                ? qbdata?.map((item, id) => (
                    <div>{item.label.replace("Subscription", "Validity ")}</div>
                  ))
                : null}{" "}
            </span>
          </div>
          <div style={{ marginBottom: "1rem", fontSize: "18px" }}>
            <span> Total amount &#8377;{totalamount} </span>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem"
            }}
          >
            <StyledButton
              disabled={totalamount <= 0}
              onClick={handlePayment}
              style={{
                marginRight: "2rem",
                outline: "none",
                backgroundColor: "#31a188",
                color: "white"
              }}
            >
              Extend
            </StyledButton>
            <StyledButton
              onClick={() => setModalOpen(false)}
              style={{
                marginLeft: "2rem",
                outline: "none",
                backgroundColor: "#dc3545",
                color: "white"
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </Modal>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertstatus}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </StyleBlueBox>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default PlanValidity;
