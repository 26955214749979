import { Typography } from "@mui/material";
import React from "react";
import { StyledFooterDiv } from "../styledcomponents/styledDBwidgets";

export default function Footer() {
  return (
    <StyledFooterDiv>
      <Typography variant="caption">
        All contents of this site are &copy; 2024 PGNEET
      </Typography>
    </StyledFooterDiv>
  );
}
