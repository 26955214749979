import React, { useState, useEffect } from "react";
import { formatTime } from "../../Common/formatTime";

const Counter = ({ timeDuration, handleTestCompleted, setBalanceSeconds }) => {
  const minutes = formatTime(timeDuration).minutes;
  const seconds = formatTime(timeDuration).seconds;
  const [[m, s], setTime] = useState([parseInt(minutes), parseInt(seconds)]);
  const tick = () => {
    if (m === 0 && s === 0) {
      handleTestCompleted();
    } else if (s === 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
    setBalanceSeconds(m * 60 + s);
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  return (
    <div>
      <p>{`
      ${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`}</p>
    </div>
  );
};
export default Counter;
