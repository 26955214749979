import {
    AccordionDetails,
    Typography, useTheme,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    StyledAccordion,
    StyledAccordionSummary,
} from "../styledcomponents/styledDBwidgets";
import {ArrowDropDown} from "@mui/icons-material";
import {accOptions} from "../staticData/staticDBData";
import {AboutBannerHead, BlueContainerSection, ContentDiv, SectionHead} from "../styledcomponents/beforeLoginWidgets";
import {pgNeetGetFaq} from "../../http/allApis";

function FaqsPage() {
    const [data, setData] = useState([]);
    const [catchError, setCatchError] = useState("");
    const theme = useTheme();

    const getfaqData = () => {
        pgNeetGetFaq()
            .then((res) => {
                setData(res.data.data);
            })
            .catch((res) => {
                setCatchError(res?.response?.data?.message);
            });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        getfaqData();
    }, []);

    return (
        <div>
            <BlueContainerSection>
                <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
                    Frequently asked questions
                </AboutBannerHead>
            </BlueContainerSection>
            <ContentDiv>
                <div>
                    {data?.map((item, index) => (
                        <StyledAccordion>
                            <StyledAccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                expandIcon={<ArrowDropDown style={{ color: "#31a188" }} />}
                            >
                                <Typography variant="p" color="#31a188" fontSize={16}>
                                    {index + 1}. {item.question}
                                </Typography>
                            </StyledAccordionSummary>
                            <AccordionDetails>
                                <Typography
                                    variant="p"
                                    color={theme.palette.text.primary}
                                    fontSize={"15px"}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{ __html: item.answer }}
                                    />
                                </Typography>
                            </AccordionDetails>
                        </StyledAccordion>
                    ))}
                </div>
            </ContentDiv>
        </div>
    );
}

export default FaqsPage;
