import {
  AppBar,
  Badge,
  BadgeMark,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import pgneet_logo from "../../assets/pgneet_logo_text.png";
import {
  StyledNBDiv,
  StyledBeforeToolBar,
  StyledNBResDiv
} from "../styledcomponents/styledNavBar";
import MenuIcon from "@mui/icons-material/Menu";
import { ColorModeContext } from "../Common/ToggleThemeProvider";
import { Brightness2, WbSunny } from "@mui/icons-material";
import { dashboardinstructionAPi } from "../../http/allApis";
import { Divider, Modal } from "antd";

function NavBarBeforeLogin() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmMd = useMediaQuery(theme.breakpoints.down(1200));
  const isLg = useMediaQuery(theme.breakpoints.up(1700));
  const [navBg, setNavBg] = useState(false);
  const { mode, toggleColorMode } = useContext(ColorModeContext);
  const [instValue, setInstValue] = useState([]);
  const [modalWindow, setModalWindow] = useState(false);
  // const[routeValue,setRouteValue]=useState(false)
  const changeNavBar = () => {
    if (window.scrollY >= 6) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };
  window.addEventListener("scroll", changeNavBar);
  const handleScrollHome = () => {
    window.scrollTo(0, 0);
  };
  // window.scrollTo(0, 0);

  const handleinst = (item) => {
    return item.status;
  };
  useEffect(() => {
    dashboardinstructionAPi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          let data = res?.data?.data.filter(handleinst);
          const firstItems = data.slice(0, 1);
          setInstValue(firstItems);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const handleClose = () => {
    setModalWindow(false);
  };
  return (
    <AppBar position="sticky" elevation={navBg ? 4 : 0}>
      <StyledBeforeToolBar
        style={{ padding: isSmMd ? "0 4%" : isLg ? "5px 9%" : "0 9%" }}
      >
        <img
          src={pgneet_logo}
          width={"auto"}
          style={{ cursor: "pointer", height: isLg ? "5rem" : "3rem" }}
          alt="pg_neet_logo"
          onClick={(e) => navigate("/")}
        />
        <StyledNBResDiv>
          {window.location.pathname === "/menu" ? (
            <Typography
              variant="body2"
              onClick={() => navigate(-1)}
              color="#515151"
            >
              Close
            </Typography>
          ) : (
            <div>
              <IconButton
                style={{
                  marginLeft: ".5rem",
                  outline: "none",
                  fontSize: "5px"
                }}
                onClick={toggleColorMode}
              >
                {mode === "dark" ? (
                  <WbSunny
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: isLg ? "2rem" : "1rem"
                    }}
                  />
                ) : (
                  <Brightness2
                    style={{
                      color: theme.palette.text.main,
                      rotate: "150deg",
                      fontSize: isLg ? "2rem" : "1rem"
                    }}
                  />
                )}
              </IconButton>
              <IconButton
                sx={{
                  width: "fit-content",
                  height: "fit-content",
                  padding: "2px",
                  outline: "none",
                  marginLeft: "1rem"
                }}
                onClick={() => navigate("/menu")}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
        </StyledNBResDiv>
        <StyledNBDiv>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#f38424" : theme.palette.text.primary,
                textDecoration: "none",
                marginRight: "1rem",
                fontWeight: "600",
                fontSize: isLg ? "20px" : "15px"
              };
            }}
            to="/"
            onClick={handleScrollHome}
          >
            Home
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#f38424" : theme.palette.text.primary,
                textDecoration: "none",
                marginRight: "1rem",
                fontWeight: "600",
                fontSize: isLg ? "20px" : "15px"
              };
            }}
            to="/about"
          >
            About Us
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#f38424" : theme.palette.text.primary,
                textDecoration: "none",
                marginRight: "1rem",
                fontWeight: "600",
                fontSize: isLg ? "20px" : "15px"
              };
            }}
            to="/courses"
          >
            Courses
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#f38424" : theme.palette.text.primary,
                textDecoration: "none",
                marginRight: "1rem",
                fontWeight: "600",
                fontSize: isLg ? "20px" : "15px"
              };
            }}
            to="/faqs"
          >
            FAQs
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#f38424" : theme.palette.text.primary,
                textDecoration: "none",
                marginRight: "1rem",
                fontWeight: "600",
                fontSize: isLg ? "20px" : "15px"
              };
            }}
            to="/blog"
            // to="blog/:headingName"
          >
            Blog
          </NavLink>
          {/* {instValue.map((item) =>
            item.status && item.imageStatus ? (
              <Badge badgeContent={1} color="primary">
                <NavLink
                  style={({ isActive }) => {
                    return {
                      color: theme.palette.text.primary,
                      textDecoration: "none",
                      fontWeight: "600",
                      fontSize: isLg ? "20px" : "15px"
                    };
                  }}
                  onClick={() => setModalWindow(!modalWindow)}
                >
                  Announcement
                </NavLink>
              </Badge>
            ) : null
          )} */}
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#f38424" : theme.palette.text.primary,
                textDecoration: "none",
                marginRight: "1rem",
                fontWeight: "600",
                fontSize: isLg ? "20px" : "15px"
                // marginLeft: ".8rem"
              };
            }}
            to="/contact"
          >
            Contact Us
          </NavLink>
          <NavLink
            style={({ isActive }) => {
              return {
                color: isActive ? "#f38424" : theme.palette.text.primary,
                textDecoration: "none",
                fontWeight: "600",
                fontSize: isLg ? "20px" : "15px"
              };
            }}
            to="/login"
          >
            Login/Sign Up
          </NavLink>
          <IconButton
            onClick={toggleColorMode}
            style={{ marginLeft: ".5rem", outline: "none" }}
          >
            {mode === "dark" ? (
              <WbSunny
                style={{
                  color: theme.palette.text.primary,
                  fontSize: isLg ? "1rem" : "13px"
                }}
              />
            ) : (
              <Brightness2
                style={{
                  color: theme.palette.text.primary,
                  rotate: "150deg",
                  fontSize: isLg ? "2rem" : "1rem"
                }}
              />
            )}
          </IconButton>
        </StyledNBDiv>
      </StyledBeforeToolBar>
      {/* {instValue.map((item) =>
        item.imageStatus ? (
          <Modal
            // style={{  }}
            width={600}
            height={700}
            // title={
            //   <Typography variant="h6" gutterBottom>
            //     Add Exam{" "}
            //   </Typography>
            // }
            open={modalWindow}
            onOk={handleClose}
            onCancel={handleClose}
            footer={null}
          >
            <div
              style={{
                margin: "1rem 0",
                backgroundColor: "#fff",
                padding: "2rem 1rem"
              }}
            >
              <img
                src={item?.instImage}
                alt="image"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Modal>
        ) : null
      )} */}
    </AppBar>
  );
}

export default NavBarBeforeLogin;
