import React from "react";
import {
  AboutBannerHead,
  AboutParaTypo,
  BlueContainerSection,
  ContentDiv,
  SectionHead
} from "../styledcomponents/beforeLoginWidgets";

function Terms() {
  window.scroll(0, 0);
  return (
    <div>
      <BlueContainerSection>
        <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
          Terms and conditions
        </AboutBannerHead>
      </BlueContainerSection>
      <ContentDiv>
        <div>
          {/* <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Introduction
            </SectionHead>
          </div> */}
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Greetings and welcome to PGNEET© - An online revision tool for
              doctors, medical students, and other health professionals
              preparing for medical postgraduate entrance exams.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Prior to using this website, it is essential to acknowledge and
              accept our Terms and Conditions for use. We kindly request that
              you thoroughly review and understand these terms before
              proceeding.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Terms and Conditions may change over time; those applicable to
              subscribers are those which are current and agreed to when a user
              subscribes.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              The utilization of PGNEET© services is contingent upon compliance
              with these specified terms and conditions. If you do not consent
              to abide by these Terms and Conditions, we kindly ask that you
              refrain from using this website. For any inquiries or
              clarifications regarding the Terms and Conditions, please feel
              free to contact us at
              <a
                href="mailto:admin@pgneet.net"
                style={{ color: "blue", textDecoration: "none" }}
              >
                {" "}
                support@pgneet.net.
              </a>
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Please be aware that while our terms and conditions may be subject
              to modification, the prevailing terms applicable to subscribers
              are those in effect at the time of subscription and acceptance.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            The website is only available in English, as is the subscription
            process.
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Registration/Subscription
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              All registrations must be completed online; if the correct
              registration process is not followed, we will not be able to
              handle any payments, schedule any courses, or grant access to any
              online resources. Please visit the registration page in order to
              register.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              The website lists the cost of the PGNEET© online revision course
              and mock exams. Payments must be made by subscribers in Indian
              Rupees. The exchange rate for your transaction will be determined
              by your card provider if you are making a payment from an account
              that isn't in Indian Rupees.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              After payment is received, accounts will be activated within 24
              hours. As soon as the purchase is finalised, the expiration date
              is established. If there are any delays in activation, please
              email .
              <a
                href="mailto:admin@pgneet.net"
                style={{ color: "blue", textDecoration: "none" }}
              >
                support@pgneet.net.
              </a>
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              By signing this agreement, you acknowledge that your subscription
              fees cannot be applied to another diet plan or course. The
              reservation would have to be cancelled, and a new one would have
              to be made. We ask that you do not email us requests for
              transfers.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              You hereby agree that there will be no refunds given at any point
              after the purchase due to the nature of the service provided by
              PGNEET©, as access is granted immediately upon purchase (there is
              no "cooling off" period).
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Cancellation of Subscription (user ID) is the subject of written
              requests for course cancellations sent to{" "}
              <a
                href="mailto:admin@pgneet.net"
                style={{ color: "blue", textDecoration: "none" }}
              >
                support@pgneet.net.
              </a>
              . Your subscription will be cancelled at the time and date
              specified in this email, which also happens to be the cancellation
              window.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              You guarantee to us that all the information you provide to us on
              the website is accurate, especially the fact that the credit or
              debit card you are using is your own and that you have enough
              money on hand to pay for the service.
            </AboutParaTypo>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Usage of Website
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              When you use our website, whether as a registered or
              non-registered user, you are confirming your acceptance of these
              Terms and Conditions and your commitment to adhere to them.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              User accounts are intended solely for personal, non-commercial
              home use by the subscriber. It is imperative that passwords and
              other login details remain confidential and not be shared. Failure
              to comply may result in actions taken, including immediate
              subscription termination.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Any misuse of the service for purposes beyond personal, individual
              revision, such as copying questions, answers, or notes, or
              attempting to compromise the security or functionality of PGNEET©,
              will lead to account termination and potential legal consequences,
              including criminal prosecution.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              While we strive to maintain uninterrupted website availability,
              occasional access restrictions for site maintenance or upgrades
              may occur. Technical issues may also disrupt access. Although we
              make every effort to resolve these promptly, we cannot assume
              responsibility for any resulting loss or damages.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Our website is optimized for use on PCs running Windows,
              Macintosh, Linux, Android, and iOS. Using other devices may result
              in limited functionality.
            </AboutParaTypo>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              External links
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              For the convenience of our subscribers, the PGNEET© website may
              contain links to external sites. Please note that including a link
              does not imply endorsement or approval of the linked site or its
              contents, as it operates independently of our website and beyond
              our control. Consequently, we cannot assume responsibility for the
              content or any resulting loss or damages from using these external
              sites.
            </AboutParaTypo>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              User data Deletion Request
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              {/* Users have the rights to exit with deletion of their profile or
              personal data stored within our system. To initiate a data
              deletion request, users must follow the procedure outlined on our
              platform, which we provided the exit options in the profile screen
              (My Account - &gt; Profile) with giving reasons (note: reason text
              is optional). If you user data deletion not happen, kindly reach
              out to this (admin@pgneet.net) mail. */}
              {/* Users have the right to request the deletion of their profile and
              personal data stored within our system. To initiate a data
              deletion request, please follow the outlined procedure on our
              platform, accessible through the profile screen (My Account - &gt;
              Delete Account). If your data deletion request is not processed,
              kindly contact us at support@pgneet.net. */}
              Users have the right to request the deletion of their profile and
              personal data stored within our system. To initiate a data
              deletion request, please follow the outlined procedure on our
              platform, accessible through the profile screen (My Account - &gt;
              Delete Account). If your data deletion request is not processed,
              kindly contact us at{" "}
              <a
                href="mailto:admin@pgneet.net"
                style={{ color: "blue", textDecoration: "none" }}
              >
                support@pgneet.net.
              </a>
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              All rights not explicitly granted in these Terms and Conditions or
              in any written license are reserved.
            </AboutParaTypo>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Limitation of liability
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              This website serves as a resource to facilitate revision and does
              not make any express or implied representations regarding the
              accuracy of drug dosages. Users must independently verify drug
              dosages through recognized formularies such as the National
              Formulary of India (NFI) before prescribing.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              While we take reasonable care to ensure the accuracy of
              information on our site, the material and information provided on
              our website come without any guarantees, conditions, or warranties
              regarding correctness, accuracy, reliability, usefulness, or other
              aspects of the information. Medical knowledge evolves, and to the
              fullest extent permitted by law, PGNEET© disclaims any
              responsibility or legal liability for errors or the misuse of
              material on the website.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              PGNEET© is not liable for any loss, damage, costs, expenses, or
              claims of any kind arising out of or related to the revision
              questions and other information available on this website, except
              as expressly provided in these conditions.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              PGNEET© is not liable for delays or failures in performing its
              obligations related to the website if caused by factors beyond its
              reasonable control.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              In no event shall PGNEET©'s total liability to any user for all
              damages, losses, and other claims (whether contractual or not)
              exceed the amount paid to PGNEET© for using this site. The maximum
              liability for any user's claim shall not exceed the current paid
              subscription fee.
            </AboutParaTypo>
          </div>
          {/* <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              In no event shall PGNEET.NET total liability to any user for all
              damages, losses, and other causes of action (whether in contract
              or not) exceed the amount paid to PGNEET.NET for use of this site.
              The maximum liability for any claim from a user shall not exceed
              the current subscription fee which have been paid.
            </AboutParaTypo>
          </div> */}
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Privacy
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              All personal data collected by PGNEET© is governed by our privacy
              policy. By using this site, you consent to the use of personal
              data in accordance with the privacy policy.
            </AboutParaTypo>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Law governing Terms and Conditions
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              These Terms and Conditions are exclusively governed by Indian
              laws. However, we reserve the right to take legal action for
              breaches of these Terms and Conditions in any relevant country.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              These terms and conditions do not affect your statutory rights.
            </AboutParaTypo>
          </div>
        </div>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Copyright
            </SectionHead>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              The PGNEET© site is copyright protected and it's content, design,
              layout, databases or graphics shall not be reproduced, stored in
              any form or by any means including but not limited to electronic,
              paper, mechanical, photocopying, recording, or broadcasting, nor
              shall the site be shown in a public gathering. You may only use
              the material available for your own personal non-commercial home
              use provided that it is not changed and copyright notices remain;
              PGNEET© retains the intellectual property rights on all material
              from their website. You shall not create any derivative work or
              make any other adaptation, without our prior written consent.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              If you use or attempt to use the service for purposes other than
              personal individual revision, including but not limited to copying
              questions, copying answers, copying notes, tampering, hacking,
              modifying or otherwise corrupting the security or functionality of
              the service provided by PGNEET©, your account will be terminated
              and you will be subject to damages and other penalties, including
              criminal prosecution where available.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              All rights not explicitly granted in these Terms and Conditions or
              in any written licence remain reserved.
            </AboutParaTypo>
          </div>
        </div>
      </ContentDiv>
    </div>
  );
}

export default Terms;
