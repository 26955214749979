import {
  Accordion,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
  styled
} from "@mui/material";

export const StyledGreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#31a188",
  color: "#fff",
  textTransform: "none",
  fontWeight: 300,
  lineHeight: "1.2rem",
  outline: "none",
  // height: "fit-content",
  // margin: ".5rem 0 1rem 0",
  "&:hover": {
    backgroundColor: "#f38424",
    color: "#fff"
  },
  ":disabled": {
    backgroundColor: theme.palette.mode === "dark" ? "#1f2129d9" : "#f1f1f1",
    color: "gray"
  },
  [theme.breakpoints.between(600, 680)]: {
    fontSize: "11px"
  },
  [theme.breakpoints.between(680, 900)]: {
    fontSize: "small"
  }
}));

export const PlanValidityDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  height: "100%",
  alignItems: "center",
  [theme.breakpoints.between(900, 1024)]: {
    alignItems: "center"
  },
  [theme.breakpoints.down(900)]: {
    height: "auto"
  }
}));

export const StyledFooterDiv = styled("div")({
  bottom: 0,
  // margin: "2rem 0",
  backgroundColor: "#f1f1f1",
  padding: "1rem",
  display: "flex",
  justifyContent: "center",
  color: "gray"
});

export const StyledBeforeFooterDiv = styled("div")(({ theme }) => ({
  bottom: 0,
  borderTop: "5px solid gray",
  backgroundColor: "#333",
  // padding: "1rem",
  display: "flex",
  justifyContent: "space-between",
  color: "#fff",
  padding: "1rem 9%",
  [theme.breakpoints.down(1200)]: {
    padding: "1rem 4%"
  },
  alignItems: "center"
}));

export const StyledFooterIconButton = styled(IconButton)(({ theme }) => ({
  width: "2.1rem",
  height: "2rem",
  // border: "1px solid #777",
  border: "2px solid #ECECEC",

  margin: "0 .25rem",
  color: "#fff",
  outline: "none",
  "&:hover": {
    backgroundColor: "#31a188"
  },
  [theme.breakpoints.down(800)]: {
    width: "2rem",
    height: "2rem"
  }
}));

export const StyledFooterText = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  color: "#fff",
  "&:hover": {
    color: "#f38424"
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "11px"
  }
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#212529" : "#fff",
  border: "1px solid #f1f1f1",
  borderRadius: "4"
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#2d2e34f7" : "#f7f7f7",
  border: "1px solid #f1f1f1"
}));

export const StyleBlueBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#2d2e34f7" : "#0fd3704f",
  padding: ".5rem 1rem",
  marginTop: "1rem",
  borderRadius: "4px",
  border: "1px solid #b8f1d485",
  height: "7rem",
  [theme.breakpoints.down(900)]: {
    height: "auto"
  }
}));

export const StyledRedButton = styled(Button)({
  backgroundColor: "#dc3545",
  color: "#fff",
  textTransform: "none",
  fontWeight: 300,
  outline: "none",
  // margin: ".5rem 0 1rem 0",
  "&:hover": {
    backgroundColor: "#dc3545",
    color: "#fff"
  }
});

export const FlexDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%"
}));

//Invoices

export const NormalDiv = styled("div")(({ theme }) => ({
  display: "block",
  [theme.breakpoints.down(600)]: {
    display: "none"
  }
}));

export const ResDiv = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down(600)]: {
    display: "block"
  }
}));
