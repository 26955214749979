import React from "react";
import {
  StyledQuizBannerDiv,
  StyledQuizIconButton
} from "../../styledcomponents/styledQuizWidgets";
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  Flag,
  FlagOutlined
} from "@mui/icons-material";
import {
  Alert,
  Checkbox,
  Divider,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import QuizResult from "./QuizResult";
import QuizQuestion from "./QuizQuestion";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  addFlagApi,
  examApi,
  getQuestionApi,
  testCompletedApi,
  updateScoreDataApi
} from "../../../http/allApis";
import { useEffect } from "react";
import ModelFile from "../../landing/ModelFile";

function QuestionPage({
  type,
  mydropvalue,
  questiondata,
  qdStartNumber,
  scoredata,
  setScoredata,
  setTotalCourse,
  referid,
  pageFor,
  reviewFor,
  notAttendQues,
  mockSelectQues,
  flagVal,
  planId,
  scoreClick
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [quesData, setQuesData] = useState();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [startnumber, setStartNumber] = useState(1);
  const [qdStartNumberVal, setQdStartNumberVal] = useState(qdStartNumber[0]);
  const [maxnumber, setMaxnumber] = useState(0);
  const [flagstatus, setFlagstatus] = useState(false);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const { state } = useLocation();
  const ttBoolean = sessionStorage.getItem("TimedTest");

  useEffect(() => {
    const fetchData = async () => {
      if (reviewFor) {
        if (reviewFor === "overall") {
          let data = state?.questionIds;
          let index = state?.scoredata.findIndex(
            (item) => item?.startnumber === state?.startnumber
          );
          setStartNumber(index + 1);
          setQdStartNumberVal(data[index]);
        } else if (scoreClick !== "scoreClick") {
          if (
            state?.componentIdentity &&
            state?.componentIdentity === "quizReview"
          ) {
            let data = [];
            state?.scoredata.map((item) => {
              data.push(item.startnumber);
            });
            let index = state?.scoredata.findIndex(
              (item) => item?.startnumber === state?.startnumber
            );
            setStartNumber(index + 1);
            setQdStartNumberVal(data[index]);
          } else if (reviewFor === "timed-test") {
            let data = state?.questionIds;
            let index = state?.scoredata.findIndex(
              (item) => item?.startnumber === state?.startnumber
            );
            setStartNumber(index + 1);
            setQdStartNumberVal(data[index]);
          } else if (reviewFor === "mock") {
            let data = state?.questionIds;
            let index = state?.scoredata.findIndex(
              (item) => item?.startnumber === state?.startnumber
            );
            setStartNumber(index + 1);
            setQdStartNumberVal(data[index]);
          }
        }
      }
    };
    fetchData();
  }, [reviewFor]);

  const getQuestion = (value) => {
    if (questiondata?.length > 0) {
      let payload;
      if (
        reviewFor === "timed-test" ||
        type === "timed-test" ||
        reviewFor === "mock" ||
        type === "mock"
      ) {
        payload = {
          startnumber:
            value != undefined && value != null ? value : startnumber,
          questiondata: questiondata,
          referid: referid,
          planId: planId,
          timedTestReview: ttBoolean === "Yes" ? true : false
        };
      } else if (state?.reviewFor === "overall" || type === "overall") {
        payload = {
          questionid: qdStartNumberVal,
          planId: planId
        };
      } else if (type === "quiz") {
        let quesD = scoredata.filter((obj) => obj.startnumber === startnumber);
        payload = {
          planId: planId,
          startnumber: qdStartNumberVal,
          questiondata: questiondata,
          dropvalue: mydropvalue,
          attended:
            mydropvalue === 2
              ? quesD[0]?.mark != undefined &&
                quesD[0]?.mark != null &&
                quesD[0]?.mark != 2
                ? true
                : false
              : false,
          flagged:
            mydropvalue === 2
              ? quesD[0]?.flag != undefined &&
                quesD[0]?.flag != null &&
                quesD[0]?.flag
              : false
        };
      } else {
        payload = {
          startnumber: startnumber,
          questiondata: questiondata,
          referid: referid
        };
      }
      let qstnType = reviewFor || type;
      getQuestionApi(payload, qstnType)
        .then(function (response) {
          setQuesData(response?.data);
          setFlagstatus(response?.data?.flagstatus);
          if (pageFor != "review") {
            let selAns = response?.data?.answer?.findIndex(
              (obj) => obj.selectedanswer === true
            );
            var editedscore = {
              startnumber: startnumber,
              questionid: response?.data?.questionid,
              flag: response?.data?.flagstatus,
              mark: type === "quiz" ? 2 : selAns === -1 ? 5 : selAns + 1
            };
            let mydata1 = scoredata.some(
              (u) => u?.startnumber === editedscore?.startnumber
            );
            if (!mydata1) {
              setScoredata((prevScoredata) => [...prevScoredata, editedscore]);
            }
          }
        })
        .catch((res) => {
          if (res?.response?.data?.status === 500) {
            setModelWin(true);
            setCatchError(res?.response?.data?.message);
          } else {
            console.log(res?.response?.data?.error);
          }
        });
    }
  };

  useEffect(() => {
    let markScore = scoredata.filter((obj) => obj.mark === 1);
    let finalScore = Math.round((markScore.length / questiondata.length) * 100);
    setTotalCourse(finalScore);
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (notAttendQues != undefined || mockSelectQues !== undefined) {
      if (state.componentIdentity === "startQuiz") {
        setStartNumber(notAttendQues);
        setQdStartNumberVal(qdStartNumber[notAttendQues]);
      } else if (state?.componentIdentity === "quizReview") {
        let data = [];
        state?.scoredata.map((item) => {
          data.push(item.startnumber);
        });
        let index = state?.scoredata.findIndex(
          (item) => item?.startnumber === notAttendQues
        );
        setStartNumber(index + 1);
        setQdStartNumberVal(data[index]);
      } else if (state?.reviewFor === "overall") {
        let data = state?.questionIds;
        let index = state?.scoredata.findIndex(
          (item) => item?.startnumber === notAttendQues
        );
        setStartNumber(index + 1);
        setQdStartNumberVal(data[index]);
      } else if (state.reviewFor === "timed-test") {
        let data = [];
        state?.scoredata.map((item) => {
          data.push(item.startnumber);
        });
        let index = state?.scoredata.findIndex(
          (item) => item?.startnumber === notAttendQues
        );
        setStartNumber(index + 1);
        setQdStartNumberVal(data[index]);
      } else if (state.reviewFor === "mock") {
        let data = [];
        state?.scoredata.map((item) => {
          data.push(item.startnumber);
        });
        let index = state?.scoredata.findIndex(
          (item) => item?.startnumber === notAttendQues
        );
        setStartNumber(index + 1);
        setQdStartNumberVal(data[index]);
      } else if (
        state.componentIdentity === "timedTestStarted" ||
        state?.componentIdentity === "mockquesstarted"
      ) {
        let data = [];
        state?.scoredata.map((item) => {
          data.push(item.startnumber);
        });
        let index = state?.scoredata.findIndex(
          (item) => item?.startnumber === mockSelectQues
        );
        setStartNumber(index + 1);
        setQdStartNumberVal(data[index]);
      }
    }
  }, [notAttendQues, flagVal, mockSelectQues]);

  const handleExamApi = (questionId, answerId) => {
    let payload;
    var jsonString = sessionStorage.getItem("planData");
    var jsonData = JSON.parse(jsonString);
    if (type === "quiz") {
      payload = {
        questionid: questionId,
        selectedanswer: answerId,
        planId: planId !== undefined && planId !== null ? planId : jsonData?.id
      };
    } else {
      payload = {
        referid: referid,
        selectedanswer: answerId,
        startnumber: startnumber,
        questionid: questionId,
        planId: planId !== undefined && planId !== null ? planId : jsonData?.id
      };
    }
    examApi(payload, type)
      .then(function (response) {
        setQuesData(response?.data);
        // if (type === "quiz") {
        let selAns = response?.data?.answer?.findIndex(
          (obj) => obj.selectedanswer === true
        );
        var editedscore = {
          startnumber: startnumber,
          questionid: questionId,
          flag: response?.data?.flagstatus,
          mark:
            type === "quiz" ? (response?.data?.markstatus ? 1 : 0) : selAns + 1
        };
        setFlagstatus(response?.data?.flagstatus);
        let mydata1 = scoredata?.map((u) =>
          u?.startnumber !== editedscore?.startnumber ? u : editedscore
        );
        setScoredata(mydata1);
        // } else {
        // }
      })
      .catch((res) => {
        // if (res?.response?.data?.status === 500) {
        //   setModelWin(true);
        //   setCatchError(res?.response?.data?.message);
        // } else {
        console.log(res?.response?.data?.error);
        // }
      });
  };

  useEffect(() => {
    if (
      (questiondata?.length > 0 &&
        state?.componentIdentity === "startQuiz" &&
        state?.componentIdentity !== undefined) ||
      state?.componentIdentity === "quizReview" ||
      state?.reviewFor === "overall" ||
      state?.componentIdentity === "timedTestStarted" ||
      reviewFor === "timed-test" ||
      state?.componentIdentity === "pretimedTestStarted" ||
      state?.componentIdentity === "mockquesstarted" ||
      state?.componentIdentity === "preMockQuesStarted" ||
      state?.reviewFor === "mock"
    ) {
      getQuestion();
    }
    setMaxnumber(questiondata?.length);
  }, [questiondata, startnumber]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const snackbarcall = (message1, status1) => {
    setMessage(message1);
    setAlertstatus(status1);
    setOpen(true);
  };
  const addFlag = (event) => {
    const storedSubscriptionDataString = sessionStorage.getItem("planData");
    const storedSubscriptionData = JSON.parse(storedSubscriptionDataString);
    let pathName = window?.location?.pathname;
    let payload = {
      status: event.target.checked,
      questionid: quesData?.questionid,
      planId: storedSubscriptionData?.id,
      referid: referid,
      timedTest:
        pathName.includes("/student/timed-test") || ttBoolean === "Yes"
          ? true
          : false
    };

    addFlagApi(payload, type)
      .then((res) => {
        setFlagstatus(res?.data?.result);
        let mydata1 = scoredata.map((item) => {
          if (item.startnumber === startnumber) {
            return {
              startnumber: startnumber,
              questionid: item.questionid,
              flag: res?.data?.result,
              mark: item.mark
            };
          } else {
            return {
              startnumber: item.startnumber,
              questionid: item.questionid,
              flag: item.flag,
              mark: item.mark
            };
          }
        });
        setScoredata(mydata1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrev = () => {
    setStartNumber((val) => val - 1);
    setQdStartNumberVal((val) => val - 1);
  };
  const handleNext = () => {
    setStartNumber((val) => val + 1);
    setQdStartNumberVal((val) => val + 1);
  };

  const updateScoreData = (mark) => {
    let payload = {
      startnumber: startnumber,
      mark: mark,
      referid: referid
    };
    updateScoreDataApi(payload, type)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div key={quesData?.questionid}>
      <StyledQuizBannerDiv>
        <StyledQuizIconButton disabled={startnumber === 1} onClick={handlePrev}>
          <ArrowCircleLeft fontSize="large" />
        </StyledQuizIconButton>
        <Typography variant="body2" color={"gray"}>
          Question {startnumber} of {maxnumber}
        </Typography>
        <Checkbox
          icon={<FlagOutlined sx={{ color: "gray" }} />}
          sx={{ color: "gray" }}
          value={flagstatus}
          checked={flagstatus}
          checkedIcon={<Flag sx={{ color: "red" }} />}
          onChange={addFlag}
        />
        <StyledQuizIconButton
          disabled={maxnumber === startnumber}
          onClick={handleNext}
        >
          <ArrowCircleRight fontSize="large" />
        </StyledQuizIconButton>
      </StyledQuizBannerDiv>
      <Divider />
      <div style={{ width: "100%", margin: ".5rem 0" }}>
        <div
          style={{
            color: theme.palette.text.primary,
            width: "100%",
            overflow: "auto",
            fontSize: "15px"
          }}
          dangerouslySetInnerHTML={{ __html: quesData?.result }}
        />
        <div style={{ width: "100%" }} key={quesData?.questionid}>
          {pageFor === "review" ||
          (quesData?.answeredstatus === true && type === "quiz") ? (
            <QuizResult
              quesData={quesData}
              startnumber={startnumber}
              noOfQn={questiondata?.length}
              handleNext={handleNext}
              snackbarcall={snackbarcall}
              reviewFor={reviewFor}
            />
          ) : (
            <QuizQuestion
              submitAnswer={handleExamApi.bind()}
              quesData={quesData}
              type={type}
              updateScoreData={updateScoreData}
            />
          )}
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default QuestionPage;
