import React, { useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";
import { Typography } from "@mui/material";

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "fontfamily",
      "fontsize",
      "|",
      "alignment",
      "|",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "subscript",
      "superscript",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
      "|"
    ]
  }
};

function ResultPageEditor({ setMyNotes, myNotes }) {
  const [mynote, setMynote] = useState("");
  return (
    <div>
      <Typography
        variant="body2"
        fontWeight={500}
        style={{ marginBottom: ".5rem" }}
      >
        User notes :
      </Typography>
      <CKEditor
        editor={ClassicExtended}
        config={editorConfiguration}
        data={myNotes}
        onReady={(editor1) => {
          // You can store the "editor" and use when it is needed.
        }}
        onChange={(event, editor1) => {
          const data = editor1.getData();
          // setMynote(data);
          setMyNotes(data);
        }}
        onBlur={(event, editor1) => {}}
        onFocus={(event, editor1) => {
          editor1.setData(myNotes);
        }}
      />
    </div>
  );
}

export default ResultPageEditor;
