import { Typography } from "@mui/material";
import React from "react";

function PerformanceContent({ percentage }) {
  return (
    <div
      style={{
        marginTop: "1.5rem",
        backgroundColor: "#0fd3704f",
        padding: ".7rem",
        borderRadius: "6px",
        textAlign: "center",
      }}
    >
      <div>
        <Typography variant="body" fontWeight={700}>
          Your performance
        </Typography>
      </div>
      <Typography variant="p" fontSize={15}>
        Your average score of{" "}
        <span>
          <b>{percentage || 0}%</b>
        </span>{" "}
        puts you on the{" "}
        <span>
          <b>0th percentile</b>
        </span>{" "}
        of users who are taking the exam
      </Typography>
    </div>
  );
}

export default PerformanceContent;
