import React from "react";
import JoditEditor from "jodit-react";
import AWS from "aws-sdk";
import "./custom-jodit.css";

const REGION = "ap-south-1";
const myBucket = new AWS.S3({
  params: { Bucket: "iskoolu-doc" },
  credentials: {
    accessKeyId: "AKIAUFMZ2RGJCY55ZXCQ",
    secretAccessKey: "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"
  },
  region: REGION
});
function AdminTextEditor({ editorFor, setContent, content }) {
  const uploadFile = (file) => {
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: "pgneet-img",
      Key: file?.name
      // "static/test/" +
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(evt, "evt");
        // setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) console.log(err);
      });
  };
  const editorConfig = {
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "font",
      "paragraph",
      "fontsize",
      "ul",
      "ol",
      "outdent",
      "indent",
      "align",
      "subscript",
      "superscript",
      "|",
      "image",
      "|",
      "link",
      "|",
      "table",
      "source",
      "undo",
      "redo",
      "eraser",
      "brush",
      "symbols",
      "|",
      "copyformat",
      "|",
      "cut",
      "|",
      "paste",
      "|",
      "find",
      "selectall",
      "fullsize"
    ],
    uploader: {
      // url: uploadUrl,
      // format: "json", // The response format from the server
      // params = {
      //   ACL: "public-read",
      //   Body: file,
      //   Bucket: "pgneet-img",
      //   Key: file?.name
      //   // "static/test/" +
      // },

      // myBucket
      //   .putObject(params)
      //   .on("httpUploadProgress", (evt) => {
      //     console.log(evt, "evt");
      //     // setProgress(Math.round((evt.loaded / evt.total) * 100));
      //   })
      //   .send((err) => {
      //     if (err) console.log(err);
      //   });
      insertImageAsBase64URI: true
      // imagesExtensions: ["jpg", "png", "jpeg", "gif", "svg", "webp"]
    },
    height: 350
  };
  return (
    <div>
      <JoditEditor
        key={editorFor}
        config={editorConfig}
        value={content}
        onChange={(val) => {}}
        tabIndex={1}
        onBlur={(val) => setContent(val)}
        style={{ height: editorConfig.height }}
      />
    </div>
  );
}

export default AdminTextEditor;
