import React from "react";
import { CustomNoRowsOverlay } from "./CustomNoRowsOverlay";
import { StripedDataGrid } from "./StrippedDataGrid";
import useStyles from "../widgets/datagridStyles";
import "./custom.css";
import {
  GridToolbar,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
  gridPaginationSelector
} from "@mui/x-data-grid";

import MuiPagination from "@mui/material/Pagination";
import { useEffect } from "react";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const Value = useGridSelector(apiRef, gridPaginationSelector);

  sessionStorage.setItem("paginationPageSize", Value.paginationModel.pageSize);
  sessionStorage.setItem("paginationPage", Value.paginationModel.page);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        sessionStorage.setItem("paginationPage", newPage + 1);
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return (
    <GridPagination
      style={{ color: "black" }}
      ActionsComponent={Pagination}
      {...props}
    />
  );
}

function AdminDataGrid({ rows, columns, loading, exportOpt }) {
  const classes = useStyles();

  return (
    <div>
      <div
        style={{
          // minHeight: rows?.length !== 0 ? "auto" : 400,
          marginTop: "1rem"
        }}
      >
        <StripedDataGrid
          rows={rows}
          loading={loading}
          autoHeight={rows?.length !== 0 ? true : 500}
          columns={columns}
          classes={{ columnHeader: classes.colCell }}
          showCellRightBorder={true}
          getRowHeight={() => "auto"}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{
            toolbar: GridToolbar,
            pagination: CustomPagination,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          slotProps={{
            toolbar: {
              csvOptions: {
                disableToolbarButton: exportOpt === true ? false : true
              },
              printOptions: { disableToolbarButton: true }
            }
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize:
                  parseInt(sessionStorage.getItem("paginationPageSize")) || 5,
                page: parseInt(sessionStorage.getItem("paginationPage")) || 0
              }
            }
          }}
        />
      </div>
    </div>
  );
}

export default AdminDataGrid;
