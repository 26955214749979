import React, { useEffect, useState } from "react";
import {
  TransparentHead,
  AboutParaTypo,
  AboutParaTypoDiv
} from "../../styledcomponents/beforeLoginWidgets";
import {
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  InputBase,
  Grid,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  FormControl,
  Box,
  FormHelperText, useTheme
} from "@mui/material";
import { Add, ArrowDropDown } from "@mui/icons-material";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import { Modal, Switch, Form } from "antd";
import Swal, { default as swal } from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";
import {DataGrid, GridActionsCellItem, gridClasses} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  addUserRolePrivileges,
  getUserRolePrivileges,
  handleinstructionApi
} from "../../../http/allAdminApi";
import useStyles from "../widgets/datagridStyles";
import {alpha} from "@mui/material/styles";

const rolesOptions = [
  { id: "admin", label: "Admin" },
  { id: "dataEntry", label: "Data Entry" },
  { id: "developer", label: "Developer" }
];

function AdminRoles() {
  const classes = useStyles();
  const ODD_OPACITY = 0.2;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [checkBox, setCheckBox] = useState(true);
  const [subjectVal, setSubjectVal] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [modalFor, setModalFor] = useState("Add");
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [rolesObject, setRolesObject] = useState({
    id: "",
    usrId: "",
    status: false,
    userRole: "",
    usrName: "",
    usrEmail: "",
    password: "",
    flag: "",
    dashboard: false,
    questionBank: false,
    highYield: false,
    mock: false,
    faqs: false,
    invoices: false,
    members: false,
    subject: false,
    category: false,
    subCategory: false,
    HyCategory: false,
    state: false,
    exam: false,
    subscription: false,
    boardCastMessage: false,
    changePassword: false
  });
  const handleMenu = (menu) => {
    setModalFor(menu);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    setRolesObject({
      id: "",
      usrId: "",
      status: false,
      userRole: "",
      usrName: "",
      usrEmail: "",
      password: "",
      flag: "",
      dashboard: false,
      questionBank: false,
      highYield: false,
      mock: false,
      faqs: false,
      invoices: false,
      members: false,
      subject: false,
      category: false,
      subCategory: false,
      HyCategory: false,
      state: false,
      exam: false,
      subscription: false,
      boardCastMessage: false,
      changePassword: false
    });
    setOpenModal(false);
    getPrivileges();
  };
  const getPrivileges = () => {
    let payload = {
      flag: "all"
    };
    addUserRolePrivileges(payload)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };

  const handleAddApi = (val) => {
    let payload = {
      usrName: rolesObject.usrName,
      email: rolesObject.usrEmail,
      password: rolesObject.password,
      // status: rolesObject.status,
      userRole: rolesObject.userRole,
      flag: val,
      privilege: {
        dashboard: rolesObject.dashboard,
        questionBank: rolesObject.questionBank,
        highYield: rolesObject.highYield,
        mock: rolesObject.mock,
        faqs: rolesObject.faqs,
        invoices: rolesObject.invoices,
        members: rolesObject.members,
        subject: rolesObject.subject,
        category: rolesObject.category,
        subCategory: rolesObject.subCategory,
        HyCategory: rolesObject.HyCategory,
        state: rolesObject.state,
        exam: rolesObject.exam,
        subscription: rolesObject.subscription,
        boardCastMessage: rolesObject.boardCastMessage,
        changePassword: rolesObject.changePassword,
        status: rolesObject.status
      }
    };
    addUserRolePrivileges(payload)
      .then((res) => {
        if (res?.data?.status === "Success") {
          handleSuccess();
          setSuccessMsg(res?.data?.message);
          setAlertstatus("success");
          setOpen(true);
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };

  const handleEditApi = (val) => {
    let payload = {
      usrName: rolesObject.usrName,
      email: rolesObject.usrEmail,
      password: rolesObject.password,
      // status: rolesObject.status,
      userRole: rolesObject.userRole,
      flag: val,
      id: rolesObject.usrId,
      privilege: {
        id: rolesObject.id,
        dashboard: rolesObject.dashboard,
        questionBank: rolesObject.questionBank,
        highYield: rolesObject.highYield,
        mock: rolesObject.mock,
        faqs: rolesObject.faqs,
        invoices: rolesObject.invoices,
        members: rolesObject.members,
        subject: rolesObject.subject,
        category: rolesObject.category,
        subCategory: rolesObject.subCategory,
        HyCategory: rolesObject.HyCategory,
        state: rolesObject.state,
        exam: rolesObject.exam,
        subscription: rolesObject.subscription,
        boardCastMessage: rolesObject.boardCastMessage,
        changePassword: rolesObject.changePassword,
        status: rolesObject.status
      }
    };
    addUserRolePrivileges(payload)
      .then((res) => {
        if (res?.data?.status === "Success") {
          handleSuccess();
          setSuccessMsg(res?.data?.message);
          setAlertstatus("success");
          setOpen(true);
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };

  const handleDeleteApi = (id) => {
    let paylaod = {
      flag: "delete",
      id: id
    };
    console.log("Delet===>paylaod", paylaod);
  };

  const handleSave = (cond) => {
    if (cond === "add") {
      handleAddApi(cond);
    } else {
      handleEditApi(cond);
    }
  };

  const openActionMenu = (condition, roleObject) => {
    setAnchorEl(null);
    if (condition === "Edit") {
      setModalFor("Edit");
      setOpenModal(true);
      console.log(roleObject, "roleObject");
      setRolesObject({
        id: roleObject.privilege.id,
        usrId: roleObject.id,
        status: roleObject.privilege.status,
        userRole: roleObject.userRole,
        usrName: roleObject.usrName,
        usrEmail: roleObject.email,
        password: roleObject.password,
        flag: "Edit",
        dashboard: roleObject.privilege.dashboard,
        questionBank: roleObject.privilege.questionBank,
        highYield: roleObject.privilege.highYield,
        mock: roleObject.privilege.mock,
        faqs: roleObject.privilege.faqs,
        invoices: roleObject.privilege.invoices,
        members: roleObject.privilege.members,
        subject: roleObject.privilege.subject,
        category: roleObject.privilege.category,
        subCategory: roleObject.privilege.subCategory,
        HyCategory: roleObject.privilege.hyCategory,
        state: roleObject.privilege.state,
        exam: roleObject.privilege.exam,
        subscription: roleObject.privilege.subscription,
        boardCastMessage: roleObject.privilege.boardCastMessage,
        changePassword: roleObject.privilege.changePassword
      });
    } else if (condition === "add") {
      setModalFor("Add");
      setOpenModal(true);
      // handleAddApi();
    }
  };

  const deleteRow = () => {
    setAnchorEl(null);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        handleDeleteApi(paramData?.id).then((res) => {
          if (res?.data?.status === "Success") {
            getPrivileges();
          }
        });
      }
    });
  };

  useEffect(() => {
    getPrivileges();
  }, []);
  const onChange = (checked) => {
    setCheckBox(!checkBox);
  };

  const columns = [
    {
      field: "usrName",
      headerName: "User Name",
      flex: 1.5,
      hide: true
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      hide: true
    },
    {
      field: "userRole",
      headerName: "Roles",
      flex: 1,
      hide: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (rowParams) => {
        return [
          <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => openActionMenu("Edit", rowParams.row)}
              color="inherit"
          />
        ];
      },
    }
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Manage Roles
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "1rem"
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleMenu("add")}
            style={{ margin: "1rem", borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add Role
          </StyledGreenButton>
        </div>
        <DataGrid
            sx={{
              [`& .${gridClasses.row}.even`]: {
                "&:hover, &.Mui-hovered": {
                  backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                  "@media (hover: none)": {
                    backgroundColor: "none",
                  },
                },
                "&.Mui-selected": {
                  backgroundColor: alpha(
                      theme.palette.primary.main,
                      ODD_OPACITY + theme.palette.action.selectedOpacity
                  ),
                  "&:hover, &.Mui-hovered": {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY +
                        theme.palette.action.selectedOpacity +
                        theme.palette.action.hoverOpacity
                    ),
                    color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "black",
                    "@media (hover: none)": {
                      backgroundColor: alpha(
                          theme.palette.primary.main,
                          ODD_OPACITY + theme.palette.action.selectedOpacity
                      ),
                      color: alpha(
                          theme.palette.text.primary,
                          ODD_OPACITY + theme.palette.action.selectedOpacity
                      ),
                    },
                  },
                },
              },
              border: 0,
              color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "black",
              fontFamily: [
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
              ].join(","),
              WebkitFontSmoothing: "auto",
              letterSpacing: "normal",
              "& .MuiDataGrid-columnsContainer": {
                backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "black",
              },
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
                color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "black",
              },
              "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
                borderRight: `1px solid ${
                    theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
                }`,
                color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "black",
              },
              "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                borderBottom: `1px solid ${
                    theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
                }`,
                color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "black",
              },
              "& .MuiDataGrid-cell": {
                color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "black",
                marginTop: ".5rem",
                marginBottom: ".5rem"
              },
              "& .MuiPaginationItem-root": {
                borderRadius: 0,
                color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "black",
              },
            }}
            rows={resData}
            columns={columns}
            autoHeight={resData?.length !== 0 ? true : false}
            showCellRightBorder={true}
            classes={{ columnHeader: classes.colCell }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            pageSizeOptions={[10, 50, 100]}
            getRowHeight={() => "auto"}
        />
        {/*<AdminDataGrid loading={loading} columns={columns} rows={resData} />*/}
      </div>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "add" ? "Add Roles" : "Edit Roles"}
          </Typography>
        }
        width={650}
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              style={{ margin: ".5rem 0 1.5rem 0" }}
              size="small"
              fullWidth
              label="User Name"
              id="subName"
              value={rolesObject.usrName}
              onChange={(e) => {
                setRolesObject({ ...rolesObject, usrName: e.target.value });
              }}
            />
          </Grid>
          {modalFor === "Edit" ? null : (
            <Grid item xs={6}>
              <TextField
                style={{ margin: ".5rem 0 1.5rem 0" }}
                size="small"
                fullWidth
                label="Password"
                id="subName"
                value={rolesObject.password}
                onChange={(e) => {
                  setRolesObject({ ...rolesObject, password: e.target.value });
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              style={{ margin: ".5rem 0 1.5rem 0" }}
              size="small"
              fullWidth
              label="Email ID"
              id="subName"
              value={rolesObject.usrEmail}
              onChange={(e) => {
                setRolesObject({ ...rolesObject, usrEmail: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="roleOptionField"
              select
              label="Role"
              placeholder="Select the role"
              type="text"
              size="small"
              style={{ margin: ".5rem 0 1.5rem 0" }}
              fullWidth
              value={rolesObject.userRole}
              onChange={(e) => {
                setRolesObject({ ...rolesObject, userRole: e.target.value });
              }}
            >
              {rolesOptions?.map((item, id) => (
                <MenuItem key={id} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/*<Grid item xs={6}>
            <Form.Item label="Status">
              <Switch
                checked={rolesObject.status}
                onChange={(e) => {
                  console.log("Switch value:", e);
                  setRolesObject({
                    ...rolesObject,
                    status: e
                  });
                }}
              />
            </Form.Item>
          </Grid>*/}
        </Grid>

        <div style={{ fontWeight: 600 }}>Select Navbar Menu from the list</div>
        <Box sx={{ display: "flex", gap: "10%" }}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.dashboard}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        dashboard: JSON.parse(e.target.checked)
                      });
                    }}
                    name="dashboard"
                  />
                }
                label="Dashboard"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.questionBank}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        questionBank: JSON.parse(e.target.checked)
                      });
                    }}
                    name="questionBank"
                  />
                }
                label="Question Bank"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.highYield}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        highYield: JSON.parse(e.target.checked)
                      });
                    }}
                    name="highYield"
                  />
                }
                label="High Yield"
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.mock}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        mock: JSON.parse(e.target.checked)
                      });
                    }}
                    name="mock"
                  />
                }
                label="Mock"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.faqs}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        faqs: JSON.parse(e.target.checked)
                      });
                    }}
                    name="faqs"
                  />
                }
                label="FAQs"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.blog}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        blog: JSON.parse(e.target.checked)
                      });
                    }}
                    name="blog"
                  />
                }
                label="Blog"
              />
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.invoices}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        invoices: JSON.parse(e.target.checked)
                      });
                    }}
                    name="invoices"
                  />
                }
                label="Invoices"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.members}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        members: JSON.parse(e.target.checked)
                      });
                    }}
                    name="members"
                  />
                }
                label="Members"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#31a188",
                      "&.Mui-checked": { color: "#31a188" }
                    }}
                    checked={rolesObject.roles}
                    onChange={(e) => {
                      setRolesObject({
                        ...rolesObject,
                        roles: JSON.parse(e.target.checked)
                      });
                    }}
                    name="roles"
                  />
                }
                label="Roles"
              />
            </FormGroup>
          </FormControl>
        </Box>

        <div style={{ fontWeight: 600 }}>
          Select settings menu from the list
          <Checkbox
            sx={{ color: "#31a188", "&.Mui-checked": { color: "#31a188" } }}
            checked={rolesObject.settings}
            onChange={(e) => {
              setRolesObject({
                ...rolesObject,
                settings: JSON.parse(e.target.checked)
              });
            }}
            name="settings"
          />
        </div>
        {rolesObject.settings && (
          <Box sx={{ display: "flex", gap: "10%" }}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.subject}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          subject: JSON.parse(e.target.checked)
                        });
                      }}
                      name="subject"
                    />
                  }
                  label="Subject"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.category}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          category: JSON.parse(e.target.checked)
                        });
                      }}
                      name="category"
                    />
                  }
                  label="Category"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.subCategory}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          subCategory: JSON.parse(e.target.checked)
                        });
                      }}
                      name="subCategory"
                    />
                  }
                  label="Sub Category"
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.HyCategory}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          HyCategory: JSON.parse(e.target.checked)
                        });
                      }}
                      name="hyCategory"
                    />
                  }
                  label="High Category"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.subscription}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          subscription: JSON.parse(e.target.checked)
                        });
                      }}
                      name="subscription"
                    />
                  }
                  label="Subscription"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.exam}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          exam: JSON.parse(e.target.checked)
                        });
                      }}
                      name="exam"
                    />
                  }
                  label="Exam"
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.boardCastMessage}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          boardCastMessage: JSON.parse(e.target.checked)
                        });
                      }}
                      name="announcement"
                    />
                  }
                  label="Announcement"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#31a188",
                        "&.Mui-checked": { color: "#31a188" }
                      }}
                      checked={rolesObject.changePassword}
                      onChange={(e) => {
                        setRolesObject({
                          ...rolesObject,
                          changePassword: JSON.parse(e.target.checked)
                        });
                      }}
                      name="changePassword"
                    />
                  }
                  label="Change Password"
                />
              </FormGroup>
            </FormControl>
          </Box>
        )}
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Button
            style={{
              textTransform: "none",
              backgroundColor: "#2c3643",
              color: "#fff",
              outline: "none"
            }}
            onClick={() => handleSave(modalFor)}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <MenuItem onClick={() => openActionMenu("Edit", paramData)}>
          Edit
        </MenuItem>
        {/* <MenuItem onClick={() => deleteRow("Delete", paramData)}>
          Delete
        </MenuItem> */}
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminRoles;
