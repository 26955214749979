import { Box, Snackbar } from "@mui/material";
import React, { useState } from "react";
import {
  AboutParaTypo,
  TransparentHead,
} from "../../styledcomponents/beforeLoginWidgets";
import { StyledButton } from "../../styledcomponents/styledQBExamWidgets";
import { Popconfirm } from "antd";
import MuiAlert from "@mui/material/Alert";
import { resetQuestionApi } from "../../../http/allApis";
import ModelFile from "../../landing/ModelFile";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ResetQuestionsHist() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const confirm = () => {
    resetQuestionApi()
      .then(function (response) {
        if (response.data.result === true) {
          setMessage("Reset done successfully");
          setAlertstatus("success");
          setOpen(true);
        } else {
          setMessage("Error!");
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch((response) => {
        setModelWin(true);
        setCatchError(response?.response?.data?.message);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Box
        style={{
          border: "1.5px solid  #EBECF0",
          borderRadius: "6px",
          marginTop: ".5rem",
        }}
      >
        <div
          style={{
            padding: "1rem",
            borderBottom: "1.5px solid  #EBECF0",
          }}
        >
          <TransparentHead variant="h5" fontWeight={"bold"}>
            Reset the question history
          </TransparentHead>
        </div>
        <div
          style={{
            padding: "1rem",
          }}
        >
          <AboutParaTypo variant="body2">
            This option permanently resets your question and answer history.
            This means that your performance data will be reset, access to the
            questions will be reset and all timed tests be deleted. It is
            similar to having a new account. Your personal notes will however be
            saved and available after you've reset your account. Reseting your
            history may be useful if you want to go over the questions again
            (even the ones you got right) or simply want a fresh start with the
            scoring data reset. <b>Please note that this is irreversible.</b>
          </AboutParaTypo>
          <Box mt={2}>
            <Popconfirm
              placement="top"
              title={"Are you sure to want to reset?"}
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
            >
              <StyledButton style={{ outline: "none" }}>
                Reset Question History
              </StyledButton>
            </Popconfirm>
          </Box>
          {/* <div style={{ marginTop: "1rem" }}>
          
          </div> */}
        </div>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default ResetQuestionsHist;
