import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { allTopic, highyieldCategory } from "../staticData/quizStaticData";
import { useParams } from "react-router-dom";
import SearchComp from "../student/widgets/SearchComp";
import axios from "axios";
import constantValues from "../Common/ConstantValues";
import { highyieldAllCategory, highyieldAllTopic } from "../../http/allApis";
import ModelFile from "../landing/ModelFile";

function HighYieldPage() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(600));
  const [mainmenu, setMainmenu] = useState([]);
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);
  const [submenu, setSubmenu] = useState([]);
  const [content, setContent] = useState("");
  const { categoryParam } = useParams();
  const [contentLoading, setContentLoading] = useState(false);
  const [search, setSearch] = useState(categoryParam || "");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);

  const [openKeys, setOpenKeys] = React.useState([]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const onClickSub = (e) => {
    let mycontent = submenu
      .filter((item) => parseInt(item.key) === parseInt(e.key))
      .map((item1) => item1.content);

    setContent(mycontent);
    setSearch("");
  };

  useEffect(() => {
    getMainmenu();
    getSubmenu();
  }, [categoryParam]);

  const getMainmenu = () => {
    highyieldAllCategory()
      .then((response) => {
        let mydata = response.data.result.map((item) => ({
          key: item.id,
          label: (
            <Typography>
              <b>{item.category}</b>
            </Typography>
          )
        }));
        setMainmenu(mydata);
        let mydata1 = response.data.result.map(
          (item) => "m" + item.id.toString()
        );
        setRootSubmenuKeys(mydata1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubmenu = () => {
    highyieldAllTopic()
      .then((response) => {
        let mydata = response.data.result.map((item) => ({
          key: item.id,
          label: <Typography>{item.topic}</Typography>,
          catid: item.category1,
          content: item.content
        }));

        setSubmenu(mydata);
      })
      .catch((response) => {
        setModelWin(true);
        setCatchError(response?.response?.data?.message);
      });
  };

  const handleFindContent = (searchVal) => {
    let finalSearch = searchVal || search;
    if (finalSearch.toString().length > 2) {
      let mycontent = submenu
        .filter((item) => item.content.toString().includes(finalSearch))
        .map((item1) => item1.content);

      let mycontent1 = mycontent
        .toString()
        .split(finalSearch)
        .join(
          '<span style="background-color:yellow">' + finalSearch + "</span>"
        );

      if (mycontent.length > 0) {
        setContent(mycontent1);
        setContentLoading(false);
      } else {
        setContent("");
        setContentLoading(false);
      }
    }
  };

  const searchContent = (searchVal) => {
    setContent("");
    setContentLoading(true);
    setSearch(searchVal);
    handleFindContent(searchVal);
  };

  useEffect(() => {
    handleFindContent();
  }, [submenu]);
  const mymainmenu = mainmenu.map((item) => {
    return {
      key: "m" + item.key,
      label: item.label,
      children: submenu
        .filter((item1) => item1.catid === item.key)
        .map((item2) => ({ key: item2.key, label: item2.label }))
    };
  });

  return (
    <div>
      <Typography
        variant={isSmall ? "p" : "h5"}
        fontWeight={500}
        color={"#31a188"}
      >
        High Yield
      </Typography>
      {/* <Grid item xs={12} md={6}> */}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "1rem",
          padding: ".5rem 0"
        }}
      >
        <SearchComp
          type={true}
          searchData={categoryParam}
          searchContent={searchContent}
        />
      </div>
      {/* </Grid> */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {mymainmenu.length !== 0 ? (
            <Menu
              onClick={onClickSub}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              style={{
                height: "auto",
                margin: "1rem 0",
                backgroundColor: "#b8f1d485",
                borderRadius: "4px",
                color: "#000"
              }}
              items={mymainmenu}
            />
          ) : (
            <div
              style={{
                height: "100%",
                margin: "1rem 0",
                backgroundColor: "#b8f1d485",
                borderRadius: "4px",
                padding: "2rem"
              }}
            >
              Loading...
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Box p={1} marginTop={".6rem"}>
            {content !== "" ? (
              <Typography>
                <div
                  style={{
                    color: theme.palette.mode === "dark" ? "#fff" : "#515151",
                    fontSize: "15px"
                  }}
                  dangerouslySetInnerHTML={{ __html: content }}
                />{" "}
              </Typography>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  border: "2px solid #f1f1f1",
                  borderRadius: "4px",
                  padding: "2rem",
                  marginTop: ".5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10rem"
                }}
              >
                {contentLoading ? (
                  <CircularProgress style={{ color: "#f38424" }} />
                ) : (
                  "No Data"
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default HighYieldPage;
