import { Card } from "@mui/material";
import React from "react";
import { AboutHead } from "../../styledcomponents/beforeLoginWidgets";

function DbIncomCard({ bgColor, title, amount, type }) {
  return (
    <div>
      <Card
        style={{
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: bgColor,
        }}
      >
        <div>
          <AboutHead style={{ color: "#000" }} variant="h6" fontWeight={"bold"}>
            {title}
          </AboutHead>
          <AboutHead style={{ color: "#2c3643" }} align="center" variant="h4">
            {type === "income" ? <span>&#8377;</span> : null} {amount}
          </AboutHead>
        </div>
      </Card>
    </div>
  );
}

export default DbIncomCard;
