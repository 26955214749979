import { Paper, Typography } from "@mui/material";
import React from "react";

function AdminMenu() {
  return (
    <div style={{ marginTop: "2rem" }}>
      <Paper
        style={{ padding: "2rem", justifyContent: "center", display: "flex" }}
      >
        <Typography>Please turn on the desktop site option.</Typography>
      </Paper>
    </div>
  );
}

export default AdminMenu;
