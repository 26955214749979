import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AboutParaTypo } from "../../styledcomponents/beforeLoginWidgets";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, Snackbar, Alert } from "@mui/material";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import { Form, Switch } from "antd";
import { ArrowBackIos, Close, Delete, Done, Edit } from "@mui/icons-material";
import {
  QbAddAnswerApi,
  QbDeleteAnswerApi,
  QbEditAnswerApi,
  adminQBAnswersApi
} from "../../../http/allAdminApi";
import { default as swal } from "sweetalert2";
import AdminTextEditor from "../widgets/AdminTextEditor";
import { useNavigate } from "react-router";
function AdminQBAnswers() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const questionData = state?.questionData;
  const [checkBox, setCheckBox] = useState(true);
  const [rows, setRows] = useState(questionData?.answer || []);
  const [showEditor, setShowEditor] = useState(false);
  const [answerVal, setAnswerVal] = useState("");
  const [editorFor, setEditorFor] = useState("Add");
  const [successMsg, setSuccessMsg] = useState("");
  const [answerId, setAnswerId] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const handleApiClose = () => {
    setOpen(false);
  };

  const handleDelete = (data) => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          deleteApi(data.id);
        }
      });
  };

  const answerApi = () => {
    let mockVal = { ...questionData };
    adminQBAnswersApi(questionData?.id).then((res) => {
      mockVal.answer = res?.data?.data || [];
      setRows(mockVal);
      setRows(res?.data?.data || []);
    });
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setOpen(true);
      setShowEditor(false);
      answerApi();
      setAnswerVal("");
      setCheckBox(true);
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleAction = (val) => {
    if (val === "Add") {
      setEditorFor("Add");
      setShowEditor(true);
    }
  };

  const handleAddAnswer = (id) => {
    let payload = {
      questionId: questionData?.id,
      answer: answerVal,
      status: checkBox
    };
    QbAddAnswerApi(payload)
      .then((res) => {
        handleSuccess(res);
        handleClose();
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleEditAnswer = () => {
    let payload = {
      questionId: questionData?.id,
      id: answerId,
      answer: answerVal,
      status: checkBox
    };
    QbEditAnswerApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const deleteApi = (id) => {
    let payload = {
      id: id
    };
    QbDeleteAnswerApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleSave = (val) => {
    if (val === "Add") {
      handleAddAnswer();
    } else if (val === "Edit") {
      handleEditAnswer();
    }
  };

  const handleEdit = (val) => {
    setEditorFor("Edit");
    setAnswerId(val.id);
    setAnswerVal(val?.answer);
    setCheckBox(val.status);
    setShowEditor(true);
  };

  const handleClose = () => {
    setAnswerVal("");
    setShowEditor(false);
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "firstName",
      headerName: "Answer",
      // width: 800,
      flex: 5,
      renderCell: (params) => {
        return (
          <AboutParaTypo variant="p">
            <div
              style={{
                width: "100%"
                // fontSize: "15px",
              }}
              dangerouslySetInnerHTML={{
                __html: params?.row?.answer
              }}
            />
          </AboutParaTypo>
        );
      }
    },
    {
      field: "lastName",
      headerName: "Correct Answer",
      // width: 120,
      flex: 1,
      renderCell: (params) => {
        return params.row.status ? (
          <Done sx={{ color: "green" }} />
        ) : (
          <Close sx={{ color: "red" }} />
        );
      }
    },
    {
      field: "age",
      headerName: "Action",
      // width: window.innerWidth > 1800 ? 500 : 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <IconButton
              style={{ outline: "none" }}
              onClick={() => handleEdit(params.row)}
            >
              <Edit sx={{ color: "gray" }} />
            </IconButton>
            <IconButton
              style={{ outline: "none" }}
              onClick={() => handleDelete(params.row)}
            >
              <Delete sx={{ color: "#D32F2F" }} />
            </IconButton>
          </div>
        );
      }
    }
  ];

  const handleBack = () => {
    navigate("/admin/question");
  };

  return (
    <div
      style={{
        margin: "1rem 0",
        backgroundColor: "#fff",
        padding: "1rem"
      }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <IconButton
          style={{ outline: "none", color: "#000" }}
          onClick={handleBack}
        >
          <ArrowBackIos />
        </IconButton>
        <AboutParaTypo
          variant="p"
          style={{
            color: "#000"
          }}
        >
          <b>Question Id : </b>
          {questionData?.id}
        </AboutParaTypo>
      </div>
      <AboutParaTypo variant="p" style={{ color: "black" }}>
        <div
          style={{
            width: "100%"
            // fontSize: "15px",
          }}
          dangerouslySetInnerHTML={{
            __html: questionData?.question
          }}
        />
      </AboutParaTypo>
      {showEditor ? (
        <div style={{ margin: "2rem 0", color: "black" }}>
          <AdminTextEditor
            editorFor={"Answer"}
            content={answerVal}
            setContent={(val) => setAnswerVal(val)}
          />
          <div>
            <Form.Item label="Status">
              <Switch
                checked={checkBox}
                onChange={() => setCheckBox(!checkBox)}
              />
            </Form.Item>
          </div>
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "end"
            }}
          >
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#dc3545",
                color: "#fff",
                marginRight: "1rem",
                outline: "none"
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#2c3643",
                color: "#fff",
                outline: "none"
              }}
              onClick={() => handleSave(editorFor)}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{ margin: "1rem 0", display: "flex", justifyContent: "end" }}
        >
          {questionData.answer.length >= 5 || rows.length >= 5 ? null : (
            <StyledGreenButton
              onClick={() => handleAction("Add")}
              style={{ outline: "none" }}
            >
              Add New
            </StyledGreenButton>
          )}
        </div>
      )}

      <Box
        sx={{ height: 300, width: "100%", marginTop: "1rem", color: "black" }}
      >
        <DataGrid
          style={{ color: "black" }}
          rows={rows}
          hideFooter
          columns={columns}
          disableRowSelectionOnClick
        />
      </Box>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleApiClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleApiClose}
            severity={alertstatus}
            sx={{ width: "100%" }}
          >
            {successMsg}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default AdminQBAnswers;
