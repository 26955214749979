import { Close, Done, Flag } from "@mui/icons-material";
import {
  Table,
  TableContainer,
  TableBody,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";
import React from "react";
import {
  RowResTypo,
  RowResTypoInnerHtml,
  StyledTableCell,
  StyledTableRow
} from "../../styledcomponents/styledQuizWidgets";
import { useNavigate } from "react-router-dom";

function ReviewQuestions({ reviewQnData, referid, reviewFor, planId }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(600));
  const navigate = useNavigate();
  const handleReview = (questionid, startnumberVal) => {
    var jsonString = sessionStorage.getItem("planData");
    var jsonData = JSON.parse(jsonString);
    let qnIds = reviewQnData?.map((item, id) => item?.questionid);
    if (reviewFor === "quiz") {
      let scoreData = reviewQnData?.map((item1) => ({
        startnumber: item1.startnumber,
        questionid: item1.questionid,
        mark: item1.status ? 1 : 0,
        flag: item1.flag
      }));
      let propsData = {
        questionIds: qnIds,
        pageFor: "review",
        reviewFor: "quiz",
        scoredata: scoreData,
        referid: referid,
        startnumber: startnumberVal,
        planId: jsonData?.id,
        componentIdentity: "quizReview"
      };
      navigate("/student/quiz", {
        state: propsData
      });
    } else if (reviewFor === "timed-test") {
      let scoreData = reviewQnData?.map((item1) => ({
        startnumber: item1.startnumber,
        mark: item1.status ? 1 : 0,
        flag: item1.flag
      }));
      let propsData = {
        questionIds: qnIds,
        pageFor: "review",
        reviewFor: "timed-test",
        scoredata: scoreData,
        referid: referid,
        quesId: questionid,
        startnumber: startnumberVal,
        planId: jsonData?.id
      };
      navigate("/student/quiz", {
        state: propsData
      });
    } else if (reviewFor === "mock") {
      let scoreData = reviewQnData?.map((item1) => ({
        startnumber: item1.startnumber,
        mark: item1.status ? 1 : 0,
        flag: item1.flag
      }));
      let propsData = {
        questionIds: qnIds,
        pageFor: "review",
        reviewFor: "mock",
        scoredata: scoreData,
        referid: referid,
        startnumber: startnumberVal,
        quesId: questionid,
        planId: jsonData?.id
      };
      navigate("/student/quiz", {
        state: propsData
      });
    } else {
      let scoreData = reviewQnData?.map((item1) => ({
        startnumber: item1.startnumber,
        mark: item1.status ? 1 : 0,
        flag: item1.flag
      }));
      let propsData = {
        questionIds: qnIds,
        pageFor: "review",
        reviewFor: "overall",
        scoredata: scoreData,
        referid: referid,
        quesId: questionid,
        planId: jsonData?.id,
        startnumber: startnumberVal
      };
      navigate("/student/quiz", {
        state: propsData
      });
    }
  };

  return (
    <div>
      {reviewQnData && reviewQnData.length !== 0 ? (
        <TableContainer>
          <Table>
            <TableBody>
              {reviewQnData?.map((row, id) => (
                <StyledTableRow key={id}>
                  <StyledTableCell width={40} component="th" scope="row">
                    <RowResTypo
                      variant="caption"
                      style={{ color: theme.palette.text.secondary, width: 35 }}
                    >
                      {id + 1}
                    </RowResTypo>
                  </StyledTableCell>
                  <StyledTableCell>
                    <RowResTypoInnerHtml variant="caption">
                      <div
                        style={{
                          color: theme.palette.text.secondary,
                          fontSize: isSmall ? "11px" : "15px"
                          // marginTop: "1rem",
                        }}
                        dangerouslySetInnerHTML={{ __html: row?.question }}
                      />
                    </RowResTypoInnerHtml>
                  </StyledTableCell>
                  <StyledTableCell>
                    <RowResTypo variant="caption">
                      {row?.status === true ? (
                        <Done sx={{ color: "green" }} />
                      ) : row?.status === false ? (
                        <Close sx={{ color: "red" }} />
                      ) : (
                        "-"
                      )}
                    </RowResTypo>
                  </StyledTableCell>
                  <StyledTableCell width={40}>
                    {row?.flag ? <Flag fontSize="small" color="error" /> : null}
                  </StyledTableCell>
                  <StyledTableCell align="right" width={90}>
                    <RowResTypo
                      variant="caption"
                      sx={{ color: "blue", cursor: "pointer" }}
                      onClick={() => handleReview(row, row?.startnumber)}
                    >
                      Review
                    </RowResTypo>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            width: "100%",
            border: "2px solid #f1f1f1",
            borderRadius: "4px",
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10rem"
          }}
        >
          No Data
        </Box>
      )}
    </div>
  );
}

export default ReviewQuestions;
