import React from "react";
import { SocialButton } from "../../styledcomponents/beforeLoginWidgets";
import { FacebookOutlined } from "@mui/icons-material";
import {
  ConfirmUserApi,
  fbGraphApi,
  socialSignUpApi
} from "../../../http/allApis";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import ModelFile from "../../landing/ModelFile";
import { StyledButton } from "../../styledcomponents/styledQBExamWidgets";
import { Typography } from "@mui/material";
import { Modal } from "antd";
import { StyledFooterIconButton } from "../../styledcomponents/styledDBwidgets";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FBLoginButton({ buttonFor, callBackFunction, handleAlert }) {
  const [modelWin, setModelWin] = useState(false);
  const [resp, setResp] = useState();
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "2841983716098248",
      cookie: true,
      xfbml: true,
      version: "v12.0"
    });

    window.FB.getLoginStatus(function (response) {
      console.log(response);
      if (response.status === "connected") {
        console.log("User is logged in");
      } else {
        console.log("User is not logged in");
      }
    });
  };
  function loginWithFacebook() {
    window.FB.login(
      function (response) {
        setResp(response);
        if (response.authResponse) {
          let payload1 = {
            facebookId: response.authResponse.userID,
            webToken: response.authResponse.accessToken,
            signupType: "Facebook"
          };
          socialSignUpApi(payload1, buttonFor)
            .then((res) => {
              if (res.data.status === "Success") {
                sessionStorage.setItem("token", res.data.data.accessToken);
                sessionStorage.setItem(
                  "Tokentype",
                  JSON.stringify(res?.data?.data)
                );
                callBackFunction(res.data.data);
              } else if (res.data.status === "Failure") {
                handleAlert(res.data.message, "warning");
              } else {
                if (
                  res.data.error.description ===
                  "You Are Already Login.You Need to Continue This Session."
                ) {
                  setModelWin(true);
                } else {
                  handleAlert(res.data.error.description, "warning");
                }
              }
            })
            .catch((response) => {
              console.log(response);
            });
        } else {
          // User canceled the login process or didn't authorize the app
          console.log("User canceled login or did not authorize the app");
        }
      },
      { scope: "email" }
    );
  }

  const handleLoginIssue = () => {
    let payload = {
      webToken: resp.authResponse.accessToken,
      signupType: "Facebook"
    };
    ConfirmUserApi(payload).then((res) => {
      if (res.data.status === "Success") {
        setModelWin(false);
        socialSignUpApi(payload, buttonFor)
          .then((res) => {
            if (res.data.status === "Success") {
              sessionStorage.setItem("token", res.data.data.accessToken);
              sessionStorage.setItem(
                "Tokentype",
                JSON.stringify(res?.data?.data)
              );
              callBackFunction(res.data.data);
            } else if (res.data.status === "Failure") {
              handleAlert(res.data.message, "warning");
            } else {
              handleAlert(res.data.error.description, "warning");
              setModelWin(true);
            }
          })
          .catch((err) => {});
      }
    });
  };
  return (
    <div>
      <StyledFooterIconButton
        id={"login-button"}
        variant="outlined"
        onClick={loginWithFacebook}
        style={{
          backgroundColor: "white",
          outline: "none"
        }}
      >
        <FontAwesomeIcon
          size="xs"
          color="blue"
          icon={faFacebookF}
          onClick={loginWithFacebook}
        />
      </StyledFooterIconButton>
      {/* <SocialButton
        id={"login-button"}
        variant="outlined"
        onClick={loginWithFacebook}
        style={{
          marginRight: "1rem",
          width: "100%",
          outline: "none",
          height: "2.05rem"
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "10%" }}>
            <FacebookOutlined
              style={{
                color: "#0a58ca",
                marginRight: ".5rem",
                fontSize: "20px"
              }}
            />
          </div>
          <div style={{ width: "90%" }}>
            <span style={{ color: "#4b4f54", textAlign: "center" }}>
              Sign in with Facebook
            </span>
          </div>
        </div>
      </SocialButton> */}
      <div>
        <div>
          <Modal
            title={
              <Typography variant="h6" gutterBottom>
                Login Alert{" "}
              </Typography>
            }
            open={modelWin}
            onOk={() => setModelWin(false)}
            onCancel={() => setModelWin(false)}
            footer={null}
          >
            <Typography style={{ marginBottom: "1rem" }}>
              This message means that you've already signed into your account or
              session on this platform.
            </Typography>
            <Typography>
              To proceed, you don't need to log in again. Instead, you can pick
              up where you left off in your current session without re-entering
              your login credentials.
            </Typography>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <StyledButton
                onClick={() => handleLoginIssue("Cancel")}
                style={{ outline: "none" }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                onClick={() => handleLoginIssue("Continue")}
                style={{
                  outline: "none",
                  marginLeft: "1rem",
                  backgroundColor: "#31a188"
                }}
              >
                Continue
              </StyledButton>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default FBLoginButton;
