import Axios from "axios";
import constantValues from "../components/Common/ConstantValues";

//Admin api

export const AdminDasboardApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/admin-dashboard";
  return Axios.get(URL, {
    headers: options
  });
};

export const questionApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/question";
  return Axios.get(URL, {
    headers: options
  });
};

export const quesExpApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/textbook";
  return Axios.get(URL, {
    headers: options
  });
};

export const TopicsApi = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/topic";
  return Axios.get(URL, {
    headers: options,
    params: {
      id: id
    }
  });
};

export const TopicsCategoryApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/topic-category";
  return Axios.get(URL, {
    headers: options
  });
};

export const mockQuestionApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/mock-question";
  return Axios.get(URL, {
    headers: options
  });
};

export const admimMockAnswerApi = (quesId) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/mock-answer";
  return Axios.get(URL, {
    headers: options,
    params: { questionId: quesId }
  });
};

export const mockExplanApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/mock-textbook";
  return Axios.get(URL, {
    headers: options
  });
};

export const faqApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/admin-faq";
  return Axios.get(URL, {
    headers: options
  });
};

export const InvoiceAPi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/invoice";
  return Axios.get(URL, {
    headers: options
  });
};

export const MemberApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/admin-members";
  return Axios.get(URL, {
    headers: options
  });
};

export const SettingSubjectApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/main-category";
  return Axios.get(URL, {
    headers: options
  });
};

export const SettingCategoryApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/category";
  return Axios.get(URL, {
    headers: options
  });
};
export const SettingCategoryApiId = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/category";
  return Axios.get(URL, {
    headers: options,
    params: {
      subjectId: id
    }
  });
};

export const SettingSubCategoryApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/sub-category";
  return Axios.get(URL, {
    headers: options
  });
};

export const SettingSubCategoryApiId = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/sub-category";
  return Axios.get(URL, {
    headers: options,
    params: {
      categoryId: id
    }
  });
};

export const SettingStateApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/state";
  return Axios.get(URL, {
    headers: options
  });
};

export const SettingExamApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/exam";
  return Axios.get(URL, {
    headers: options
  });
};

export const SettingSubscriptionApi = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/subscription";
  return Axios.get(URL, {
    headers: options
  });
};

export const SettingSubjectAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-main-category";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubjectEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-main-category";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubjectDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-main-category";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingCategoryAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-category";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingCategoryEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-category";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingCategoryDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-category";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubCategoryAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-subcategory";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubCategoryEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-subcategory";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubCategoryDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-subcategory";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingStateAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-state";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingStateEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-state";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingStateDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-state";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingExamAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-exam";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingExamEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-exam";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingExamDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-exam";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubcribAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-subscription";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubcribEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-subscription";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const SettingSubcribDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-subscription";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const FaqAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-faq";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const FaqEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-faq";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const FaqDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-faq";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyRefApi = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/view-ref";
  return Axios.get(URL, {
    headers: options,
    params: { id: id }
  });
};

export const HyTopicAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-hightopics";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyTopicEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-hightopics";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QBQuestionAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qb-add-question";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QuestionSetLimit = (payload) => {
  console.log(payload, "QuestionSetLimit");
  // const options = {
  //   Authorization: "Bearer " + sessionStorage.getItem("token"),
  // };
  // const URL = constantValues.PGNEET_PROD_URL + "/qb-add-question";
  // return Axios.post(URL, payload, {
  //   headers: options,
  // });
  let res = {
    data: {
      status: "success"
    }
  };
  return res;
};

export const MockQuestionSetLimit = (payload) => {
  console.log(payload, "MockQuestionSetLimit");
  // const options = {
  //   Authorization: "Bearer " + sessionStorage.getItem("token"),
  // };
  // const URL = constantValues.PGNEET_PROD_URL + "/qb-add-question";
  // return Axios.post(URL, payload, {
  //   headers: options,
  // });
};

export const QBQuestionEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qb-edit-question";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QBQuestionDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qb-delete-question";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QBQuestionRefApi = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/question-ref";
  return Axios.get(URL, {
    headers: options,
    params: {
      questionId: id
    }
  });
};

export const QBExplanationAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-textbook";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyTopicDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-hightopics";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QBExplanationEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-textbook";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyCategoryAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-highcategory";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QBExplanationDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-textbook";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyCategoryEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-highcategory";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyCategoryDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-highcategory";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyviewRefApi = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/view-ref";
  return Axios.get(URL, {
    headers: options,
    params: { id: id }
  });
};

export const HyViewAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-hightopics";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QBExplanationRefApi = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/textbook-ref";
  return Axios.get(URL, {
    headers: options,
    params: { textbookId: id }
  });
};

export const adminQBAnswersApi = (quesId) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/qbanswer";
  return Axios.get(URL, {
    headers: options,
    params: { questionId: quesId }
  });
};

export const MockAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-mock-question";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyViewEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-hightopics";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-mock-question";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const HyViewDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-hightopics";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-mock-question";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QbAddAnswerApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-qbanswer";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockRefApi = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/mock-question-ref";
  return Axios.get(URL, {
    headers: options,
    params: {
      questionId: id
    }
  });
};

export const MockExplanationAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-mock-textbook";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockExplanationEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-mock-textbook";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockExplanationDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-mock-textbook";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockExplanationRefApi = (id) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/mock-textbook-ref";
  return Axios.get(URL, {
    headers: options,
    params: {
      id: id
    }
  });
};

export const MockQnAnswerDeleteApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-mock-answer";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockAnswerAddApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/add-mock-answer";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const MockAnswerEditApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-mock-answer";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QbDeleteAnswerApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/delete-qbanswer";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const QbEditAnswerApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/edit-qbanswer";
  return Axios.post(URL, payload, {
    headers: options
  });
};

// Upload CSV

export const csvUploadApi = (uploadData, location) => {
  // if (location !== "/admin/mock/importcsv") {
  //   let Imgheaders = {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: "Bearer " + sessionStorage.getItem("token"),
  //   };
  //   return Axios.post(
  //     constantValues.PGNEET_PROD_URL + "/upload-csv",
  //     uploadData,
  //     {
  //       headers: Imgheaders,
  //       params: payloadAll,
  //     }
  //   );
  // } else {
  console.log(uploadData, "uploadData");
  let Imgheaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  console.log(Imgheaders, "Imgheaders");
  return Axios.post(
    constantValues.PGNEET_PROD_URL + "/upload-csv",
    uploadData,
    {
      headers: Imgheaders
      // params: payloadMock,
    }
  );
  // }
};

export const handleBlogApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    "Content-Type": "multipart/form-data"
  };
  return Axios.post(constantValues.PGNEET_PROD_URL + "/blog-title", payload, {
    headers: options
  });
};
export const handleBlogHeadingApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/blog-heading";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const handleinstructionApi = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    "Content-Type": "multipart/form-data"
  };
  return Axios.post(
    constantValues.PGNEET_PROD_URL + "/admin-instruction",
    payload,
    {
      headers: options
    }
  );
};

export const blogUsersCount = () => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-user";
  return Axios.get(URL);
};
export const dashboardBlogHeading = () => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-header";
  return Axios.get(URL);
};
export const dashboardBlogMonth = () => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-month-count";
  return Axios.get(URL);
};

export const handleBlogHeadinghApi = (payload) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-sub-title";
  return Axios.get(URL, {
    params: payload
  });
};

export const handleBlogMonthhApi = (payload) => {
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-month";
  return Axios.get(URL, {
    params: payload
  });
};

export const getUserRolePrivileges = () => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/get-privilege";
  return Axios.get(URL, {
    headers: options
    // params: {
    //   flag: "all"
    // }
  });
};

export const addUserRolePrivileges = (payload) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };
  const URL = constantValues.PGNEET_PROD_URL + "/privilege";
  return Axios.post(URL, payload, {
    headers: options
  });
};

export const handleBlogpublish = (payload, startDate, endDate) => {
  const options = {
    Authorization: "Bearer " + sessionStorage.getItem("token")
  };

  const URL = constantValues.PGNEET_PROD_URL + "/blog-publish";

  return Axios.post(URL, null, {
    headers: options,
    params: {
      id: payload,
      publishDate: startDate,
      endDate: endDate
    }
  });
};

export const blogDetailApi = (payload) => {
  console.log(payload, "Payload");
  const URL = constantValues.PGNEET_PROD_URL + "/auth/blog-details";
  return Axios.get(URL, {
    params: payload
  });
};
