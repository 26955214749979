import React, {useEffect, useRef} from "react";
import {
  aboutListPara,
  aboutQuestions,
  fundingNobleCause
} from "../Common/beforeLoginPageContents";
import {
  AboutBannerHead,
  AboutHead,
  AboutParaTypo,
  BlueContainerSection,
  ContentDiv,
  TransparentHead
} from "../styledcomponents/beforeLoginWidgets";
import ContactPage from "./ContactPage";
import childrenclipart from "../../assets/childrenclipart2 .png";

function AboutPage() {
  const contactRef = useRef();
  useEffect(() => {
    const y = contactRef.current.offsetTop;
    if (window.location.pathname === "/contact") {
      window.scrollTo(0, y-70);
    } else {
      window.scrollTo(0, 0);
    }
  }, [window.location.pathname]);
  return (
    <div>
      <BlueContainerSection>
        <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
          About Us
        </AboutBannerHead>
      </BlueContainerSection>
      <ContentDiv>
        {aboutListPara &&
          Object.values(aboutListPara).map((content, index) => (
            <div key={index} style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">{content}</AboutParaTypo>
            </div>
          ))}
      </ContentDiv>
      <ContentDiv>
        <TransparentHead variant="h6" fontWeight={"bold"}>
          Funding a noble cause
        </TransparentHead>
        {fundingNobleCause &&
          Object.values(fundingNobleCause).map((content, index) => (
            <div key={index} style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">{content}</AboutParaTypo>
            </div>
          ))}
        <div style={{ marginTop: "2rem" }}>
          <img
            src={childrenclipart}
            alt="linkexprie"
            width={window.innerWidth > 600 ? 450 : 350}
            height={250}
          />
          <div style={{ fontWeight: "bold", marginLeft: "3rem" }}>
            <span style={{ color: "red" }}> Children</span>{" "}
            <span style={{ color: "lightblue" }}> and</span>{" "}
            <span style={{ color: "green" }}> Adolescent</span>{" "}
            <span style={{ color: "#7F00FF" }}> Mental</span>
            <span style={{ color: "orange" }}> Health</span>
            <span style={{ color: "darkblue" }}> Services</span>
          </div>
        </div>
      </ContentDiv>
      <ContentDiv ref={contactRef}>
        <TransparentHead variant="h4" fontWeight={"bold"}>
          Contact us
        </TransparentHead>
        <ContactPage />
      </ContentDiv>
    </div>
  );
}

export default AboutPage;
