import React, { useState, useEffect } from "react";
import {
  AboutParaTypo,
  AboutParaTypoDiv,
  TransparentHead
} from "../../styledcomponents/beforeLoginWidgets";
import AdminTable from "../widgets/AdminTable";
import {
  SettingSubCategoryAddApi,
  SettingSubCategoryApi,
  SettingSubCategoryDeleteApi,
  SettingSubCategoryEditApi
} from "../../../http/allAdminApi";
import {
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  InputBase
} from "@mui/material";
import { Add, ArrowDropDown } from "@mui/icons-material";
import { SettingCategoryApi } from "../../../http/allAdminApi";
import { default as swal } from "sweetalert2";
import { StyledGreenButton } from "../../styledcomponents/styledDBwidgets";
import { Modal, Switch, Form } from "antd";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";

function AdminSubCategory() {
  const [openModal, setOpenModal] = useState(false);
  const [subCatVal, setSubCatVal] = useState("");
  const [catOptions, setCatOptions] = useState([]);
  const [checkBox, setCheckBox] = useState(true);
  const [settingSc, setSettingsc] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subjectVal, setSubjectVal] = useState("");
  const [modalFor, setModalFor] = useState("Add");
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [subId, setSubId] = useState("");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const handleInputChange = (event) => {
    const value = event.target.value;

    const filteredResults = settingSc?.filter((item) => {
      let finalValue = value.replace(/\s/g, "").toLowerCase();
      let finalSubCategory = item.subCategory.replace(/\s/g, "").toLowerCase();

      return (
        item.id.toString().includes(value) ||
        finalSubCategory.includes(finalValue.toLowerCase()) ||
        item.category.toLowerCase().includes(value.toLowerCase())
      );
    });
    setSearchVal(value);
    setResData(filteredResults);
  };

  const categoryApi = () => {
    SettingCategoryApi().then((res) => {
      setCatOptions(res.data.data);
    });
  };

  const onChange = (checked) => {
    setCheckBox(!checkBox);
  };
  const handleClose = () => {
    setSubjectVal("");
    setSubCatVal("");
    setOpenModal(false);
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setOpen(true);
      handleSubCatApi();
      setOpenModal(false);
      setSubCatVal("");
      setSubjectVal("");
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleSubCategoryAdd = () => {
    let payload = {
      categoryId: subjectVal,
      subCategory: subCatVal,
      status: checkBox
    };
    SettingSubCategoryAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };
  const handleSubCategoryEdit = () => {
    let payload = {
      id: subId,
      categoryId: subjectVal,
      subCategory: subCatVal,
      status: checkBox
    };
    SettingSubCategoryEditApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };

  const handleSave = () => {
    if (modalFor == "Add") {
      handleSubCategoryAdd();
    } else if (modalFor == "Edit") {
      handleSubCategoryEdit();
    }
  };

  const handleDeleteApi = (val) => {
    let payload = {
      id: val
    };
    SettingSubCategoryDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };
  const handleMenu = (condition, id) => {
    setAnchorEl(null);
    if (condition === "edit") {
      setModalFor("Edit");
      categoryApi();
      setSubId(paramData?.id);
      setSubjectVal(paramData?.categoryId);
      setSubCatVal(paramData?.subCategory);
      setCheckBox(paramData?.status);
      setOpenModal(true);
    } else if (condition === "add") {
      categoryApi();
      setSubjectVal(paramData?.id);
      setModalFor("Add");
      setOpenModal(true);
    } else if (condition === "delete") {
      swal
        .fire({
          title: "Are you sure?",
          text: "If you delete this, related sub categories, questions and answers also will be deleted. You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          // cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) {
            handleDeleteApi(paramData?.id);
          }
        });
    }
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const handleSubCatApi = () => {
    SettingSubCategoryApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setSettingsc(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(true);
        }
      });
  };

  useEffect(() => {
    handleSubCatApi();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5, hide: true },
    {
      field: "subCategory",
      headerName: "Sub Category",
      flex: 3,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem"
            }}
          >
            <AboutParaTypo variant="p">
              {params?.row?.subCategory}
            </AboutParaTypo>
          </div>
        );
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.status ? "green" : "red"
              // padding: "3px",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      }
    },
    {
      field: "category",
      headerName: "Category",
      flex: 2,
      hide: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem"
            }}
          >
            <AboutParaTypo variant="p">{params?.row?.category}</AboutParaTypo>
          </div>
        );
      }
    },
    {
      field: "age",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none"
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Sub Category
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem"
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleMenu("add")}
            style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add New
          </StyledGreenButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black"
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem"
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "Add" ? "Add Sub Category" : "Edit Sub Category"}
          </Typography>
        }
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <TextField
          select
          label="Category"
          type="text"
          size="small"
          value={subjectVal}
          id="name"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000"
            },
            "& .MuiInputLabel-root": {
              color: "#000"
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px"
            }
          }}
          onChange={(e) => setSubjectVal(e.target.value)}
        >
          <MenuItem value={""} />
          {catOptions?.map((item, id) => (
            <MenuItem key={id} value={item.id}>
              {item.category}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          placeholder="Sub Category"
          type="text"
          size="small"
          value={subCatVal}
          id="name"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000"
            },
            "& .MuiInputLabel-root": {
              color: "#000"
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px"
            }
          }}
          onChange={(e) => setSubCatVal(e.target.value)}
        />
        <Form.Item label="Status">
          <Switch checked={checkBox} onChange={onChange} />
        </Form.Item>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Button
            style={{
              textTransform: "none",
              outline: "none",
              backgroundColor:
                subjectVal === "" || subCatVal === "" ? "white" : "#2c3643",
              color: subjectVal === "" || subCatVal === "" ? "black" : "#fff"
            }}
            disabled={subjectVal === "" || subCatVal === ""}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Modal>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <MenuItem onClick={() => handleMenu("edit")}>Edit</MenuItem>
        <MenuItem onClick={() => handleMenu("delete")}>Delete</MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminSubCategory;
